import { useEffect, useState, useContext } from "react"
import { getFavoriteReportList } from "../../../../api/Reports/GetFavoriteReportList";
import AuthContext from "../../../../context/AuthContext"
import useDocumentTitle from "../../../../utils/useDocumentTitle"
import FavoritesItem from './FavoritesItem/FavoritesItem';
import { CircularProgress } from '@mui/material';
import './FavoritesList.css';

const Loader = () => {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <CircularProgress />
    </div>
  );
};

const favorites = [
  {
    created_at: '20 ноября 2024 года, 12:51',
    body_type: 'GRZ',
    body: 'У122КО55',
    model: 'ТОЙОТА ЛЕНД КРУЗЕР 200 Land Cruiser 2007',
    is_favorite: true
  },
  {
    created_at: '20 ноября 2024 года, 12:53',
    body_type: 'GRZ',
    body: 'У027РС55',
    model: 'DАТSUN ОN-DО ВААВD0 on-DO 2020',
    is_favorite: true
  }
];

export const FavoritesList = () => {
  const [reports, setReports] = useState()
  const { user, authTokens } = useContext(AuthContext)

  useEffect(() => {
    getFavoriteReportList({ setData: setReports, token: authTokens.access })
  }, [])
  useDocumentTitle('Избранные отчеты')  

  if (!reports) {
    return <Loader />
  }
  
  return (
    <div className="favorites__list">
      {reports.map((item, index) => (
        <FavoritesItem key={index} report={item} token={authTokens.access} />
      ))}
    </div>
  )
}