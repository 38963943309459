import './Photos.css';

export const Photos = ({ photos, onOpen }) => {
  const hasPhotos = photos?.length > 0;
  const photosLength = hasPhotos ? photos.length : 0;

  return (
    <section className="photos" id="photos">
      <div className="photos__title">
        <h2 className="section-heading section-heading--photos">Фотографии</h2>
        <div className="photos__count">
          {photosLength > 0 ? `Найдено фото: ${photosLength} ` : 'Фото не найдено'}
        </div>
      </div>

      {hasPhotos ?
        <div className="photos__block">
          {photos?.slice(0, 4).map((photo, index) => (
            (index != 3 || photosLength <= 4) ? (<div className="photos__wrap" key={index}>
              <img className="photos__photo" src={photo.uri}
                width="167" height="150" alt="фото автомобиля"
                onClick={onOpen}
              />
            </div>) : (
              <div className="photos__wrap photos__wrap--last" >
                <img className="photos__photo" src={photo.uri}
                  width="167" height="150" alt="фото автомобиля"
                  onClick={onOpen} />
                <div className="photos__overlay" onClick={onOpen}>
                  <p className="photos__more" >Ещё {photosLength - 3} фото</p>
                </div>
              </div>
            )
          ))}
        </div> : ''
      }
    </section >
  )
}