import { Link } from "react-router-dom"
import LoginForm from "../../components/AuthForms/LoginForm/LoginForm"
import './Auth.css';

const LoginBlock = () => {
  return (
    <div className="auth__wrap">
      <div className="auth__title">
        <h2 className="section-heading section-heading--auth">Войдите в аккаунт</h2>
        <p className="auth__text">Авторизуйтесь через E-mail или воспользуетесь соц. сетями</p>
      </div>
      <LoginForm />
      <div className="auth__info-block">
        <p style={{ margin: '0' }}>
          <span>Еще нет аккаунта? </span>
          <Link className="auth__link" to='/registration'>Зарегистрироваться</Link>
        </p>
        <p style={{ margin: '0' }}>
          <span>Забыли пароль? </span>
          <Link className="auth__link" to='/forget-password'>Восстановить пароль</Link>
        </p>
        <p className="auth__agreement">
          Создавая аккаунт, Вы соглашаетесь с <Link className="auth__agreement-link" to="/offer">условиями оферты </Link>
          <br /> и <Link className="auth__agreement-link" to="/privacy">политикой конфиденциальности</Link>
        </p>
      </div>
    </div>
  )
}

export default LoginBlock