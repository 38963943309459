import './PledgesHistory.css';

export const PledgesHistory = ({ pledgesHistory }) => {
  const hasPledges = pledgesHistory?.items?.length > 0;
  const pledgesLength = hasPledges ? pledgesHistory.items.length : 0;

  return (
    <section className="pledges-history" id="pledges-history">
      <div className="pledges-history__title">
        <h2 className="section-heading section-heading--pledges-history">Залоги</h2>
        <div className={`pledges-history__count ${hasPledges ?
          'pledges-history__count--error' :
          'pledges-history__count--success'}`}>
          {pledgesLength > 0 ? `Найдено залогов: ${pledgesLength}` : 'Нет залогов'}
        </div>
      </div>
      {hasPledges ? (
        <ul className="list-reset pledges-history__list">
          {pledgesHistory?.items?.map((pledges, index) => (
            <li className="pledges-history__item" key={index}>
              <div className="pledges-history__item-left">
                <p className='pledges-history__parameter'>
                  Состояние:
                  {pledges.in_pledge ?
                    <span style={{ color: '#DF4949' }}> Находится в залоге</span>
                    :
                    <span style={{ color: '#82DF49' }}> Не находится в залоге</span>
                  }
                </p>
                {pledges?.number && (
                  <p className='pledges-history__parameter'>
                    Номер залога:
                    <span> {pledges.number.split(" ")[0]}</span>
                  </p>
                )}
                {pledges?.date?.event && (
                  <p className='pledges-history__parameter'>
                    Дата:
                    <span> {pledges.date.event.split(" ")[0]}</span>
                  </p>
                )}
              </div>
              <div className="pledges-history__item-right">
                {pledges?.pledgors?.length > 0 && (
                  <div className="pledges-history__box">
                    <p className="pledges-history__item-title">Поручители:</p>
                    {pledges.pledgors.map((pledgor, index) => (
                      <div key={index}>
                        {pledgor?.name && (
                          <p className='pledges-history__parameter'>
                            ФИО:
                            <span > {pledgor.name}</span>
                          </p>
                        )}
                        {pledgor?.dob && (
                          <p className='pledges-history__parameter'>
                            Дата рождения:
                            <span > {pledgor.dob}</span>
                          </p>
                        )}
                        {pledgor?.type && (
                          <p className='pledges-history__parameter'>
                            Тип:
                            <span >
                              {pledgor.type === 'PERSON' ? ' Физическое лицо' : ' Юридическое лицо'}
                            </span>
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {pledges?.pledgees?.length > 0 && (
                  <div className="pledges-history__box">
                    <p className="pledges-history__item-title">Залогодержатели:</p>
                    {pledges.pledgees.map((pledgee, index) => (
                      <div key={index} >
                        {pledgee?.name && (
                          <p className='pledges-history__parameter'>
                            ФИО:
                            <span> {pledgee.name}</span>
                          </p>
                        )}
                        {pledgee?.type && (
                          <p className='pledges-history__parameter'>
                            Тип:
                            <span>
                              {pledgee.type === 'PERSON' ? ' Физическое лицо' : ' Юридическое лицо'}
                            </span>
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}