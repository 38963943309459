import { useState } from "react";
import { Link } from 'react-router-dom';
import { ModalDetailsDamage } from './ModalDetailsDamage/ModalDetailsDamage';
import './DetailsTrafficAccident.css';
import error from '../../../../media/images/new/error.svg';
import accident1 from '../../../../media/images/new/accident1.jpg';
import accident2 from '../../../../media/images/new/accident2.jpg';


export const DetailsTrafficAccident = () => {
  const [isModalOpen, setIsModalOpen] = useState(null)
  const trafficAccidentItems = [
    {
      date: '12 февраля 2018 года в 12:50',
      incidentType: 'Столкновение',
      carCondition: 'Повреждено',
      img: accident1
    },
    {
      date: '19 января 2019 года в 10:00',
      incidentType: 'Наезд на стоящее ТС',
      carCondition: 'Повреждено',
      img: accident2
    },
    {
      date: '15 марта 2021 года в 15:30',
      incidentType: 'Наезд на препятствие',
      carCondition: 'Повреждено',
      img: accident2
    },
  ]
  return (
    <div className="tab tab--traffic-accident traffic-accident">
      <div className="tab__title">
        <h3 className="heading-three heading-three--traffic-accident">ДТП</h3>
        <p className="tab__text">Мы покажем, попадал ли автомобиль в аварии и что было повреждено. Вы узнаете, если в ДТП изменилась геометрия кузова это влияет на безопасность</p>
      </div>
      <div className="tab__content-block">
        <div className="tab__content-wrap tab__content-wrap--traffic-accident">
          <div className="traffic-accident__title-wrap">
            <div className="tab__heading-wrap">
              <h3 className="heading-three heading-three--content">Был в ДТП</h3>
              <img className="traffic-accident__check" src={error} width={20} height={20} />
            </div>
            <p className="tab__text">
              В отчёт не попадут аварии, которые произошли раньше 2015 года
              или не оформлялись в ГИБДД. Узнать о таких ДТП можно в блоке &nbsp;
              <button className="btn-reset" style={{ color: '#004CDB' }}
                onClick={() => document.getElementById('details-tabs-oblect-7').checked = true
                }> «Расчёты стоимости ремонта»</button>
            </p>
          </div>
          <ul className="list-reset traffic-accident__list">
            {trafficAccidentItems.map((item, index) => (
              <li className="traffic-accident__item" key={index}>
                <p className="traffic-accident__date">{item.date}</p>
                <p className="traffic-accident__type">
                  <span style={{ opacity: '0.5' }}>Тип происшествия: </span>
                  <span>{item.incidentType}</span>
                </p>
                <p className="traffic-accident__condition">
                  <span style={{ opacity: '0.5' }}>Состояние авто после ДТП: </span>
                  <span>{item.carCondition}</span>
                </p>
                <button className="btn-reset second-button traffic-accident__button"
                  onClick={() => setIsModalOpen(item)}
                >Посмотреть повреждения</button>
              </li>
            ))}
          </ul>
          {isModalOpen && <ModalDetailsDamage open={!!isModalOpen} onClose={() => setIsModalOpen(null)} data={isModalOpen} />}
        </div>
      </div>
    </div>
  )
}