import './DetailsCalculation.css';
import error from '../../../../media/images/new/error.svg';

export const DetailsCalculation = () => {
  const calculationItems = [
    {
      date: '12 июня 2015 года',
      price: '12 000 руб.'
    },
    {
      date: '30 августа 2017 года',
      price: '26 400 руб.'
    },
    {
      date: '27 февраля 2018 года',
      price: '79 161 руб.'
    },
    {
      date: '10 июня 2020 года',
      price: '45 800 руб.',
      details: 'Включает замену переднего бампера и ремонт правого крыла'
    },
    {
      date: '5 октября 2021 года',
      price: '12 300 руб.',
      details: 'Рассчитана только замена лобового стекла'
    }
  ];

  return (
    <div className="tab tab--calculation tab-calculation" id="tab-calculation">
      <div className="tab__title">
        <h3 className="heading-three">Расчёты стоимости ремонта</h3>
        <p className="tab__text">Мы проверяем, во сколько страховые компании, независимые эксперты, дилеры и СТО оценили восстановление автомобиля после ДТП. Часто это помогает узнать об авариях, которые не попали в базу ГИБДД</p>
      </div>

      <div className="tab__content-block tab__content-block--calculation">
        <div className="tab-calculation__title-wrap">
          <div className="tab__heading-wrap">
            <h3 className="heading-three">Обнаружены расчёты стоимости ремонта</h3>
            <img className="pledges__check" src={error} width={20} height={20} />
          </div>
          <p className="tab__text">Мы проверяем, во сколько эксперты страховых компаний оценили восстановление автомобиля после ДТП. Расчёт не означает, что машину ремонтировали</p>
        </div>

        <ul className="list-reset tab-calculation__list">
          {calculationItems.map((item, index) => (
            <li className="tab-calculation__item" key={index}>
              <p className="tab-calculation__date">{item.date}</p>
              <p className="tab-calculation__price">Стоимость ремонта: <span>{item.price}</span></p>
              {item.details && <p className="tab-calculation__details">{item.details}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}