import ForgetPasswordForm from "../../components/AuthForms/ForgetPasswordForm/ForgetPasswordForm";
import './Auth.css';

const RecoveryPasswordPage = () => {
  return (
    <div className="auth__wrap">
      <div className="auth__title">
        <h2 className="section-heading section-heading--auth">Восстановление пароля</h2>
        <p className="auth__text">Введите E-mail, который вы указывали при регистрации.
          На него будет отправлен пароль для восстановления</p>
      </div>
      <ForgetPasswordForm />
    </div>
  )
}

export default RecoveryPasswordPage