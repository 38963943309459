import AuthTemplate from "./AuthTemplate";
import LoginBlock from "./LoginBlock";
import './Auth.css';

const LoginPage = () => {
  return (
    <AuthTemplate title={'Авторизация'} form={<LoginBlock />} />
  )
}

export default LoginPage