import './Slider.css';
import star from '../../../media/images/new/star.svg';
import error from '../../../media/images/new/error.svg';
import success from '../../../media/images/new/success.svg';
import image1 from '../../../media/images/new/slider-image1.png';
import image2 from '../../../media/images/new/slider-image2.png';
import image3 from '../../../media/images/new/slider-image3.png';

export const Slider = () => {
  return (
    <div className="slider">
      <div className="slider__wrapper">
        <h3 className="heading-three">Нам <span style={{ color: '#004CDB' }}>доверяют</span> миллионы</h3>
        <ul className="list-reset slider__list">
          <li className="slider__item slide">
            <div className="slide__wrap">
              <img loading="lazy" src={image1} width="426" height="163" alt="" />
              <div className="slide__info">
                <div className="slide__info-top">
                  <div className="slide__info-user">
                    <p className="slide__info-user-name">Иван</p>
                    <p className="slide__info-profession">Автолюбитель</p>
                  </div>
                  <div className="slide__info-rating">
                    <div className="slide__info-stars">
                      {new Array(5).fill(0).map((item, index) => (
                        <img loading="lazy" key={index} src={star} width="15" height="15" alt="рейтинг" />
                      ))}
                    </div>
                    <p className="slide__info-note">Подбирал BMW 328i</p>
                  </div>
                </div>
                <div className="slide__info-title">
                  <p className="slide__info-heading">
                    AUTOCHECK обнаружил 3 серьёзных недостатка
                  </p>
                  <div className="slide__info-icons">
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                  </div>
                </div>
                <div className="slide__info-text">
                  Сервис очень помог! Узнал, что автомобиль, который собирался купить, участвовал в серьёзной аварии. Это позволило избежать необдуманной покупки. Всё очень удобно, отчёт пришёл сразу
                </div>

              </div>
            </div>
          </li>
          <li className="slider__item slide slide--central">
            <div className="slide__wrap">
              <img loading="lazy" src={image2} width="426" height="163" alt="" />
              <div className="slide__info">
                <div className="slide__info-top">
                  <div className="slide__info-user">
                    <p className="slide__info-user-name">Иван</p>
                    <p className="slide__info-profession">Автолюбитель</p>
                  </div>
                  <div className="slide__info-rating">
                    <div className="slide__info-stars">
                      {new Array(5).fill(0).map((item, index) => (
                        <img loading="lazy" key={index} src={star} width="15" height="15" alt="рейтинг" />
                      ))}
                    </div>
                    <p className="slide__info-note">Подбирал BMW 328i</p>
                  </div>
                </div>
                <div className="slide__info-title">
                  <p className="slide__info-heading">
                    AUTOCHECK обнаружил 3 серьёзных недостатка
                  </p>
                  <div className="slide__info-icons">
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                  </div>
                </div>
                <div className="slide__info-text">
                  Сервис очень помог! Узнал, что автомобиль, который собирался купить, участвовал в серьёзной аварии. Это позволило избежать необдуманной покупки. Всё очень удобно, отчёт пришёл сразу
                </div>

              </div>
            </div>
          </li>
          <li className="slider__item slide">
          <div className="slide__wrap">
              <img loading="lazy" src={image3} width="426" height="163" alt="" />
              <div className="slide__info">
                <div className="slide__info-top">
                  <div className="slide__info-user">
                    <p className="slide__info-user-name">Алексей</p>
                    <p className="slide__info-profession">Менеджер</p>
                  </div>
                  <div className="slide__info-rating">
                    <div className="slide__info-stars">
                      {new Array(5).fill(0).map((item, index) => (
                        <img loading="lazy" key={index} src={star} width="15" height="15" alt="рейтинг" />
                      ))}
                    </div>
                    <p className="slide__info-note">Подбирал BMW 328i</p>
                  </div>
                </div>
                <div className="slide__info-title">
                  <p className="slide__info-heading">
                    AUTOCHECK обнаружил 3 серьёзных недостатка
                  </p>
                  <div className="slide__info-icons">
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={error} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                    <span><img loading="lazy" src={success} width="15" height="15" alt="" /></span>
                  </div>
                </div>
                <div className="slide__info-text">
                  Сервис очень помог! Узнал, что автомобиль, который собирался купить, участвовал в серьёзной аварии. Это позволило избежать необдуманной покупки. Всё очень удобно, отчёт пришёл сразу
                </div>

              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}