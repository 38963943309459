import './CompleteDatabaseSection.css';
import rolf from '../../../media/images/new/rolf.png';
import rolf_2x from '../../../media/images/new/rolf_2x.png';
import automir from '../../../media/images/new/automir.png';
import automir_2x from '../../../media/images/new/automir_2x.png';
import tts from '../../../media/images/new/tts.png';
import tts_2x from '../../../media/images/new/tts_2x.png';
import kluch from '../../../media/images/new/kluch.png';
import kluch_2x from '../../../media/images/new/kluch_2x.png';
import nbki from '../../../media/images/new/nbki.png';
import nbki_2x from '../../../media/images/new/nbki_2x.png';
import fitservice from '../../../media/images/new/fitservice.png';
import fitservice_2x from '../../../media/images/new/fitservice_2x.png';
import autmama from '../../../media/images/new/autmama.png';
import autmama_2x from '../../../media/images/new/autmama_2x.png';
import uremont from '../../../media/images/new/uremont.png';
import uremont_2x from '../../../media/images/new/uremont_2x.png';
import agat from '../../../media/images/new/agat.png';
import agat_2x from '../../../media/images/new/agat_2x.png';
import businessCar from '../../../media/images/new/business-car.png';
import businessCar_2x from '../../../media/images/new/business-car_2x.png';
import kan from '../../../media/images/new/kan.png';
import kan_2x from '../../../media/images/new/kan_2x.png';
import audatex from '../../../media/images/new/audatex.png';
import audatex_2x from '../../../media/images/new/audatex_2x.png';

export const CompleteDatabaseSection = () => {
  const data = [
    {
      title: 'Рольф',
      img: rolf,
      img_2x: rolf_2x
    },
    {
      title: 'Автомир',
      img: automir,
      img_2x: automir_2x
    },
    {
      title: 'ТрансТехСервис',
      img: tts,
      img_2x: tts_2x
    },
    {
      title: 'Ключавто',
      img: kluch,
      img_2x: kluch_2x
    },
    {
      title: 'НБКИ',
      img: nbki,
      img_2x: nbki_2x
    },
    {
      title: 'FitService',
      img: fitservice,
      img_2x: fitservice_2x
    },
    {
      title: 'Autmama',
      img: autmama,
      img_2x: autmama_2x
    },
    {
      title: 'Uremont',
      img: uremont,
      img_2x: uremont_2x
    },
    {
      title: 'Agat Group',
      img: agat,
      img_2x: agat_2x
    },
    {
      title: 'Business Car',
      img: businessCar,
      img_2x: businessCar_2x
    },
    {
      title: 'Кан Авто',
      img: kan,
      img_2x: kan_2x
    },
    {
      title: 'Audatex',
      img: audatex,
      img_2x: audatex_2x
    },
  ]
  return (
    <section id="partners" className="complete-database">
      <div className='container complete-database__container'>
        <h2 className="section-heading section-heading--complete-database">Полная база данных об автомобилях с пробегом</h2>
        <ul className="list-reset complete-database__list">
          {data.map((item, index) => (
            <li className="complete-database__item">
              <picture className="complete-database__picture">
                <img loading="lazy" className="complete-database__img" src={item.img} srcSet={`${item.img_2x} 2x`}
                  width="150" height="50" alt={item.title} />
              </picture>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}