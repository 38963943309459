import { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { validateRegistrationForm } from "./RegistrationFormValidate"
import { registrationRequest } from "../../../../api/Auth/registrationRequest"
import '../AuthForm.css';

const RegistrationForm = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password1, setPassword1] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const { referral_code } = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password1: ''
  });

  const handleSubmitForm = () => {
    if (validateRegistrationForm(firstName, lastName, email, password, password1, setError)) {
      registrationRequest({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        referral: referral_code,
        setIsSuccess: setIsSuccess,
      })
    };
  }

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/login')
    }
  }, [isSuccess, navigate])

  return (
    <form action="" className="auth-form" method="POST" noValidate={true}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitForm();
      }}>
      <div className="auth-form__container">
        <div className="auth-fields">
          <p className="auth-fields__wrap">
            <label className="visually-hidden" htmlFor="email">email</label>
            <input
              className={`auth-field ${!!error.email ? 'auth-field--error' : ''}`}
              type="email"
              name="email"
              id="email"
              placeholder="mail@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="auth-field__message-error">{error.email}</span>
          </p>
          <p className="auth-fields__wrap">
            <label className="visually-hidden" htmlFor="password">пароль</label>
            <div className="auth-field__input-wrap">
              <input
                className={`auth-field ${!!error.password ? 'auth-field--error' : ''}`}
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Пароль"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="button" className={`btn-reset auth-fields__change-input-type ${showPassword ? 'auth-fields__change-input-type--show' : ''}`}
                onClick={() => handleTogglePassword()}></button>
            </div>
            <span className="auth-field__message-error">{error.password}</span>
          </p>
          <p className="auth-fields__wrap">
            <label className="visually-hidden" htmlFor="password1">повторите пароль</label>
            <div className="auth-field__input-wrap">
              <input
                className={`auth-field ${!!error.password1 ? 'auth-field--error' : ''}`}
                type={showPassword1 ? 'text' : 'password'}
                name="password1"
                id="password1"
                placeholder="Повторите пароль"
                onChange={(e) => setPassword1(e.target.value)}
              />
              <button type="button" className={`btn-reset auth-fields__change-input-type ${showPassword1 ? 'auth-fields__change-input-type--show' : ''}`}
                onClick={() => handleTogglePassword1()}></button>
            </div>
            <span className="auth-field__message-error">{error.password1}</span>
          </p>
        </div>

        <div className="auth-form__buttons">
          <button className="btn-reset main-button auth-form__button">Создать аккаунт</button>
          {/* <Link className="auth-form__link auth-form__link--yandex">
            <span className="visually-hidden">Войти через Яндекс</span>
          </Link>
          <Link className="auth-form__link auth-form__link--google">
            <span className="visually-hidden">Войти через Google</span>
          </Link>
          <Link className="auth-form__link auth-form__link--vk">
            <span className="visually-hidden">Войти через Вконтакте</span>
          </Link> */}
        </div>
      </div>
    </form>
  )
}

export default RegistrationForm