import './OwnershipHistory.css';

export const OwnershipHistory = ({ ownerships }) => {
  const hasOwnership = ownerships?.items?.length > 0;
  const ownershipLength = hasOwnership ? ownerships.items.length : 0;

  return (
    <section className="ownership-history" id="ownership-history">
      <div className="ownership-history__title">
        <h2 className="section-heading section-heading--ownership-history">История владения</h2>
        <div className="ownership-history__count">
          {ownershipLength > 0 ? `Записи о владельцах: ${ownershipLength}` : 'Нет сведений о владельцах'}
        </div>
      </div>

      {hasOwnership ? (
        <ul className="list-reset ownership-history__list">
          {ownerships?.items?.map((ownership, index) => (
            <li className="ownership-history__item" key={index}>
              {ownership?.date?.start && (
                <p className="ownership-history__parameter">Начало владения:
                  <span> {ownership.date.start.split(" ")[0]}</span>
                </p>
              )}
              {ownership?.date?.end && (
                <p className="ownership-history__parameter">Окончание владения:
                  <span> {ownership.date.end.split(" ")[0]}</span>
                </p>
              )}

              {ownership?.owner?.type && (
                <p className="ownership-history__parameter">Владелец:
                  <span> {ownership.owner.type === 'PERSON' ? ' Физическое лицо' : ' Юридическое лицо'}</span>
                </p>
              )}
              {ownership?.owner?.company?.name && (
                <p className="ownership-history__parameter">Название компании:
                  <span>  {ownership.owner.company.name}</span>
                </p>
              )}
              {ownership?.owner?.company?.ogrn && (
                <p className="ownership-history__parameter">ОГРН:
                  <span> {ownership.owner.company.ogrn}</span>
                </p>
              )}
              {ownership?.owner?.company?.tin && (
                <p className="ownership-history__parameter">ИНН:
                  <span> {ownership.owner.company.tin}</span>
                </p>
              )}
              {ownership?.owner?.company?.kpp && (
                <p className="ownership-history__parameter">КПП:
                  <span> {ownership.owner.company.kpp}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}

