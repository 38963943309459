const accusativeCase = {
  h: ['сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'],
  t: ['десять', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'],
  o: ['одного', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'],
  o1: ['одного', 'двоих', 'троих', 'четверых', 'пятерых', 'шестерых', 'семерых', 'восьмерых', 'девятерых'],
  p: ['одиннацать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'],
  ten: ['десять']
}

const prepositionalCase = {
  h: ['ста', 'двухстах', 'трехстах', 'четырехстах', 'пятистах', 'шестистах', 'семистах', 'восьмистах', 'девятистах'],
  t: ['десяти', 'двадцати', 'тридцати', 'сорока', 'пятидесяти', 'шестидесяти', 'семидесяти', 'восьмидесяти', 'девяноста'],
  o: ['одном', 'двух', 'трех', 'четырех', 'пяти', 'шести', 'семи', 'восьми', 'девяти'],
  o1: ['одном', 'двух', 'трех', 'четырех', 'пяти', 'шести', 'семи', 'восьми', 'девяти'],
  p: ['одиннадцати', 'двенадцати', 'тринадцати', 'четырнадцати', 'пятнадцати', 'шестнадцати', 'семнадцати', 'восемнадцати', 'девятнадцати'],
  ten: ['десяти']
}


const nominative = {
  h: ['сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'],
  t: ['десять', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'],
  o: ['один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'],
  o1: ['один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'],
  p: ['одиннацать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'],
  ten: ['десять']
}


function numToPr(number, words) {
  const { h, t, o, o1, p, ten } = words;
  let str = number.toString();
  let out = '';
  if (number == 10) return ten[0];
  if (str.length == 1) return o1[number - 1];
  else if (str.length == 2) {
    if (str[0] == 1) out = p[parseInt(str[1]) - 1];
    else out = (t[parseInt(str[0]) - 1] + ((str[1] != '0') ? (' ' + o[parseInt(str[1]) - 1]) : ''));
  }
  else if (str.length == 3) {
    const hword = h[parseInt(str[0]) - 1];
    const str2 = (Number(number.toString().slice(number.toString().length - 2))).toString();
    let tword = str2;
    if (str2 == '10') {
      tword = ten
    } else if (str2.length == 1) {
      tword =  o[Number(str2) - 1];
    } else if (str2[0] == 1) tword = p[parseInt(str2[1]) - 1];
    else tword = (t[parseInt(str2[0]) - 1] + ((str2[1] != '0') ? (' ' + o[parseInt(str2[1]) - 1]) : ''));
    out = tword ? hword + ' ' + tword : hword;
  }

  let arr = out.split('');
  arr[0] = arr[0].toUpperCase();
  out = arr.join('');
  return out;
}

function numberCases(num, a, a1, b, c) {
  const lastTwo = Number(num.toString().slice(num.toString().length - 2));
  const lastOne = Number(num.toString().slice(num.toString().length - 1));

  if (lastOne == 0) return c;
  if((num >= 2 && num <= 9) && a1) return a1;
  if (lastTwo < 20) {
    if (lastTwo == 1) return a;
    if (lastTwo < 5) return b;
    return c;
  } else {
    if (lastOne == 1) return a;
    if (lastOne < 5) return b;
    return c;
  }
}

export {accusativeCase, nominative, prepositionalCase, numToPr, numberCases};


