import './DiagnosticCards.css';

export const DiagnosticCards = ({ diagnosticCards }) => {
  const hasDiagnosticCards = diagnosticCards?.items?.length > 0;
  const diagnosticCardsLength = hasDiagnosticCards ? diagnosticCards.items.length : 0;

  return (
    <section className="diagnostic-cards" id="diagnostic-cards">
      <div className="diagnostic-cards__title">
        <h2 className="section-heading section-heading--diagnostic-cards">Диагностические карты</h2>
        <div className={`diagnostic-cards__count ${hasDiagnosticCards ?
          'diagnostic-cards__count--success' :
          'diagnostic-cards__count--error'
          }`}>
          {hasDiagnosticCards ?
            `Диагностических карт: ${diagnosticCardsLength}` :
            'Не обнаружено диагностических карт'}
        </div>
      </div>
      {hasDiagnosticCards ? (
        <ul className="list-reset diagnostic-cards__list">
          {diagnosticCards?.items?.map((diagnosticCard, index) => (
            <li className="diagnostic-cards__item" key={index}>
              {diagnosticCard?.date?.from && (
                <p className='diagnostic-cards__parameter'>Дата начала:
                  <span> {diagnosticCard.date.from.split(" ")[0]}</span>
                </p>
              )}
              {diagnosticCard?.date?.to && (
                <p className='diagnostic-cards__parameter'>Дата окончания:
                  <span> {diagnosticCard.date.to.split(" ")[0]}</span>
                </p>
              )}
              {diagnosticCard?.doc?.number && (
                <p className='diagnostic-cards__parameter'>Номер:
                  <span> {diagnosticCard.doc.number}</span>
                </p>
              )}
              {diagnosticCard?.inspection?.place && (
                <p className='diagnostic-cards__parameter'>Место:
                  <span> {diagnosticCard.inspection.place}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}