import './DetailsAdsHistory.css';
import img1 from '../../../../media/images/new/tab-ads1.png';
import img2 from '../../../../media/images/new/tab-ads2.png';
import img3 from '../../../../media/images/new/tab-ads3.png';
import img4 from '../../../../media/images/new/tab-ads4.png';

export const DetailsAdsHistory = () => {
  return (
    <div className="tab tab--ads-history tab-ads">
      <div className="tab__title">
        <h3 className="heading-three">История объявлений</h3>
        <p className="tab__text">Если машина продавалась на сервисах для размещения объявлений, вы увидите, как она выглядела и что было
          сказано в описании</p>
      </div>
      <div className="tab__content-block tab__content-block--ads">

        <div className="tab-ads__title">
          <h3 className="heading-three">1 объявление на Авито</h3>
          <p className="tab__text">История продажи автомобиля собирается на основе ранее опубликованных объявлений на всех сервисах по продаже б/у автомобилей с таким же VIN</p>
        </div>

        <div className="tab-ads__content">
          <p className="tab-ads__subtitle">Размещение от 10 февраля 2020 года</p>
          <div className="tab-ads__images-wrap">
            <img className="tab-ads__photo" src={img1} width="158" height="80" alt="фото автомобиля" />
            <img className="tab-ads__photo" src={img2} width="158" height="80" alt="фото автомобиля" />
            <img className="tab-ads__photo" src={img3} width="158" height="80" alt="фото автомобиля" />
            <img className="tab-ads__photo" src={img4} width="158" height="80" alt="фото автомобиля" />
          </div>
          <span style={{opacity: '0.5'}}>Продаю в хорошем состоянии кузов чистый ровный</span>
          <p className="tab-ads__parameter">Обнаружены расхождения:
            <span> на фотографиях видно повреждение заднего бампера, которое не упомянуто
              в описании</span>
          </p>
          <p className="tab-ads__parameter">Цена снижалась:
            <span> первоначально 1 325 000 руб, затем скорректирована до 1 275 000 руб.</span>
          </p>
          <p className="tab-ads__parameter">Комментарии продавца: 
            <span> "Машина на ходу, все вопросы по телефону."</span>
          </p>
        </div>

        <div className="tab-ads__summary">
          <p className="tab-ads__summary-item">Цена: <span>1 275 000 руб.</span></p>
          <p className="tab-ads__summary-item">Пробег: <span>96 000 км </span></p>
          <p className="tab-ads__summary-item">Состояние: <span>Битый</span></p>
          <p className="tab-ads__summary-item">Регион: <span>Московская область</span></p>
        </div>

        <button className="btn-reset second-button tab-ads__button">Перейти к объявлению</button>
      </div>
    </div>
  )
}