export const hasDecreasingMileage = (mileages) => {
  if (!Array.isArray(mileages?.items) || mileages?.items?.length === 0) {
    return false;
  } else {
    let decreasing = false;
    for (let i = 1; i < mileages?.items?.length; i++) {
      if (mileages?.items[i]?.mileage <= mileages?.items[i - 1].mileage) {
        decreasing = true;
        break;
      }
    }
    return decreasing;
  }
}