import './ShortAccidents.css';
import img from '../../../../../../media/images/new/image11.png';

export const ShortAccidents = () => {
  return (
    <div className="short-block short-block-accidents">
      <div className="short-block-accidents__left">
        <div>
          <h4 className="short-block-accidents__title">Повреждения</h4>
          <ul className="list-reset short-block-accidents__list">
            <li className="short-block-accidents__item">Передний бампер</li>
            <li className="short-block-accidents__item">Капот</li>
            <li className="short-block-accidents__item">Решетка</li>
            <li className="short-block-accidents__item">Решетка радиатора</li>
            <li className="short-block-accidents__item">Отбойник</li>
            <li className="short-block-accidents__item">Переднее левое крыло</li>
            <li className="short-block-accidents__item">Переднее правое крыло</li>
            <li className="short-block-accidents__item">Обе фары</li>
            <li className="short-block-accidents__item">Подушки безопасности</li>
          </ul>
        </div>
        <div>
          <h4 className="short-block-accidents__title">Технические неисправности в момент ДТП</h4>
          <p className="short-block-accidents__text">Не устанавливались</p>
        </div>
      </div>
      <div className="short-block-accidents__right">
        <img src={img} alt="повреждения" />
      </div>
    </div>
  )
}