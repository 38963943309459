import './Arbitration.css';

export const Arbitration = ({ arbitration }) => {
  const hasArbitration = arbitration?.items?.length > 0;
  const arbitrationLength = hasArbitration ? arbitration?.items?.length : 0;

  return (
    <section className="arbitration" id="arbitration">
      <div className="arbitration__title">
        <h2 className="section-heading section-heading--arbitration">Арбитражи</h2>
        <div className={`arbitration__count ${hasArbitration ?
          'arbitration__count--error' :
          'arbitration__count--success'
          }`}>
          {arbitrationLength > 0 ?
            `Найдено арбитражей: ${arbitrationLength}` :
            'Арбитражи отсутствуют'}
        </div>
      </div>
      {hasArbitration ? (
        <ul className="list-reset arbitration__list">
          {arbitration?.items?.map((arbitrationItem, index) => (
            <li className="arbitration__item" key={index}>
              {arbitrationItem?.date?.registration && (
                <p className='arbitration__parameter'>Дата регистрации дела:
                  <span> {arbitrationItem.date.registration.split(" ")[0]}</span>
                </p>
              )}

              {arbitrationItem?.court?.name && (
                <p className='arbitration__parameter'>Наименование суда:
                  <span> {arbitrationItem.court.name}</span>
                </p>
              )}

              {arbitrationItem?.number && (
                <p className='arbitration__parameter'>Номер судебного дела:
                  <span> {arbitrationItem.number}</span>
                </p>
              )}

              {arbitrationItem?.type && (
                <p className='arbitration__parameter'>Тип судебного дела:
                  <span> {arbitrationItem.type}</span>
                </p>
              )}

              {arbitrationItem?.description && (
                <p className='arbitration__parameter'>Описание судебного дела:
                  <span> {arbitrationItem.description}</span>
                </p>
              )}

              {arbitrationItem?.url && (
                <p className='arbitration__parameter'>Судебное дело: 
                  <a className="arbitration__link" href={arbitrationItem.url} target='_blank'> Скачать PDF</a>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section >
  )
}