import './DetailsUseInTaxi.css';
import error from '../../../../media/images/new/error.svg';
import success from '../../../../media/images/new/success.svg';

export const DetailsUseInTaxi = () => {
  const taxiItems = [
    {
      title: 'Не найдено разрешение на работу в такси',
      isChecked: true,
      text: 'Данные представлены из региональных баз по регистрации автомобиля в качестве такси'
    },
    {
      title: 'Не найдены сведения об использовании в каршеринге',
      isChecked: true,
      text: 'На каршеринговых авто ездят практически круглосуточно. Они много времени проводят в пробках — от этого двигатель и сцепление быстро изнашиваются. Салон тоже страдает от большого количества водителей и пассажиров'
    },
    {
      title: 'Обнаружены сведения об использовании для коммерческих перевозок',
      isChecked: false,
      text: 'Машина зарегистрирована как транспортное средство для грузоперевозок. Это означает, что автомобиль мог эксплуатироваться с большими нагрузками, что ускоряет износ подвески, трансмиссии и двигателя'
    },
    {
      title: 'Выявлено использование в учебных целях',
      isChecked: false,
      text: 'Автомобиль числился среди транспортных средств автошколы. Это предполагает интенсивную эксплуатацию с частыми резкими манёврами, торможением и повышенным износом сцепления и тормозной системы'
    },
    {
      title: 'Не найдены записи о работе в службах курьерской доставки',
      isChecked: true,
      text: 'Автомобили, используемые для доставки, часто имеют высокую степень износа подвески и двигателя из-за постоянных поездок на короткие расстояния и частых остановок'
    }
  ];

  return (
    <div className="tab tab--taxi tab-taxi">
      <div className="tab__title">
        <h3 className="heading-three heading-three--taxi">Использование в такси и каршеринге</h3>
        <p className="tab__text">Из-за большого пробега такие автомобили быстрее приходят в негодность. Чтобы вы могли узнать об износе, мы проверяем реестры разрешений на работу в такси и базы данных каршеринговых компаний</p>
      </div>

      <div className="tab__content-block">
        <ul className="list-reset tab-taxi__list">
          {taxiItems.map((item, index) => (
            <li className="tab-taxi__item" key={index}>
              <div className="tab__heading-wrap">
                <h3 className="heading-three">{item.title}</h3>
                <img src={item.isChecked ? success : error} width="20" height="20" alt="иконка" />
              </div>
              <p className="tab__text">{item.text}</p>
            </li>
          ))}
        </ul>

      </div>

    </div>
  )
}