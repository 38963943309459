import './CustomsHistory.css';

export const CustomsHistory = ({ customs }) => {
  const hasCustoms = customs?.items?.length > 0;
  const customsLength = hasCustoms ? customs.items.length : 0;

  return (
    <section className="customs-history" id="customs-history">
      <div className="customs-history__title">
        <h2 className="section-heading section-heading--customs-history">Таможенные процедуры</h2>
        <div className={`customs-history__count ${hasCustoms ?
          'customs-history__count--success' :
          'customs-history__count--error'
          }`}>
          {customsLength > 0 ?
            `Таможенные процедуры: ${customsLength}` :
            'Сведений о прохождении таможни не найдено'}
        </div>
      </div>
      {hasCustoms ? (
        <ul className="list-reset customs-history__list">
          {customs?.items?.map((custom, index) => (
            <li className="customs-history__item" key={index}>
              {custom?.date?.event && (
               <p className='customs-history__parameter'>Дата:
                  <span> {custom.date.event.split(" ")[0]}</span>
                </p>
              )}
              {custom?.price?.amount && (
                <p className='customs-history__parameter'>Стоимость:
                  <span> {custom.price.amount}₽</span>
                </p>
              )}
              {custom?.document && (
                 <p className='customs-history__parameter'>Документы:
                  {custom.document.name && (
                    <span> {custom.document.name}</span>
                  )}
                  {custom.document.number && (
                    <span> {custom.document.number}</span>
                  )}
                </p>
              )}
              {custom?.org?.name && (
                 <p className='customs-history__parameter'>Название организации:
                  <span> {custom.org.name}</span>
                </p>
              )}
              {custom?.country?.from?.name && (
                 <p className='customs-history__parameter'>Страна отправления:
                  <span> {custom.country.from.name}</span>
                </p>
              )}
              {custom?.country?.to?.name && (
                 <p className='customs-history__parameter'>Страна назначения:
                  <span> {custom.country.to.name}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}