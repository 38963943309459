import '../../styles/index.css'
import useDocumentTitle from "../../utils/useDocumentTitle";
import { FirstScreen } from '../components/FirstScreen/FirstScreen';
import { BenefitSection } from '../components/BenefitSection/BenefitSection';
import { DetailsTabSection } from '../components/DetailsTabSection/DetailsTabSection';
import { CompleteDatabaseSection } from '../components/CompleteDatabaseSection/CompleteDatabaseSection';
import { AnswersSection } from '../components/AnswersSection/AnswersSection';
import { ReviewsSection } from '../components/ReviewsSection/ReviewsSection';
import { DevicesSection } from '../components/DevicesSection/DevicesSection';
import { CheckForOneRubleSection } from '../components/CheckForOneRubleSection/CheckForOneRubleSection';
import { ButtonUp } from '../components/ButtonUp/ButtonUp';

export const MainPage = () => {
  useDocumentTitle('Главная')
  return (
    <>
      <FirstScreen />
      <BenefitSection />
      <DetailsTabSection />
      <CompleteDatabaseSection />
      <AnswersSection />
      <ReviewsSection />
      <DevicesSection />
      <CheckForOneRubleSection />
      <ButtonUp />
    </>
  )
}