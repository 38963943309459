import './DetailsLegal.css';
import error from '../../../../media/images/new/error.svg';
import success from '../../../../media/images/new/success.svg';

export const DetailsLegal = () => {
  const legalItems = [
    {
      title: 'Пробег автомобиля',
      isChecked: true,
      text: 'Запрет регистрационных действий на машину накладывается, если у автовладельца есть неоплаченные штрафы и налоги, либо если имущество стало предметом спора'
    },
    {
      title: 'Нет сведений о розыске',
      isChecked: true,
      text: 'Покупка разыскиваемого автомобиля грозит тем, что его отберут в ГИБДД при регистрации, и пока будет идти следствие, а это может затянуться на долгий срок, автомобиль будет стоять на штрафплощадке'
    },
    {
      title: 'Залог не найден',
      isChecked: true,
      text: 'Мы проверили базы данных Федеральной нотариальной палаты (ФНП) и Национального бюро кредитных историй (НБКИ)'
    },
    {
      title: 'Обнаружены сведения о судебных спорах',
      isChecked: false,
      text: 'Автомобиль является предметом судебного разбирательства. Это может привести к аресту транспортного средства или его передаче другим лицам по решению суда. Покупка такой машины несёт серьёзные риски, включая невозможность её регистрации и использование до завершения споров'
    },
    {
      title: 'Не выявлено перебитых VIN-номеров',
      isChecked: true,
      text: 'Автомобили с изменёнными или повреждёнными идентификационными номерами могут быть признаны нелегальными. В результате их изымают, а владельца могут привлечь к административной или уголовной ответственности'
    }
  ];

  return (
    <div className="tab tab--legal tab-legal">
      <div className="tab__title">
        <h3 className="heading-three heading-three--legal">Юридическая чистота</h3>
        <p className="tab__text">Мы проверяем авто на залог, ограничения и розыск. Если они обнаружатся после покупки, машину могут забрать за долги или не зарегистрировать в ГИБДД</p>
      </div>
      <div className="tab__content-block tab__content-block--legal">
        <ul className="list-reset tab-legal__list">
          {legalItems.map((item, index) => (
            <li className="tab-legal__item" key={index}>
              <div className="tab__heading-wrap">
                <h3 className="heading-three">{item.title}</h3>
                <img src={item.isChecked ? success : error} width="20" height="20" alt="иконка" />
              </div>
              <p className="tab__text">{item.text}</p>
            </li>
          ))}
        </ul>

      </div>
    </div>
  )
}