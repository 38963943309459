import AuthTemplate from "./AuthTemplate";
import RecoveryBlock from "./RecoveryBlock";
import './Auth.css';

const RecoveryPage = () => {
  return (
    <AuthTemplate title={'Восстановление пароля'} form={<RecoveryBlock />}/>
  )
}

export default RecoveryPage