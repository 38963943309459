import './CheckForOneRubleSection.css';
import CustomTextField from "../CustomTextField/CustomTextField";
import img from '../../../media/images/new/macbookpro.png';
import img_2x from '../../../media/images/new/macbookpro_2x.png';

export const CheckForOneRubleSection = () => {
  return (
    <section className="check-for-one-ruble">
      <div className='container check-for-one-ruble__container'>
        <div className="check-for-one-ruble__left">
          <div className="check-for-one-ruble__wrap">
          <h3 className="check-for-one-ruble__title">Проверьте автомобиль за 1 рубль</h3>
          <p className="check-for-one-ruble__text">
            Первый запрос – всего 1 рубль! Узнайте всю историю автомобиля по госномеру, VIN
            или номеру кузова. Мы покажем информацию о владельцах, ДТП, залогах, ограничениях и пробеге. Убедитесь в надёжности машины перед покупкой без лишних затрат. Попробуйте прямо сейчас!
          </p>
          </div>         
          <CustomTextField />
        </div>
        <div className="check-for-one-ruble__right">
          <picture>
            <img loading="lazy" className='check-for-one-ruble__img' src={img} srcSet={`${img_2x} 2x`}
              width="513" height="297" alt="компьютер MacBook Pro" />
          </picture>
        </div>
      </div>
    </section>
  )
}