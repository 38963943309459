import AuthTemplate from "./AuthTemplate";
import HelpBlock from "./HelpBlock";
import './Auth.css';

const HelpPage = () => {
  return (
    <AuthTemplate title={'Помощь'} form={<HelpBlock />} />
  )
}

export default HelpPage