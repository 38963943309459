import './ServiceHistory.css';

export const ServiceHistory = ({ serviceHistory }) => {
  const hasServiceHistory = serviceHistory?.items?.length > 0;
  const serviceHistoryLength = hasServiceHistory ? serviceHistory.items.length : 0;

  return (
    <section className="service-history"  id="service-history">
      <div className="service-history__title">
        <h2 className="section-heading section-heading--service-history">История обслуживания</h2>
        <div className={`service-history__count ${hasServiceHistory ?
          'service-history__count--success' :
          'service-history__count--error'
          }`}>
          {serviceHistoryLength > 0 ?
            `Записи об обслуживании: ${serviceHistoryLength}` :
            'Нет сведений о дилерском обслуживании'}
        </div>
      </div>
      {hasServiceHistory ? (
        <ul className="list-reset service-history__list">
          {serviceHistory?.items?.map((service, index) => (
            <li className="service-history__item" key={index}>
              {service?.date?.end && (
                <p className='service-history__parameter'>Дата:
                  <span> {service.date.end.split(" ")[0]}</span>
                </p>
              )}
              {service?.geo?.city && (
                <p className='service-history__parameter'>Город:
                  <span> {service.geo.city}</span>
                </p>
              )}
              {service?.dealer?.name && (
                <p className='service-history__parameter'>Дилер:
                  <span> {service.dealer.name}</span>
                </p>
              )}

            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}