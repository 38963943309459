import CustomTextFieldFavorites from './CustomTextFieldfavorites/CustomTextFieldFavorites';
import useDocumentTitle from "../../../../utils/useDocumentTitle"
import { FavoritesList } from '../FavoritesList/FavoritesList';

export const TabFavorites = () => {
  useDocumentTitle('Избранные отчеты')  

  return (
    <div className="tab-favorites favorites">
      <div className="favorites__wrapper">
        <CustomTextFieldFavorites />
        <FavoritesList />        
      </div>
    </div>
  )
}