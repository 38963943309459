import { useState } from "react";
import './FinesHistory.css';

export const FinesHistory = ({ fines }) => {
  const [isOpenFines, setIsOpenFines] = useState(false);
  const hasFines = fines?.items?.length > 0;
  const finesLength = hasFines ? fines?.items?.length : 0;
  const isPaidAllFines = !fines?.items?.some(fine => !fine.is_paid);
  const finesCount = 10;

  return (
    <section className="fines-history" id="fines-history">
      <div className="fines-history__title">
        <h2 className="section-heading section-heading--fines-history">История штрафов</h2>
        <div className={`fines-history__count ${isPaidAllFines ?
          'fines-history__count--success' :
          'fines-history__count--error'
          }`}>
          {finesLength > 0 ? `Обнаружено штрафов: ${finesLength} ` : 'Штрафы не найдены'}
        </div>
      </div>
      {hasFines ? (
        <>
          <ul className="list-reset fines-history__list">
            {(isOpenFines ? fines?.items : fines?.items?.slice(0, finesCount))?.map((fines, index) => (
              <li className="fines-history__item" key={index}>
                {fines?.date?.accident && (
                  <p className='fines-history__parameter'>Дата:
                    <span > {` ${fines.date.accident.split(" ")[0]}`}</span>
                  </p>
                )}

                {fines?.amount?.total && (
                  <p className='fines-history__parameter'>Сумма:
                    <span> {` -${fines.amount.total}₽`}</span>
                  </p>
                )}

                {fines?.article?.description && (
                  <p className='fines-history__parameter'>Причина:
                    <span> {` ${fines.article.description}`} </span>
                  </p>
                )}

                {fines?.vendor?.name && (
                  <p className='fines-history__parameter'>От:
                    <span> {` ${fines.vendor.name}`} </span>
                  </p>
                )}
                {fines?.uin && (
                  <p className='fines-history__parameter'>УИН:
                    <span> {` ${fines.uin}`} </span>
                  </p>
                )}

                {(!fines?.need_payment && fines?.is_paid) ? (
                  <p className='fines-history__parameter'>
                    <span style={{ color: '#00E300' }}> {'Оплачен'}</span>
                  </p>
                ) : (fines?.need_payment && !fines?.is_paid) ? (
                  <p className='fines-history__parameter'>
                    <span style={{ color: '#FF0000' }}> {'Не оплачен'}</span>
                  </p>
                ) : (!fines?.need_payment && !fines?.is_paid) ? (
                  <p className='fines-history__parameter'>
                    <span style={{ color: '#004CDB' }}> {'Аннулирован'}</span>
                  </p>
                ) : ''}
              </li>
            ))}
          </ul>
          {(!isOpenFines && fines?.items?.length > finesCount) ?
            (<div className="fines-history__more">
              <button className="btn-reset second-button fines-history__button-more" type="button"
                onClick={() =>
                  setIsOpenFines(true)
                }>
                Показать все штрафы
              </button>
            </div>)
            : ''}
        </>
      ) : ''}
    </section>
  )
}