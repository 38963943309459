import { Box, Typography, TextField, Modal, Grid, Button } from "@mui/material"
import { useState, useEffect, useContext } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { createPaymentRequest } from "../../../api/Payment/CreatePaymentRequest";
import { redirect, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import './PaymentModal.css'


const PaymentModal = ({ open, onClose }) => {
    const [amount, setAmount] = useState(0);
    const [customAmount, setCustomAmount] = useState("");
    const [url, setUrl] = useState()
    const navigate = useNavigate()
    const { authTokens } = useContext(AuthContext)

    const BASE_URL_WITHOUT_PREFIX = process.env.REACT_APP_BASE_URL_WITHOUT_PREFIX;


    useEffect(() => {
        console.log(url)
        if (url) {
            window.open(url, '_blank');
        }
    }, [url, navigate])

    const handleSubmitAmount = (amount) => {
        const numericAmount = parseInt(amount, 10);
        if (!isNaN(numericAmount)) {
            handleCreatePayment(numericAmount);
        }
    };

    const handleCreatePayment = (selectedAmount) => {
        if (typeof selectedAmount === "number" && selectedAmount !== 0) {
            const return_url = BASE_URL_WITHOUT_PREFIX;
            createPaymentRequest({
                amount: selectedAmount,
                return_url: return_url,
                setData: setUrl,
                token: authTokens.access
            });
            setAmount(0);
            setCustomAmount("");
            onClose()
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box>
                <Box
                    sx={{ textAlign: 'center' }}
                    className='modal-payment__header-form'
                >
                    <Typography className='payment-modal-title'>
                        Пополнение баланса
                    </Typography>
                    <CloseIcon
                        className='payment-modal-close-icon'
                        onClick={onClose}
                    />
                </Box>
                <Box className='modal-payment__container'>
                    <Box className='payment-modal-custom-amount'>
                        <Typography className='modal-payment__helper-text'>
                            Для пополнения баланса на произвольную сумму, введите сумму в поле ввода
                        </Typography>
                        <div className="modal-payment__input-wrap">
                            <label className="modal-payment__label visually-hidden" for="payment-input">Введите сумму пополнения</label>
                            <input className="modal-payment__input"
                                type="number"
                                name="payment-input"
                                id="payment-input"
                                placeholder="Введите сумму пополнения"
                                value={customAmount}
                                onChange={(e) => setCustomAmount(e.target.value)}
                                min={0}
                                step={1}
                            />
                        </div>
                        {/* <TextField 
                        className="modal-payment__input"
                            label='Введите сумму пополнения?' 
                            type="number"
                            value={customAmount}
                            onChange={(e) => setCustomAmount(e.target.value)}
                            inputProps={{
                                min: 0,
                                step: 1,
                            }}/> */}
                        <button
                            className='btn-reset modal-payment__custom-amount-btn main-button'
                            onClick={() => handleSubmitAmount(customAmount)}
                        >
                            Пополнить баланс
                        </button>
                    </Box>
                    <Grid container className='payment-modal-btns-container'>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(100)}
                            >
                                100₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(300)}
                            >
                                300₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(500)}
                            >
                                500₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(1000)}
                            >
                                1000₽
                            </button>
                        </Grid>
                    </Grid>
                    <Grid container className='payment-modal-btns-container'>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(1500)}
                            >
                                1500₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(3000)}
                            >
                                3000₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(5000)}
                            >
                                5000₽
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <button
                                className='btn-reset modal-payment__amount-btn main-button'
                                onClick={() => handleSubmitAmount(10000)}
                            >
                                10000₽
                            </button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    )
}

export default PaymentModal