import { useEffect, useState, useContext } from "react"
import AuthContext from "../../../../../../context/AuthContext"
import UserTicketsDataGrid from "../../../../../../components/Tickets/TicketsDataGrid/TicketsDataGrid"
import Loader from "../../../../../../components/Loader/Loader"
import TicketsNotFoundImg from '../../../../../../media/images/TicketsNotFound.png'
import './TicketSystem.css'
import { getTickets } from "../../../../../../api/Tickets/getTickets"
import useDocumentTitle from "../../../../../../utils/useDocumentTitle"
import { CreateTicketForm } from '../CreateTicketForm/CreateTicketForm';
import TicketModal from "../TicketModal/TicketModal"
import success from '../../../../../../media/images/new/success.svg';

const TicketSystem = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { authTokens } = useContext(AuthContext)
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null);
  useDocumentTitle('Помощь')

  const loadTickets = () => {
    // setIsLoading(false);
    getTickets({ setData: setTickets, isLoading: setIsLoading, token: authTokens.access })
  }

  useEffect(() => {
    loadTickets()
  }, [authTokens])

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }
  return (
    <div className="ticket-system">
      <CreateTicketForm onSend={() => loadTickets()} />

      {tickets && (
        <div className="tickets__requests requests">
          <div className="requests__wrap">
            <h4 className="requests__title">Ваши запросы</h4>

            {tickets.length ?
              (<ul className="list-reset requests__list">
                {tickets.map((ticket, index) => (
                  <li className={`requests__item request ${ticket.is_closed ? 'request--answered' : 'request--pending'}`} key={index}
                    onClick={() => setSelectedTicket(ticket)}>
                    <p className="request__theme">Тема:
                      <span> {ticket.subject}</span>
                    </p>
                    <p className="request__answer">Ответ:
                      <span>
                        {ticket.is_closed ?
                          <img src={success} width="16" height="16" alt="иконка" /> :
                          <span className="answer-pending"> Ожидается</span>
                        }
                      </span>
                    </p>
                    <p className="request__new-messages">Новые сообщения:
                      <span> {ticket.unread_messages_count}</span>
                    </p>
                  </li>
                ))}
              </ul>) :
              (<div className="tickets__empty">
                <p className="tickets__empty-text">Сейчас здесь пусто</p>
                <img src={TicketsNotFoundImg} alt="Запросы отсутствуют" width="100" />
              </div>)
              }
          </div>
        </div>
      )}

      {selectedTicket && <TicketModal id={selectedTicket.id} onClose={(needUpdate) => {
        setSelectedTicket(null);
        if (needUpdate) {
          loadTickets();
        }
      }} />}
    </div>
  );
}
export default TicketSystem;
