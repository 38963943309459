import { useNavigate } from 'react-router-dom';
import './ShortAllPoints.css';
import image from '../../../../../../media/images/new/all.png';

export const ShortAllPoints = () => {
  const navigate = useNavigate();

  return (
    <div className="short-all-points">
      <div className="short-all-points__image-wrap">
        <img className="short-all-points__image"
          src={image} width="575" height="354" alt="мужчина стоит возле белого автомобиля" />
        <div className="short-all-points__image-overlay">
          <button className="btn-reset main-button short-all-points__button"
            onClick={() => navigate('/full-report/example')}
          >
            Посмотреть на пример отчета
          </button>
        </div>
      </div>
    </div>
  )
}