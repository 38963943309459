import { useContext, useState, useRef, useEffect } from "react"
import AuthContext from "../../../../context/AuthContext"
import { validateAccountSettings } from "./ValidateAccountSettings";
import { UpdateUserInfo } from "../../../../api/AccountSettings/UpdateUserInfo";
import DialogImageCrop from "../../../../components/AvatarUploader/DialogImageCropper";
import './TabProfile.css';
import defaultAvatar from '../../../../media/images/new/avatar.png';
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { FavoritesList } from '../FavoritesList/FavoritesList';
import { RequestsArchive } from './RequestsArchive/RequestsArchive';



export const TabProfile = () => {
  const { authTokens, user, logoutUser, updateUser } = useContext(AuthContext)
  const [editedUser, setEditedUser] = useState({ first_name: '', last_name: '', email: '' })
  const [newAvatar, setNewAvatar] = useState()
  const [newPassword, setNewPassword] = useState({ password: '', password1: '' })

  useDocumentTitle('Настройки аккаунта')
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  })
  const avatarInputRef = useRef(null);

  useEffect(() => {
    setEditedUser({ ...user })
  }, [user])

  const handleAttachFileClick = () => {
    avatarInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const newAvatar = e.target.files[0];
    setNewAvatar(newAvatar)
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setNewPassword((prevPassword) => ({ ...prevPassword, [name]: value }));
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleAccountSettingSubmit = () => {
    if (validateAccountSettings({
      first_name: editedUser.first_name,
      last_name: editedUser.last_name,
      email: editedUser.email,
      password: newPassword.password,
      password1: newPassword.password1,
      setError: setError,
    })) {
      UpdateUserInfo({
        first_name: editedUser.first_name,
        last_name: editedUser.last_name,
        email: editedUser.email,
        password: newPassword.password,
        token: authTokens.access,
        updateUser: updateUser,
      })
    }
  }

  return (
    <div className="tab-wrap">
      <div className="tab-profile profile">
        <form action="" className="profile-form" method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            handleAccountSettingSubmit();
          }}
        >
          <div className="profile__wrapper">
            <div className="profile__block profile__block--avatar">
              <h4 className="profile__subtitle">Аватарка профиля</h4>
              <div className="profile__wrap profile__wrap--avatar">
                <div className="profile__avatar-wrap">
                  <img className="profile__avatar"
                    src={user?.avatar || defaultAvatar}
                    width="100" height="100"
                    alt="avatar"
                  />
                </div>
                <div className="profile__avatar-change">
                  <img
                    src={user?.avatar || defaultAvatar}
                    width="100" height="100"
                    alt="avatar"
                    onClick={() => handleAttachFileClick()}
                  />
                  <input
                    type="file"
                    ref={avatarInputRef}
                    className="visually-hidden"
                    onChange={handleFileInputChange}
                    accept=".jpg, .jpeg, .png"
                    multiple
                  />
                  <div className="profile__avatar-overlay"></div>
                  {newAvatar &&
                    <DialogImageCrop selectedImage={newAvatar} />
                  }
                </div>
              </div>
            </div>

            <div className="profile__block profile__block--user-data">
              <h4 className="profile__subtitle">Данные</h4>
              <div className="profile__wrap profile__wrap--user-data">
                <div className="profile__input-wrap">
                  <label className="profile__label" htmlFor="first_name">Имя</label>
                  <input
                    className={`profile__input ${!!error.first_name ? 'profile__input--error' : ''}`}
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="Максим"
                    value={editedUser.first_name}
                    onChange={handleFieldChange}
                    error={!!error.first_name}
                  />
                  <span className="profile__message-error">{error.first_name}</span>
                </div>

                <div className="profile__input-wrap">
                  <label className="profile__label" htmlFor="last_name">Фамилия</label>
                  <input
                    className={`profile__input ${!!error.last_name ? 'profile__input--error' : ''}`}
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Максимов"
                    value={editedUser.last_name}
                    onChange={handleFieldChange}
                    error={!!error.last_name}
                  />
                  <span className="profile__message-error">{error.last_name}</span>
                </div>

                <div className="profile__input-wrap">
                  <label className="profile__label" htmlFor="email">E-mail</label>
                  <input
                    className={`profile__input ${!!error.email ? 'profile__input--error' : ''}`}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="admin@check.auto"
                    value={editedUser.email}
                    onChange={handleFieldChange}
                    error={!!error.email}
                  />
                  <span className="profile__message-error">{error.email}</span>
                </div>
              </div>
            </div>

            <div className="profile__block profile__block--change-password">
              <h4 className="profile__subtitle">Сменить пароль</h4>

              <div className="profile__wrap profile__wrap--change-password">
                <div className="profile__input-wrap">
                  <label className="visually-hidden" htmlFor="password">Новый пароль</label>
                  <input
                    className={`profile__input ${!!error.password ? 'profile__input--error' : ''}`}
                    value={newPassword.password}
                    onChange={handlePasswordChange}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Новый пароль"
                    error={!!error.password}
                  />
                  <span className="profile__message-error">{error.password}</span>
                </div>

                <div className="profile__input-wrap">
                  <label className="visually-hidden" htmlFor="password1">Повтор пароля</label>
                  <input
                    className={`profile__input ${!!error.password ? 'profile__input--error' : ''}`}
                    type="password"
                    name="password1"
                    id="password1"
                    placeholder="Повтор пороля"
                    value={newPassword.password1}
                    onChange={handlePasswordChange}
                    error={!!error.password1}
                  />
                  <span className="profile__message-error">{error.password1}</span>
                </div>
              </div>
            </div>

            <div className="profile__buttons">
              <button type="submit" className="btn-reset main-button profile__button profile__button--submit">Сохранить</button>
              <button type="button" className="btn-reset profile__button profile__button--logout"
                onClick={() => logoutUser()}
              >Выйти из аккаунта</button>
            </div>
          </div>
        </form>
      </div>
      <div className="request-archive">
        <h2 className="section-heading section-heading--request-archive">Архив запросов</h2>       

        <RequestsArchive />
      </div>
    </div>
  )
}