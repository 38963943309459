import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { changeReportFavorite } from "../../../../../api/Reports/ChangeReportFavoriteRequest";
import favoriteAdd from '../../../../../media/images/new/favorite-add.svg';
import favoriteRemove from '../../../../../media/images/new/favorite-remove.svg';

const FavoritesItem = ({ report, token }) => {
  const [isFavorite, setIsFavorite] = useState(report.is_favorite || false)
  const codeName = {
    'GRZ': 'Госномер',
    'VIN': 'VIN код',
    'BODY': 'Номер кузова'
  }
  const navigate = useNavigate()

  const handleIconClick = () => {
    changeReportFavorite({
      uuid: report.uuid,
      is_favorite: !isFavorite,
      token: token,
    })
    setIsFavorite(!isFavorite)
  }

  useEffect(() => {
    setIsFavorite(report.is_favorite);
  }, [report?.is_favorite]);

  return (
    <div className="favorites__item" >
      <p className="favorites__date">Дата создания: <span>{report.created_at}</span></p>
      <p className="favorites__number">{codeName[report.body_type]}: <span>{report.body}</span></p>
      <p className="favorites__vehicle">Транспортное средство: <span>{report.model}</span></p>
      <button className="btn-reset main-button favorites__button"
        onClick={() => navigate(`/full-report/${report.uuid}`)}
      >Перейти к запросу</button>
      <button className="btn-reset favorites__add"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick();
        }}>
        {isFavorite
          ? <img src={favoriteAdd} width="24" height="24" alt="добавлено в избранное" />
          : <img src={favoriteRemove} width="24" height="24" alt="добавить в избранное" />
        }
      </button>
    </div>
  )
}

export default FavoritesItem