import './DetailsPledges.css';
import error from '../../../../media/images/new/error.svg';

export const DetailsPledges = () => {
  const pledgesItems = [
    {
      date: '16 августа 2024 года',
      guarantors: {
        name: 'Общество с ограниченной ответственностью «СибЭнергоНаладка»',
        type: 'Юридическое лицо'
      },
      pledgees: {
        name: 'Федеральная налоговая служба (в лице Межрайонной ИФНС России № 7 по Омской области)',
        type: 'Юридическое лицо'
      },
      condition: false,
      number: '2024-009-783636-668/1'
    },
    {
      date: '25 мая 2023 года',
      guarantors: {
        name: 'Банк ВТБ (публичное акционерное общество)',
        type: 'Юридическое лицо'
      },
      pledgees: {
        name: 'Федеральная налоговая служба (в лице Межрайонной ИФНС России № 7 по Омской области)',
        type: 'Юридическое лицо'
      },
      condition: true,
      number: '2024-009-783636-668/1'
    },
  ];

  return (
    <div className="tab tab--pledges tab-pledges">
      <div className="tab__title">
        <h3 className="heading-three heading-three--pledges">Залоги</h3>
        <p className="tab__text">Автомобили в залоге могут стать причиной проблем при продаже или регистрации. Если залог не снят, кредитор может претендовать на транспортное средство</p>
      </div>

      <div className="tab__content-block tab__content-block--pledges">
        <div className="tab-pledges__title-wrap">
          <div className="tab__heading-wrap">
            <h3 className="heading-three heading-three--content">Находится в залоге</h3>
            <img className="tab-pledges__check" src={error} width={20} height={20} />
          </div>
          <p className="tab__text">
            У автомобиля есть действующий залог. Чтобы избежать рисков, рекомендуется проверить возможность снятия обременения перед покупкой
          </p>
        </div>

        <ul className="list-reset tab-pledges__list">
          {
            pledgesItems.map((item, index) => (
              <li className="tab-pledges__item" key={index}>
                <p className="tab-pledges__date">{item.date}</p>
                <div className="tab-pledges__block">
                  <p className="tab-pledges__guarantors">Поручители:</p>
                  <p>
                    <span style={{ opacity: '0.5' }}>Наименование: </span>
                    <span>{item.guarantors.name}</span>
                  </p>
                  <p>
                    <span style={{ opacity: '0.5' }}>Тип: </span>
                    <span>{item.guarantors.type}</span>
                  </p>
                </div>
                <div className="tab-pledges__block">
                  <p className="tab-pledges__pledgees">Залогодержатели:</p>
                  <p>
                    <span style={{ opacity: '0.5' }}>Наименование: </span>
                    <span>{item.pledgees.name}</span>
                  </p>
                  <p>
                    <span style={{ opacity: '0.5' }}>Тип: </span>
                    <span>{item.pledgees.type}</span>
                  </p>
                </div>
                <p className="tab-pledgees__condition">
                  <span style={{ opacity: '0.5' }}>Состояние: </span>
                  <span style={{color: `${item.condition == true ? '#FF0000' : '#00E300'}`}}>
                    {item.condition == true ? 'Находится в залоге' : 'Не находится в залоге'}
                    </span>
                </p>
                <p className="tab-pledgees__number">
                  <span style={{ opacity: '0.5' }}>Номер: </span>
                  <span>{item.number}</span>
                </p>
              </li>
            ))
          }
        </ul>

      </div>
    </div>
  )
}