import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './ShortReportPage.css';
import { ReportContentSection } from './ReportContentSection/ReportContentSection';
import useDocumentTitle from "../../../utils/useDocumentTitle";
import { ButtonUp } from '../../components/ButtonUp/ButtonUp';
import car from '../../../media/images/new/example-img.png';
import car_2x from '../../../media/images/new/example-img_2x.png';
import { ModalBuy } from '../../components/ModalBuy/ModalBuy';

export const ShortReportPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  useDocumentTitle(`Краткий отчет`)

  useEffect(() => {
    window.document.body.style.overflow = modalOpen ? 'hidden' : '';
  }, [modalOpen])

  return (
    <>
      <section className="short-report">
        <div className="example">
          <div className='container example__container'>
            <h2 className="section-heading section-heading--example">DATSUN ON-DO BDO, 2020</h2>
            <div className="example__content">
              <div className="example__car-info">
                <p className="example__vin"><span>VIN: </span>Z8NB******************</p>
                <p className="exmple__car-data">1.6 л, 87 л.с., серый</p>
                <picture>
                  <img loading="lazy" className="example__img" src={car} srcSet={`${car_2x} 2x`}
                    width="339" height="233" alt="серебристый автомобиль" />
                </picture>
              </div>
              <div className="example__report">
                <ul className="list-reset example__list">
                  <li className="example__item">Записи о ДТП</li>
                  <li className="example__item">Владельцы по ПТС</li>
                  <li className="example__item">Расчёт стоимости ремонта</li>
                  <li className="example__item">Страховые выплаты</li>
                  <li className="example__item example__item--report">
                    <Link className="example__item-link" to="/full-report/example">Посмотреть пример отчёта</Link>
                  </li>
                  <li className="example__item">История пробега</li>
                  <li className="example__item">История обслуживания</li>
                  <li className="example__item">Проверка отзывных кампаний</li>
                  <li className="example__item">Страховые полисы</li>
                  <li className="example__item">Штрафы</li>
                </ul>
                <div className="example__buttons">
                  <button className='btn-reset main-button example__button example__button--buy'
                    onClick={() => { setModalOpen(true) }}>Купить полный отчет за 1 руб
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReportContentSection />
      <ModalBuy
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onBuy={() => setModalOpen(false)}
      />
      <ButtonUp />
    </>
  )
}