import AuthTemplate from "./AuthTemplate";
import RegistrationBlock from "./RegistrationBlock";
import './Auth.css';

const RegistrationPage = () => {
  return (
    <AuthTemplate title={'Регистрация'} form={<RegistrationBlock />}/>
  )
}

export default RegistrationPage