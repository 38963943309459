import { Box, Typography, Modal } from "@mui/material"
import SvgForAccidentsHistory from "../AccidentsHistorySVG/SvgForAccidentsHistory";
import CloseIcon from '@mui/icons-material/Close';
import './ModalDamage.css'

export const ModalDamage = ({ accident, onClose }) => {
  return (
    <Modal
      open={!!accident}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <Box
          sx={{ textAlign: 'center' }}
          className='modal-damage__header-form'
        >
          <Typography className='modal-damage__title'>
            Карта повреждений:
          </Typography>
          <CloseIcon
            className='modal-damage__close-icon'
            onClick={onClose}
          />
        </Box>
        <Box className='modal-damage__container'>
          {accident && <SvgForAccidentsHistory accidentsSvgData={accident.damage} vehicleModel={accident.vehicle.model} />}
        </Box>
      </Box>
    </Modal>
  )
}