import './BenefitSection.css';

export const BenefitSection = () => {
  return (
    <section id='benefit' className="benefit">
      <div className='container benefit__container'>
        <h2 className="section-heading section-heading--benefit">Чем полезен <span>Check</span>Auto</h2>
        <ul className="list-reset benefit__list">
          <li className="benefit__item">
            <h4 className="benefit__title">Раскроем все подводные камни</h4>
            <p className="benefit__text">Вы сразу узнаете, если автомобиль имеет серьёзные проблемы и не стоит вашего внимания. Либо получите советы, что уточнить у продавца
              и на что обратить внимание при осмотре</p>
            <div className="benefit__note">
              <span className="benefit__note-text">Расчёт ремонтных работ от 2018 года</span>
              <span className="benefit__note-icon benefit__note-icon--one"></span>
            </div>
          </li>

          <li className="benefit__item">
            <h4 className="benefit__title">Предоставим эксклюзивные данные</h4>
            <p className="benefit__text">Доступ к информации из закрытых источников, включая историю пробега, ремонта
              и обслуживания. Это позволит вам более точно оценить состояние авто перед покупкой</p>
            <div className="benefit__note">
              <span className="benefit__note-text">В залоге у банка до 7 июля 2025 года</span>
              <span className="benefit__note-icon benefit__note-icon--two"></span>
            </div>
          </li>

          <li className="benefit__item">
            <h4 className="benefit__title">Сэкономим ваше время и силы</h4>
            <p className="benefit__text">Наш отчёт включает сведения из множества источников, избавляя вас от необходимости искать их самостоятельно. Всё, что нужно знать
              об автомобиле, будет в одном месте</p>
            <div className="benefit__note">
              <span className="benefit__note-text">Авто имеет 7 серьёзных недостатков</span>
              <span className="benefit__note-icon benefit__note-icon--three"></span>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}