import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReportDetailRequest } from "../../../api/Reports/GetReportDetailRequest";
import AuthContext from "../../../context/AuthContext";
import Loader from "../../../components/Loader/Loader";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import './FullReportPage.css';
import data1 from '../../full-report-example2.json';
import ReportExpire from "../../../pages/Errors/ReportExpire/ReportExpire";
import { hasDecreasingMileage } from "../../../utils/hasDecreasingMileage";
import { upgradeReportRequest } from "../../../api/Reports/UpgradeReportRequest"

import { GeneralInfo } from '../../components/FullReportItems/GeneralInfo/GeneralInfo';
import { RegistrationHistory } from '../../components/FullReportItems/RegistrationHistory/RegistrationHistory';
import { OwnershipHistory } from '../../components/FullReportItems/OwnershipHistory/OwnershipHistory';
import { MileageHistory } from '../../components/FullReportItems/MileageHistory/MileageHistory';
import { AccidentsHistory } from '../../components/FullReportItems/AccidentsHistory/AccidentsHistory';
import { RestrictHistory } from '../../components/FullReportItems/RestrictHistory/RestrictHistory';
import { PledgesHistory } from '../../components/FullReportItems/PledgesHistory/PledgesHistory';
import { TaxiHistory } from '../../components/FullReportItems/TaxiHistory/TaxiHistory';
import { LeasingHistory } from '../../components/FullReportItems/LeasingHistory/LeasingHistory';
import { CommercialUse } from '../../components/FullReportItems/CommercialUse/CommercialUse';
import { FinesHistory } from '../../components/FullReportItems/FinesHistory/FinesHistory';
import { ExecutiveProcedures } from '../../components/FullReportItems/ExecutiveProcedures/ExecutiveProcedures';
import { Arbitration } from '../../components/FullReportItems/Arbitration/Arbitration';
import { AdsHistory } from '../../components/FullReportItems/AdsHistory/AdsHistory';
import { InsuranceHistory } from '../../components/FullReportItems/InsuranceHistory/InsuranceHistory';
import { DiagnosticCards } from '../../components/FullReportItems/DiagnosticCards/DiagnosticCards';
import { RepairsHistory } from '../../components/FullReportItems/RepairsHistory/RepairsHistory';
import { ServiceHistory } from '../../components/FullReportItems/ServiceHistory/ServiceHistory';
import { CustomsHistory } from '../../components/FullReportItems/CustomsHistory/CustomsHistory';
import { Photos } from '../../components/FullReportItems/Photos/Photos';
import { ButtonUp } from '../../components/ButtonUp/ButtonUp';
import { ButtonSummaryUp } from '../../components/ButtonSummaryUp/ButtonSummaryUp';
import { SwiperSlider } from '../../components/SwiperSlider/SwiperSlider';
import ReportPreview from '../../../media/images/ReportPreviewImg.png'
import { ModalBuy } from '../../components/ModalBuy/ModalBuy';

export const FullReportPage = () => {
  const [report, setReport] = useState();
  const [openSwiperModal, setOpenSwiperModal] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const [expire, setExpire] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { uuid } = useParams()
  useDocumentTitle(`Отчет`)

  const loadReport = () => {
    if (uuid == 'example') {
      setReport(data1)
    } else {
      getReportDetailRequest({
        setData: setReport,
        setExpire: setExpire,
        uuid: uuid,
        token: authTokens?.access
      })
    }
  }

  useEffect(() => {
    setIsReady(false);
    const t = setTimeout(() => {
      setIsReady(true);
    }, 2000);
    return () => {
      clearTimeout(t);
    }
  }, [uuid]);

  useEffect(() => {
    loadReport();
  }, [uuid])

  const handleSwiperModalClose = () => {
    setOpenSwiperModal(false)
  }

  useEffect(() => {
    window.document.body.style.overflow = modalOpen ? 'hidden' : '';
  }, [modalOpen])

  const data = report?.data?.data?.content?.content;
  const techData = data?.tech_data;
  const previewImages = data?.images?.photos?.items;
  // const isReady = report?.data?.data?.is_ready
  const brandLogo = techData?.brand?.logotype?.uri;
  const carModelName = techData?.brand?.name?.original;
  const isUpgraded = report?.data?.report?.is_upgraded
  const reportCreatedAt = report?.data?.report?.created_at
  const reportExpiryDate = report?.data?.report?.expiry_date

  const codeType = {
    'GRZ': 'Госномер',
    'VIN': 'VIN код',
    'BODY': 'Номер кузова'
  }

  const getWheelPosition = () => {
    const wheelPosition = techData?.wheel?.position;
    return wheelPosition === 'LEFT' ? 'Левое' :
      wheelPosition === 'RIGHT' ? 'Правое' :
        'Неизвестное';
  };

  const scrollToAnchor = (anchor) => {
    const anchorElement = document.getElementById(anchor);
    if (anchorElement) {
      const topPosition = anchorElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }

  if (expire) {
    return (
      <ReportExpire />
    )
  }

  if (!report) {
    return (
      <Loader />
    )
  }
  return (
    <>
      <section className="full-report-head">
        <div className='container full-report-head__container'>
          <div className="full-report-head__title">
            <div className="full-report-head__brand">
              <img className="full-report-head__brand-logo" src={brandLogo} width="100" height="100" alt="Логотип бренда" />
            </div>
            <h2 className="section-heading section-heading--full-report-head">{carModelName}, {techData?.year}</h2>
          </div>
          <div className="full-report-head__wrap">
            <div className="full-report-head__left">
              <div className="full-report-head__info">
                <p className={`full-report-head__status ${isReady ? 'full-report-head__status--success' : 'full-report-head__status--progress'}`}>
                  Статус проверки:
                  <span> {isReady ? ' Завершен' : ' Отчёт генерируется...'}</span>
                </p>
                <p className="full-report-head__date-start">Дата создания: <span> {reportCreatedAt}</span></p>
                <p className="full-report-head__date-end">Дата истечения: <span> {reportExpiryDate}</span></p>
              </div>

              {previewImages?.length > 0 ?
                <div className="full-report-head__images-block images-block">
                  <div className="images-block__big-image">
                    <img src={previewImages[0].uri} width="300" height="233" alt="фото машины"
                      onClick={() => setOpenSwiperModal(true)}
                    />
                  </div>
                  <div className="images-block__wrap">
                    {previewImages?.length > 1 && <img src={previewImages[1].uri} width="100" height="71" alt="фото машины"
                      onClick={() => setOpenSwiperModal(true)}
                    />}
                    {previewImages?.length > 2 && <img src={previewImages[2].uri} width="100" height="71" alt="фото машины"
                      onClick={() => setOpenSwiperModal(true)}
                    />}

                    {previewImages?.length > 4 ?
                      <div className="images-block__more">
                        <img src={previewImages[3].uri} width="100" height="71" alt="фото машины"
                          onClick={() => setOpenSwiperModal(true)}
                        />
                        <div className="images-block__overlay" onClick={() => setOpenSwiperModal(true)}></div>
                        <p className="images-block__next-photos"
                          onClick={() => setOpenSwiperModal(true)}
                        >
                          Ещё {previewImages.length - 3} фото
                        </p>
                      </div> :
                      previewImages?.length > 3 && <img src={previewImages[3].uri} width="100" height="71" alt="фото машины"
                        onClick={() => setOpenSwiperModal(true)}
                      />}
                  </div>
                </div>
                : /*<p className="images-block__no-images">Нет изображений</p>*/
                <img width="400" height="auto"
                  src={ReportPreview}
                  alt="Default Image"
                  className='report-preview-image'
                  style={{ cursor: 'unset' }}
                />
              }

              {openSwiperModal && <SwiperSlider previewImages={previewImages} onClose={handleSwiperModalClose} />}

            </div>
            <div className="full-report-head__right">
              {techData?.brand?.name?.normalized && (
                <p className="full-report-head__parameter">Бренд:
                  <span> {techData?.brand?.name?.normalized}</span>
                </p>
              )}
              {techData?.year && (
                <p className="full-report-head__parameter">Год выпуска:
                  <span> {techData?.year}</span>
                </p>
              )}

              {getWheelPosition() && (
                <p className="full-report-head__parameter">Расположение руля:
                  <span> {getWheelPosition()}</span>
                </p>
              )}

              {techData?.engine?.fuel?.type && (
                <p className="full-report-head__parameter">Тип двигателя:
                  <span> {techData.engine.fuel.type}</span>
                </p>
              )}
              {techData?.engine?.volume && (
                <p className="full-report-head__parameter">Объем двигателя:
                  <span> {techData.engine.volume} куб. см</span>
                </p>
              )}

              {techData?.engine?.model?.name && (
                <p className="full-report-head__parameter">Модель двигателя:
                  <span> {techData.engine.model.name}</span>
                </p>
              )}
              {techData?.engine?.number && (
                <p className="full-report-head__parameter">Номер двигателя:
                  <span> {techData.engine.number}</span>
                </p>
              )}

              {techData?.engine?.power?.hp && (
                <p className="full-report-head__parameter">Мощность:
                  <span> {techData.engine.power.hp} л.с / {techData.engine.power.kw} кВт</span>
                </p>
              )}
              {techData?.drive?.type && (
                <p className="full-report-head__parameter">Привод:
                  <span> {techData.drive.type}</span>
                </p>
              )}
              {report?.data?.data?.content?.query?.type && (
                <p className="full-report-head__parameter">Госномер:
                  <span> {data?.identifiers?.vehicle?.reg_num || data?.identifiers_masked?.vehicle?.reg_num}</span>
                </p>
              )}
              {report?.data?.data?.content?.query?.type && (
                <p className="full-report-head__parameter">VIN:
                  <span> {data?.identifiers?.vehicle?.vin || data?.identifiers_masked?.vehicle?.vin}</span>
                </p>
              )}
              {techData?.model?.name?.normalized && (
                <p className="full-report-head__parameter">Модель:
                  <span> {techData.model.name.normalized}</span>
                </p>
              )}
              {report?.data?.data?.content?.query?.type && (
                <p className="full-report-head__parameter">Номер кузова:
                  <span> {report.data.data.content.query.body}</span>
                </p>
              )}

              {report?.data?.data?.content?.query?.type && (
                <p className="full-report-head__parameter">Номер ПТС:
                  <span> {data?.identifiers?.vehicle?.pts || data?.identifiers_masked?.vehicle?.pts}</span>
                </p>
              )}

              {report?.data?.data?.content?.query?.type && (
                <p className="full-report-head__parameter">Номер СТС:
                  <span> {data?.identifiers?.vehicle?.sts || data?.identifiers_masked?.vehicle?.sts}</span>
                </p>
              )}

              {techData?.transmission?.type_id && (
                <p className="full-report-head__parameter full-report-head__parameter--transmission">Тип трансмиссии:
                  <br />
                  <span> {techData.transmission.type}</span>
                </p>
              )}

              {techData?.additional_info?.vehicle?.category?.code && (
                <p className="full-report-head__parameter ">Категория ТС:
                  <span> {techData.additional_info.vehicle.category.code}</span>
                </p>
              )}

              {(techData?.weight?.max && techData?.weight?.netto) && (
                <p className="full-report-head__parameter full-report-head__parameter--weight">Масса / Грузоподъёмность:
                  <br />
                  <span> {techData.weight.max} / {techData.weight.netto}</span>
                </p>
              )}

              {techData?.body?.color?.name && (
                <p className="full-report-head__parameter">Цвет: <span> {techData.body.color.name}</span></p>
              )}
            </div>

          </div>
          {!isUpgraded && <button className="btn-reset main-button full-report-head__pay-button"
            onClick={() => { setModalOpen(true) }}
          >Купить полный отчет за 1 руб</button>}
        </div>
      </section>
      <ModalBuy
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onBuy={() => {
          upgradeReportRequest({
            uuid: uuid, token: authTokens?.access, onSuccess: () => {
              loadReport();
              setModalOpen(false);
            }
          })
        }} />
      {isUpgraded &&
        <section className="full-report-details">
          <div className='container full-report-details__container'>
            <div className="full-report-details__left">
              <GeneralInfo data={data} />
              <RegistrationHistory registrationActions={data?.registration_actions} />
              <OwnershipHistory ownerships={data?.ownership?.history} />
              <MileageHistory mileages={data?.mileages} />
              <AccidentsHistory accidents={data?.accidents?.history} />
              <RestrictHistory restrictHistory={data?.restrictions?.registration_actions_archive} />
              <PledgesHistory pledgesHistory={data?.pledges} />
              <TaxiHistory taxiHistory={data?.taxi?.history} />
              <LeasingHistory leasings={data?.leasings} />
              <CommercialUse commercialUse={data?.commercial_use} />
              <FinesHistory fines={data?.fines} />
              <Arbitration arbitration={data?.arbitration} />
              {/* <ExecutiveProcedures executiveProcedures={data?.executive_procedures} /> */}
              <AdsHistory ads={data?.ads?.history} />
              <InsuranceHistory insurances={data?.insurance?.osago} />
              <DiagnosticCards diagnosticCards={data?.diagnostic_cards} />
              <RepairsHistory />
              <ServiceHistory serviceHistory={data?.service_history} />
              <CustomsHistory customs={data?.customs?.history} />
              <Photos photos={data?.images?.photos?.items} onOpen={() => setOpenSwiperModal(true)} />

            </div>
            <div className="full-report-details__right">
              <div className="full-report__summary summary" id="summary">
                <h3 className="summary__title">Недостатки:</h3>
                <ul className="list-reset summary__list summary__list--flaws">
                  {hasDecreasingMileage(data?.mileages) ?
                    <li className="summary__item" onClick={() => scrollToAnchor('mileage-history')}>
                      Есть подозрения на скрутку</li> : ''
                  }
                  {data?.accidents?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('accidents-history')}>
                      Найдено ДТП: {data?.accidents?.history?.items?.length}</li> : ''
                  }
                  {(data?.ownership?.history?.usage?.days && (data?.ownership?.history?.usage?.days / 365 >= 10)) ?
                    <li className="summary__item" onClick={() => scrollToAnchor('service-life')}>
                      Более {Math.floor(data?.ownership?.history?.usage?.days / 365)} лет срока эксплуатации</li> : ''
                  }
                  {data?.fines?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('fines-history')}>
                      Обнаружено штрафов: {data?.fines?.items?.length} </li> : ''
                  }
                  {data?.pledges?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('pledges-history')}>
                      Обнаружено залогов: {data?.pledges?.items?.length} </li> : ''
                  }
                  {data?.taxi?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('taxi-history')}>
                      Есть данные о регистрации в такси </li> : ''
                  }
                  {data?.arbitration?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('arbitration')}>
                      Найдено арбитражей: {data?.arbitration?.items?.length} </li> : ''
                  }
                  {!data?.inspections?.count ?
                    <li className="summary__item" onClick={() => scrollToAnchor('technical-inspections')}>
                      Не проводились техосмотры </li> : ''
                  }
                  {!data?.diagnostic_cards?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('diagnostic-cards')}>
                      Не обнаружено диагностических карт </li> : ''
                  }
                  {!data?.service_history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('service-history')}>
                      Нет сведений о дилерском обслуживании </li> : ''
                  }
                  {!data?.customs?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('customs-history')}>
                      Сведений о прохождении таможни не найдено </li> : ''
                  }
                  {data?.restrictions?.registration_actions_archive?.count ?
                    <li className="summary__item" onClick={() => scrollToAnchor('restrict-history')}>
                      Обнаружены ограничения: {data?.restrictions?.registration_actions_archive?.count} </li> : ''
                  }
                  {!data?.insurance?.osago?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('insurance-history')}>
                      Сведений о страховании не найдено </li> : ''
                  }
                  {data?.leasings?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('leasing-history')}>
                      Обнаружены сведения о лизинге: {data?.leasings?.items?.length} </li> : ''
                  }
                  {data?.commercial_use?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('commercial-history')}>
                      Есть информация о коммерческом использовании: {data?.commercial_use?.items?.length}</li> : ''
                  }
                  {data?.repairs?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('repairs-history')}>
                      Есть информация о ремонтных работах: {data?.repairs?.items?.length}</li> : ''
                  }

                </ul>
              </div>

              <div className="full-report__summary summary">
                <h3 className="summary__title">Преимущества:</h3>
                <ul className="list-reset summary__list summary__list--advantages">
                  {!hasDecreasingMileage(data?.mileages) ?
                    <li className="summary__item" onClick={() => scrollToAnchor('mileage-history')}>
                      Нет подозрений на скрутку</li> : ''
                  }
                  {!data?.accidents?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('accidents-history')}>
                      Не найдено ДТП</li> : ''
                  }
                  {(data?.ownership?.history?.usage?.days && (data?.ownership?.history?.usage?.days / 365 < 10)) ?
                    <li className="summary__item" onClick={() => scrollToAnchor('service-life')}>
                      Менее {Math.ceil(data?.ownership?.history?.usage?.days / 365)} лет срока эксплуатации</li> : ''
                  }
                  {!data?.fines?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('fines-history')}>
                      Штрафов не обнаружено </li> : ''
                  }
                  {!data?.pledges?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('pledges-history')}>
                      Залогов не обнаружено </li> : ''
                  }
                  {!data?.taxi?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('taxi-history')}>
                      Нет данных о регистрации в такси </li> : ''
                  }
                  {!data?.arbitration?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('arbitration')}>
                      Не найдено арбитражей </li> : ''
                  }
                  {data?.inspections?.count ?
                    <li className="summary__item" onClick={() => scrollToAnchor('technical-inspections')}>
                      Пройденные техосмотры: {data?.inspections?.count} </li> : ''
                  }
                  {data?.diagnostic_cards?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('diagnostic-cards')}>
                      Обнаружено диагностических карт: {data?.diagnostic_cards?.items?.length} </li> : ''
                  }
                  {data?.service_history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('service-history')}>
                      Есть сведения о дилерском обслуживании: {data?.service_history?.items?.length} </li> : ''
                  }
                  {data?.customs?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('customs-history')}>
                      Есть сведения о прохождении таможни: {data?.customs?.history?.items?.length} </li> : ''
                  }
                  {!data?.restrictions?.registration_actions_archive?.count ?
                    <li className="summary__item" onClick={() => scrollToAnchor('restrict-history')}>
                      Ограничения не обнаружены </li> : ''
                  }
                  {data?.insurance?.osago?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('insurance-history')}>
                      Обнаружено страхований: {data?.insurance?.osago?.items?.length} </li> : ''
                  }
                  {!data?.leasings?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('leasing-history')}>
                      Не обнаружено сведений о лизинге </li> : ''
                  }
                  {!data?.commercial_use?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('commercial-history')}>
                      Нет информации о коммерческом использовании</li> : ''
                  }
                  {!data?.repairs?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('repairs-history')}>
                      Не обнаружено сведений о ремонтных работах</li> : ''
                  }

                </ul>
              </div>
              <div className="full-report__summary summary">
                <h3 className="summary__title">Прочее</h3>
                <ul className="list-reset summary__list summary__list--other">
                  {data?.registration_actions?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('registration-history')}>
                      Записи о регистрации: {data?.registration_actions?.items?.length}
                    </li> : ''
                  }
                  {data?.ownership?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('ownership-history')}>
                      Записи о владельцах: {data?.ownership?.history?.items?.length}
                    </li> : ''
                  }
                  {data?.ads?.history?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('ads-history')}>
                      Обнаружено объявлений: {data?.ads?.history?.items?.length}</li> : ''
                  }
                  {data?.images?.photos?.items?.length ?
                    <li className="summary__item" onClick={() => scrollToAnchor('photos')}>
                      {data?.images?.photos?.items?.length} фотографий автомобиля</li> : ''
                  }
                </ul>
              </div>

            </div>
          </div>
          <ButtonSummaryUp />
          <ButtonUp />
        </section>}
    </>
  )
}