import { resetPasswordRequest } from "../../../../api/Auth/resetPasswordRequest"
import { useState } from "react"
import { validateResetPasswordForm } from "./ValidateResetForm"

const ForgetPasswordForm = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState({ email: '' });

  const handleResetSubmit = () => {
    if (validateResetPasswordForm(email, setError)) {
      resetPasswordRequest(email)
    }
  };

  return (
    <>
      <form action="" className="auth-form" method="POST" noValidate={true}
        onSubmit={(e) => {
          e.preventDefault();
          handleResetSubmit();
        }}
      >
        <div className="auth-form__container">
          <div className="auth-fields">
            <p className="auth-fields__wrap">
              <label className="visually-hidden" htmlFor="email">email</label>
              <input
                className={`auth-field ${!!error.email ? 'auth-field--error' : ''}`}
                type="email"
                name="email"
                id="email"
                placeholder="mail@example.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="auth-field__message-error">{error.email}</span>
            </p>
          </div>

          <div className="auth-form__buttons">
            <button type="submit" className="btn-reset main-button auth-form__button auth-form__button--recovery">Отправить</button>
          </div>
        </div>
      </form>     
    </>
  )
}

export default ForgetPasswordForm