import './RestrictHistory.css';

export const RestrictHistory = ({ restrictHistory }) => {
  const hasRestricts = restrictHistory?.items?.length > 0;
  const restrictLength = hasRestricts ? restrictHistory.items.length : 0;

  return (
    <section className="restrict-history" id="restrict-history">
      <div className="restrict-history__title">
        <h2 className="section-heading section-heading--restrict-history">Ограничения</h2>
        <div className={`restrict-history__count ${hasRestricts ? 'restrict-history__count--error' : 'restrict-history__count--success'}`}>
          {restrictLength > 0 ? `Ограничений найдено: ${restrictLength}` : 'Ограничения не найдены'}
        </div>
      </div>
      {hasRestricts ?
        <ul className="list-reset restrict-history__list">
          {restrictHistory?.items?.map((restrict, index) => (

            <li className="restrict-history__item" key={index}>
              {restrict?.date?.start && (
                <p className="restrict-history__parameter">
                  Дата:
                  <span className='report-text-bold'> {restrict.date.start.split(" ")[0]}</span>
                </p>
              )}
              {restrict?.initiator?.name && (
                <p className="restrict-history__parameter">
                  Инициатор:
                  <span className='report-text-bold'> {restrict.initiator.name}</span>
                </p>
              )}
              {restrict?.initiator?.region?.name && (
                <p className="restrict-history__parameter">
                  Регион:
                  <span className='report-text-bold'> {restrict.initiator.region.name}</span>
                </p>
              )}
              {restrict?.restrict?.reason && (
                <p className="restrict-history__parameter">
                  Ограничение:
                  <span className='report-text-bold'> {restrict.restrict.reason}</span>
                </p>
              )}
              {restrict?.restrict?.type && (
                <p className="restrict-history__parameter">
                  Тип:
                  <span className='report-text-bold'> {restrict.restrict.type}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
        : (<p style={{ fontWeight: '400' }}>Ограничения не обнаружены</p>)}
    </section>
  )
}