import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material"
import './MileageHistory.css';
import img from '../../../../media/images/new/mileage-history.png';
import { useParams } from "react-router-dom";

export const MileageHistory = ({ mileages }) => {
  const hasMileages = mileages?.items?.length > 0;
  const mileagesLength = hasMileages ? mileages?.items?.length : 0;
  const [hasDecreasingMileage, setHasDecreasingMileage] = useState(false);
  const { uuid } = useParams();

  useEffect(() => {
    if (!Array.isArray(mileages?.items) || mileages?.items?.length === 0) {
      setHasDecreasingMileage(false);
    } else {
      let decreasing = false;
      for (let i = 1; i < mileages?.items?.length; i++) {
        if (mileages?.items[i]?.mileage <= mileages?.items[i - 1].mileage) {
          decreasing = true;
          break;
        }
      }
      setHasDecreasingMileage(decreasing);
    }
  }, [mileages]);


  return (
    <section className="mileage-history" id="mileage-history" >
      <div className="mileage-history__title">
        <h2 className="section-heading section-heading--mileage-history">История пробега</h2>
        <div className={`mileage-history__count ${hasDecreasingMileage ? 'mileage-history__count--error' : 'mileage-history__count--success'}`}>
          {hasDecreasingMileage ? `Найдена скрутка пробега` : 'Нет подозрений на скрутку'}
        </div>
      </div>

      {uuid == 'example' ?
        <div className="mileage-history__block">
          <img src={img} width="729" height="370" alt="график пробега" />
        </div> :
        <>
          {!mileages?.items || mileages.items.length === 0 ? (
            <Typography className='report-text-bold'>Нет информации о пробеге автомобиля</Typography>
          ) : (
            <Box sx={{ marginTop: '10px' }}>
              {mileages?.items.map((mileage, index) => (
                <Box key={index} className="mileage-block">
                  <Box sx={{
                    backgroundColor: mileage.mileage < (mileages?.items[index - 1]?.mileage || 0) ? '#DF4949' : '#82DF49',
                    borderRadius: '4px',
                    padding: '0px 2px',
                    marginRight: '5px',
                  }} />
                  <Typography className="text-item-report">
                    Дата: <span className="report-text-bold">{mileage.date.event.split(" ")[0]}</span>
                  </Typography>
                  <Typography className="text-item-report" sx={{ marginLeft: '15px' }}>
                    Пробег: <span className="report-text-bold">{mileage.mileage} км</span>
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </>
      }
    </section>
  )
}