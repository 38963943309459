import { Slider } from '../../components/Slider/Slider';
import useDocumentTitle from "../../../utils/useDocumentTitle"
import './Auth.css';

const AuthTemplate = ({ title, form }) => {
  useDocumentTitle(title)

  return (
    <section className="auth">
      <div className="container auth__container">
        {form}
        <Slider />
      </div>
    </section>
  )
}

export default AuthTemplate