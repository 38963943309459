import { ShortAccidents } from './ReportBlocks/ShortAccidents/ShortAccidents';
import { ShortLegalRisks } from './ReportBlocks/ShortLegalRisks/ShortLegalRisks';
import { ShortCommercialUse } from './ReportBlocks/ShortCommercialUse/ShortCommercialUse';
import { ShortAllPoints } from './ReportBlocks/ShortAllPoints/ShortAllPoints';
import { ShortSalesHistory } from './ReportBlocks/ShortSalesHistory/ShortSalesHistory';
import { ShortOperationHistory } from './ReportBlocks/ShortOperationHistory/ShortOperationHistory';

import './ReportContentSection.css';
import icon1 from '../../../../media/images/new/icon-accidents.svg';
import icon2 from '../../../../media/images/new/icon-legal.svg';
import icon3 from '../../../../media/images/new/icon-report.svg';
import icon4 from '../../../../media/images/new/icon-car.svg';
import icon5 from '../../../../media/images/new/icon-money.svg';
import icon6 from '../../../../media/images/new/icon-lock-blue.svg';

export const ReportContentSection = () => {
  const shortReportItems = [
    {
      title: 'ДТП и повреждения',
      text: 'Поищем данные о ДТП, расчётах стоимости ремонта и страховых выплатах. Вы узнаете, что и насколько серьёзно было повреждено',
      icon: icon1
    },
    {
      title: 'Юридические риски',
      text: 'Это залог, ограничения и розыск. Если они обнаружатся после покупки, авто могут забрать за долги или не зарегистрировать в ГИБДД.',
      icon: icon2
    },
    {
      title: 'История эксплуатации',
      text: 'Отчёт покажет события: смену владельцев, ремонты, прохождения техосмотров и многое другое',
      icon: icon3
    },
    {
      title: 'Коммерческое использование',
      text: 'Мы проверяем сразу несколько баз данных, чтобы установить, использовался ли автомобиль в коммерческих целях',
      icon: icon4
    },
    {
      title: 'История продаж',
      text: 'Если машина продавалась в интернете, вы сможете посмотреть, как изменились её пробег и стоимость',
      icon: icon5
    },
    {
      title: 'Ещё 28 пунктов проверки в полном отчёте',
      text: 'Мы проверим сразу несколько источников. Вы увидите, как выглядело авто раньше и как изменилось его состояние',
      icon: icon6
    },
  ];

  return (
    <section className="short-report__content">
      <div className='container short-report__container'>
        <div className="short-report__title">
          <h2 className="section-heading section-heading--short-report">Что будет в отчёте</h2>
          <p className="short-report__title-text">Соберём историю машины из открытых и закрытых баз, а также добавим данные от дилеров и автосервисов</p>
        </div>
        <div className="short-report__wrap">
          {shortReportItems.map((item, index) => (
            <div className="short-report__block report-block">
              <div className="report-block__title">
                <img src={item.icon} alt="иконка" width="20" height="20" />
                <h3 className="heading-three">{item.title}</h3>
              </div>
              <p className="report-block__text">{item.text}</p>
              <div className="report-block__info">
                {index == 0 && <ShortAccidents />}
                {index == 1 && <ShortLegalRisks />}
                {index == 2 && <ShortOperationHistory />}                
                {index == 3 && <ShortCommercialUse />}
                {index == 4 && <ShortSalesHistory />}
                {index == 5 && <ShortAllPoints />}
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>
  )
}