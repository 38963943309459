import { useEffect, useState, useContext } from "react"
import { getUserReports } from "../../../../../api/Reports/GetUserReportsRequest";
import { getFilteredReports } from "../../../../../api/Reports/GetFilteredReportsRequest";
import AuthContext from "../../../../../context/AuthContext"
import FavoritesItem from '../../FavoritesList/FavoritesItem/FavoritesItem';
import { CircularProgress } from '@mui/material';
import TicketsNotFoundImg from '../../../../../media/images/TicketsNotFound.png'
import './RequestsArchive.css';

const Loader = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
    }}
    >
      <CircularProgress />
    </div>
  );
};

export const RequestsArchive = () => {
  const [reports, setReports] = useState()
  const { user, authTokens } = useContext(AuthContext)
  const [bodyCode, setBodyCode] = useState('')
  const [error, setError] = useState()

  const handleFiltration = () => {
    getFilteredReports({ setData: setReports, bodyCode: bodyCode, token: authTokens?.access })
  }

  useEffect(() => {
    if (user) {
      getUserReports({ setData: setReports, token: authTokens?.access })
    }
  }, [])


  if (!reports) {
    return <Loader />
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className="check-auto">
          <div className="check-auto__wrap">
            <div className="check-auto__input-wrap">
              <input className="input-reset check-auto__input check-auto__input--profile" type="text"
                placeholder="Код автомобиля"
                value={bodyCode}
                onChange={(e) => setBodyCode(e.target.value)}
              />
              <button className="btn-reset main-button check-auto__button check-auto__button--profile"
                onClick={handleFiltration}
              >Поиск</button>
            </div>
          </div>
        </div>
        <label htmlFor="custom-text-field" className='custom-helper-text'>{error}</label>
      </div>
      <div className="favorites__list">
        {reports?.results?.length > 0 ? (reports?.results?.map((item, index) => (
          <FavoritesItem key={index} report={item} token={authTokens.access} />
        ))) : (
          <div className="check-auto__empty">
            <p className="check-auto__empty-text">Сейчас здесь пусто</p>
            <img src={TicketsNotFoundImg} alt="Запросы отсутствуют" width="100" />
          </div>
        )}
      </div>
    </>
  )
}