import './LeasingHistory.css';

export const LeasingHistory = ({ leasings }) => {
  const hasLeasing = leasings?.items?.length > 0;
  const leasingLength = hasLeasing ? leasings.items.length : 0;

  return (
    <section className="leasing-history" id="leasing-history">
      <div className="leasing-history__title">
        <h2 className="section-heading section-heading--leasing-history">История лизинга</h2>
        <div className={`leasing-history__count ${hasLeasing ?
          'leasing-history__count--error' :
          'leasing-history__count--success'}`}>
          {leasingLength > 0 ? `Записи о лизинге: ${leasingLength}` : 'Не обнаружено сведений о лизинге'}
        </div>
      </div>
      {hasLeasing ? (
        <ul className="list-reset leasing-history__list">
          {leasings?.items?.map((leasing, index) => (
            <li className="leasing-history__item" key={index}>
              {leasing?.date?.event && (
                <p className='leasing-history__parameter'>Дата:
                  <span> {leasing.date.event.split(" ")[0]}</span>
                </p>
              )}
              {leasing?.tin && (
                 <p className='leasing-history__parameter'>TIN:
                  <span> {leasing.tin}</span>
                </p>
              )}
              {leasing?.lessor?.company?.name && (
               <p className='leasing-history__parameter'>Компания лизингодатель:
                  <span> {leasing.lessor.company.name}</span>
                </p>
              )}
              {leasing?.company?.name && (
               <p className='leasing-history__parameter'>Компания лизингополучатель:
                  <span> {leasing.company.name}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}