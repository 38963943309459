import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import PasswordField from "../PasswordField/PasswordField"
// import '../AuthFormStyle.css'
import AuthContext from "../../../../context/AuthContext"
import { validateLoginForm } from "./ValidateLoginForm"
import '../AuthForm.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ email: '', password: '' });
  const { loginUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginSubmit = () => {
    if (validateLoginForm(email, password, setError)) {
      // Call loginUser with the form data
      loginUser({ email, password })
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <form action="" className="auth-form" method="POST" noValidate={true}
        onSubmit={(e) => {
          e.preventDefault();
          handleLoginSubmit();
        }}
      >
        <div className="auth-form__container">
          <div className="auth-fields">
            <p className="auth-fields__wrap">
              <label className="visually-hidden" htmlFor="email">email</label>
              <input
                className={`auth-field ${!!error.email ? 'auth-field--error' : ''}`}
                type="email"
                name="email"
                id="email"
                placeholder="mail@example.com"
                onChange={(e) => setEmail(e.target.value)}
                error={!!error.email}
              />
              <span className="auth-field__message-error">{error.email}</span>
            </p>
            <p className="auth-fields__wrap">
              <label className="visually-hidden" htmlFor="password">пароль</label>
              <div className="auth-field__input-wrap">
                <input
                  className={`auth-field ${!!error.password ? 'auth-field--error' : ''}`}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Пароль"
                  error={!!error.password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className={`btn-reset auth-fields__change-input-type ${showPassword ? 'auth-fields__change-input-type--show' : ''}`}
                onClick={() => handleTogglePassword()}></button>
              </div>
              <span className="auth-field__message-error">{error.password}</span>
            </p>
          </div>

          <div className="auth-form__buttons">
            <button type="submit" className="btn-reset main-button auth-form__button">Войти в аккаунт</button>
            {/* <Link className="auth-form__link auth-form__link--yandex">
              <span className="visually-hidden">Войти через Яндекс</span>
            </Link>
            <Link className="auth-form__link auth-form__link--google">
              <span className="visually-hidden">Войти через Google</span>
            </Link>
            <Link className="auth-form__link auth-form__link--vk">
              <span className="visually-hidden">Войти через Вконтакте</span>
            </Link> */}
          </div>
        </div>
      </form>      
    </>
  )
}

export default LoginForm