import { useContext, useState, useEffect } from "react";
import AuthContext from "../../../../context/AuthContext";
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { getBalanceHistory } from "../../../../api/Payment/getPaymentHistory";
import PaymentModal from '../../../components/PaymentModal/PaymentModal';
import TicketsNotFoundImg from '../../../../media/images/TicketsNotFound.png'
import './TabBalance.css';

const data = [
  {
    timestamp: '26 февраля 2018 года, 12:50',
    initial_amount: 9900,
    operation_type: 'Withdraw',
    tariff: 'Епископ'
  },
  {
    timestamp: '12 марта 2024 года, 15:10',
    initial_amount: 150,
    operation_type: 'Withdraw',
    tariff: 'Альфа'
  },
  {
    timestamp: '26 февраля 2008 года, 12:50',
    initial_amount: 1000,
    operation_type: 'Bonus',
  },
  {
    timestamp: '26 февраля 2020 года, 12:50',
    initial_amount: 1000,
    operation_type: 'Bonus',
  },
];

const operationTranslate = {
  Bonus: 'Пополнение',
  Withdraw: 'Покупка'
}

export const TabBalance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user, authTokens } = useContext(AuthContext);
  const [historyInfo, setHistoryInfo] = useState([]);

  useDocumentTitle('Баланс пользователя');
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const formatNumberWithSpaces = (number) => {
    // Округляем число до двух знаков после точки
    const roundedNumber = Number(number).toFixed(0);

    // Добавляем разделители для тысяч
    const numberWithSpaces = roundedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return numberWithSpaces;
  };

  useEffect(() => {
    getBalanceHistory({ setData: setHistoryInfo, token: authTokens.access })
  }, [])

  const formattedBalance = formatNumberWithSpaces(user?.balance);

  return (
    <div className="tab-balance balance">
      <PaymentModal open={isModalOpen} onClose={handleModalClose} />
      <div className="balance__wrapper">
        <div className="your-balance">
          <h4 className="your-balance__info">Ваш баланс: {formattedBalance} руб.</h4>
          <div className="your-balance__buttons-wrap">
            <button type="button"
              className="btn-reset your-balance__button your-balance__button--top-up-balance"
              onClick={() => setIsModalOpen(true)}>
              Пополнить баланс</button>
            {/* <button type="button"
              className="btn-reset your-balance__button your-balance__button--сancel-subscription">
              Отменить подписку</button> */}
          </div>
        </div>

        <div className="payment-history">
          <h2 className="section-heading section-heading--payment-history">История платежей</h2>
          <div className="payment-history__wrap">
            <div className="payment-history__block payment-history__block--write-off">
              <h4 className="payment-history__title">Списание средств</h4>
              {historyInfo?.length ? historyInfo
                .filter(transaction => transaction.operation_type === 'Withdraw')
                .map((transaction, index) => (
                  <div key={index} className="payment-history__item payment-history__item--write-off">
                    <p className="payment-history__field">Дата: <span>{transaction.timestamp}</span></p>
                    <p className="payment-history__field">Тип: <span>{operationTranslate[transaction.operation_type]}</span></p>
                    <p className="payment-history__field">Тариф: <span>{transaction.tariff}</span></p>
                    <p className="payment-history__field">Сумма: <span>{transaction.initial_amount} руб.</span></p>
                  </div>
                )) : (
                <div className="payment-history__empty">
                  <p className="payment-history__empty-text">Сейчас здесь пусто</p>
                  <img src={TicketsNotFoundImg} alt="Запросы отсутствуют" width="100" />
                </div>
              )}
            </div>

            <div className="payment-history__block payment-history__block--referral-bonuses">
              <h4 className="payment-history__title">Бонусы за рефералов</h4>
              {historyInfo?.length ? historyInfo
                .filter(transaction => transaction.operation_type === 'Bonus')
                .map((transaction, index) => (
                  <div key={index} className="payment-history__item payment-history__item--referral-bonuses">
                    <p className="payment-history__field">Дата: <span>{transaction.timestamp}</span></p>
                    <p className="payment-history__field">Тип: <span>{operationTranslate[transaction.operation_type]}</span></p>
                    <p className="payment-history__field">Сумма: <span>{transaction.initial_amount} руб.</span></p>
                  </div>
                )) : (
                <div className="payment-history__empty">
                  <p className="payment-history__empty-text">Сейчас здесь пусто</p>
                  <img src={TicketsNotFoundImg} alt="Запросы отсутствуют" width="100" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}