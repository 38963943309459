import { Typography } from "@mui/material";
import './SVGstyles.css';
import Legend from "./Legend"
import GenericSVG from "./GenericSVG"
import PassangerCarSVG from "./PassangerCarSVG";
import AutobusSVG from "./AutobusSVG"
import LorrySVG from "./LorrySVG"
import MotorhomeSVG from "./MotorhomeSVG"

const SvgForAccidentsHistory = ({ accidentsSvgData, vehicleModel }) => {
    if (!accidentsSvgData || accidentsSvgData.length === 0) {
        return (<span className="legend__value">Не удалось найти информацию</span>);
    }
    const values = accidentsSvgData?.codes ? accidentsSvgData.codes : accidentsSvgData?.points ? accidentsSvgData.points : [];
    const baseStatement = "report-crash accident-modal__auto-сrash ";
    const basePrefix = "dmg-";

    const carStatus = values?.map(value => basePrefix + value).join(" ") || '';
    const testDiapo = values ? values[0].toString() : '';

    let curDiapo = 0;
    if (testDiapo.length === 3) {
      curDiapo = testDiapo.slice(-2) 
      } else if (testDiapo.length === 2 || testDiapo.length === 1) {
        curDiapo = testDiapo.slice(-1)
      }

    const SvgData = baseStatement + carStatus

    if ( curDiapo >= 1 && curDiapo <= 9 ) {
      return (
          <>
              <GenericSVG SvgData={SvgData} />
              <Legend />
          </>
        )
    } else if ( curDiapo >= 10 && curDiapo <= 25 ) {
      return (
          <>
              <PassangerCarSVG SvgData={SvgData} />
              <Legend />
          </>
      );
    } else if ( curDiapo >= 30 && curDiapo <= 43 ) {
      return (
          <>
              <AutobusSVG SvgData={SvgData} />
              <Legend />
          </>

      )
    } else if ( curDiapo >= 60 && curDiapo <= 80) {
      return (
          <>
              <LorrySVG SvgData={SvgData} />
              <Legend />
          </>
      );
    } else if ( curDiapo >= 90 && curDiapo <= 99 ) {
      return (
          <>
              <MotorhomeSVG SvgData={SvgData} />
              <Legend />
          </>
        )
    } else {
      return (<span className="legend__value">Не удалось найти информацию</span>)
    }
}


export default SvgForAccidentsHistory;
