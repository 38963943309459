import { useContext } from "react"
import AuthContext from "../../../context/AuthContext"
import { useNavigate, Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../../media/images/new/logo-footer.svg';

export const Footer = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate(`/`);

      setTimeout(() => {
        const homeSection = document.getElementById(id);
        if (homeSection) {
          homeSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  return (
    <section className="footer">
      <div className="container footer__container">
        <div className="footer__wrap footer__wrap--top">
          <div className="footer__left">
            <div className="footer__logo-wrap">
              <Link to="/" className='footer__logo-link'>
                <img loading="lazy" className="footer__logo" src={logo} width="112" height="13" alt="checkauto" />
              </Link>
            </div>

          </div>
          <nav className="footer__nav">
            <ul className="list-reset nav__list nav__list--footer">
              <li className="nav__item">
                <button onClick={() => scrollToSection('benefit')}
                  className="btn-reset nav__link nav__link--footer">Чем полезен</button>
              </li>
              <li className="nav__item">
                <button onClick={() => navigate('/short-report')}
                  className="btn-reset nav__link nav__link--footer">Пример отчета</button>
              </li>
              <li className="nav__item">
                <button onClick={() => scrollToSection('reviews')}
                  className="btn-reset nav__link nav__link--footer">Отзывы</button>
              </li>
              <li className="nav__item">
                <button onClick={() => user ? navigate('/user-profile/tickets') : scrollToSection('faq')}
                  className="btn-reset nav__link nav__link--footer">FAQ</button>
              </li>
              <li className="nav__item">
                <button onClick={() => scrollToSection('partners')}
                  className="btn-reset nav__link nav__link--footer">Наши партнеры</button>
              </li>
              <li className="nav__item">
                <button onClick={() => navigate('/help')}
                  className="btn-reset nav__link nav__link--footer">Задать вопрос</button>
              </li>
            </ul>
          </nav>
          <div className='footer__buttons'>
            <button className='btn-reset second-button footer__button footer__button--login'
              onClick={() => navigate('/login')}
            >Вход</button>
            <button className='btn-reset main-button footer__button footer__button--registration'
              onClick={() => navigate('/registration')}
            >Регистрация</button>
          </div>
        </div>
        <div className="footer__wrap footer__wrap--bottom">
          <p className="footer__copyright">
            <span className="footer__copyright-item">© 2025 CHECKAUTO.</span>
            <span className="footer__copyright-item">Все права защищены</span>
          </p>
        </div>
      </div>
    </section>
  )
}