import { useNavigate} from 'react-router-dom';
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import CustomTextField from "../CustomTextField/CustomTextField";
import './FirstScreen.css';
import car from '../../../media/images/new/promo-car.png';
import car_2x from '../../../media/images/new/promo-car_2x.png';
import mercedes from '../../../media/images/new/promo-mercedes.png';
import mercedes_2x from '../../../media/images/new/promo-mercedes_2x.png';

export const FirstScreen = () => {
  const { user, logoutUser } = useContext(AuthContext)

  return (
    <div className='first-screen'>
      <div className='container first-screen__container'>
        <div className='promo-block'>
          <div className='promo-block__container'>
            <div className='promo-block__left'>
              <h1 className='promo__heading'><span>Проверь</span> автомобиль по VIN и госномеру</h1>
              <p className='promo__text'>Самая полная база данных об авто с пробегом. Показывает наличие ДТП, залоги, ремонты, скрутку пробега, ограничения и многое другое</p>
              <div className="promo__check">
                <CustomTextField />
              </div>
            </div>
            <div className='promo-block__right'>
              <picture className='promo-block__main-picture'>
                <img loading="lazy" className='promo__car' src={car} srcSet={`${car_2x} 2x`}
                  width="600" height="231" alt="блестящий новый синий мерседес" />
              </picture>
              <div className="promo__car-block">
                <div className="promo__car-wrap">
                  <div className="promo__car-info">
                    <h3 className='promo__car-title'>Mercedes-Benz С180 2020</h3>
                    <ul className="list-reset promo__car-list">
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">Пробег</span>
                        <span className="promo__car-check promo__car-check--success"></span>
                      </li>
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">Залоги</span>
                        <span className="promo__car-check promo__car-check--success"></span>
                      </li>
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">Ограничения</span>
                        <span className="promo__car-check promo__car-check--success"></span>
                      </li>
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">ДТП</span>
                        <span className="promo__car-check promo__car-check--error"></span>
                      </li>
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">Расчеты стоимости  ремонта</span>
                        <span className="promo__car-check promo__car-check--error"></span>
                      </li>
                      <li className="promo__car-item">
                        <span className="promo__car-parameter">Аукционы битых автомобилей</span>
                        <span className="promo__car-check promo__car-check--success"></span>
                      </li>
                    </ul>
                  </div>
                  <picture className="promo__mercedes-picture">
                    <img loading="lazy" className="promo__mercedes" src={mercedes} srcSet={`${mercedes_2x} 2x`}
                      width="120" height="120" alt="разбитый автомобиль" />
                  </picture>

                </div>
              </div>
            </div>
          </div>
          <div className='promo__features features'>
            <ul className='list-reset features__list'>
              <li className='features__item features__item--one'>
                <h4 className='features__title'>Полная база данных</h4>
                <p className='features__text'>Гарантия достоверности и актуальность данных</p>
              </li>
              <li className='features__item features__item--two'>
                <h4 className='features__title'>Выгодные предложения</h4>
                <p className='features__text'>Проверяйте больше автомобилей и экономьте до 65%</p>
              </li>
              <li className='features__item features__item--three'>
                <h4 className='features__title'>Защита от рисков</h4>
                <p className='features__text'>Сервис помогает избежать финансовых и юридических проблем</p>
              </li>
              <li className='features__item features__item--four'>
                <h4 className='features__title'>Удобство и скорость</h4>
                <p className='features__text'>Мгновенная проверка за пару секунд</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}