import './TabTickets.css';
import TicketSystem from './TicketSystem/TicketSystem/TicketSystem';
import useDocumentTitle from "../../../../utils/useDocumentTitle";
import { AnswersSection } from '../../../components/AnswersSection/AnswersSection';

export const TabTickets = () => {
  useDocumentTitle('Ваши запросы')


  return (
    <div className="tab-tickets tickets">
      <div className="tickets__wrapper">
        <TicketSystem />
        <AnswersSection />
      </div>
    </div >
  )
}