import './DetailsGeneralInfo.css';
import success from '../../../../media/images/new/success.svg';
import error from '../../../../media/images/new/error.svg';

export const DetailsGeneralInfo = () => {
  const generalInfoItems = [
    {
      title: 'Количество владельцев: 1',
      text: 'Автомобиль за всё время эксплуатации имел только одного владельца. Это снижает вероятность скрытых дефектов и сложностей с историей транспортного средства',
      label: success
    }, {
      title: 'Срок эксплуатации: 4 года, 7 месяцев, 20 дней',
      text: 'Автомобиль эксплуатировался в течение среднего срока для рынка подержанных машин',
      label: success
    }, {
      title: 'ДТП ГИБДД: 5',
      text: 'Автомобиль зарегистрирован в пяти дорожно-транспортных происшествиях. Это может свидетельствовать о серьёзных повреждениях кузова и влиянии на безопасность машины',
      label: error
    }, {
      title: 'Ограничения ГИБДД: Нет ограничений',
      text: 'На автомобиль не наложено ограничений на регистрационные действия. Это означает, что его можно свободно зарегистрировать на нового владельца',
      label: success
    },
    {
      title: 'Наличие полиса ОСАГО: Нет полиса ОСАГО',
      text: 'На автомобиль не оформлен действующий полис ОСАГО. Для управления потребуется оформить страховку',
      label: error
    },
    {
      title: 'Штрафы: 22',
      text: 'По данным базы ГИБДД, на автомобиль наложено 22 штрафа. Это может говорить о несоблюдении владельцем правил дорожного движения, что потенциально связано с эксплуатацией автомобиля в интенсивных условиях',
      label: error
    }
  ];

  return (
    <div className="tab tab--general-info general-info">
      <div className="tab__title">
        <h3 className="heading-three heading-three--general-info">Общая информация</h3>
        <p className="tab__text">Автомобильный отчёт содержит сводные данные о владельцах, эксплуатации, ограничениях и других важных аспектах истории транспортного средства</p>
      </div>
      <div className="tab__content-block">
        <div className="tab__content-wrap">
          <ul className="list-reset general-info__list">
            {generalInfoItems.map((itemData, index) => (
              <li className="general-info__item" key={index}>
                <div className="tab__heading-wrap">
                  <h3 className="heading-three">{itemData.title}</h3>
                  <img className="general-info__check" src={itemData.label} width={20} height={20}/>
                </div>
                <p className="tab__text">{itemData.text}</p>
              </li>
            )
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}