import { useEffect, useState } from "react";
import './ButtonSummaryUp.css';

export const ButtonSummaryUp = () => {
    const [showBtn, setShowBtn] = useState(false);
  
    useEffect(() => {
      const showButtonUp = () => {
        if (window.pageYOffset > 300) {
          setShowBtn(true);
        } else {
          setShowBtn(false);
        }
      }
      window.addEventListener('scroll', showButtonUp);
  
      return () => {
        window.removeEventListener('scroll', showButtonUp);
      }
    }, []);

  const scrollToAnchor = (anchor) => {
    const anchorElement = document.getElementById(anchor);
    if (anchorElement) {
      const topPosition = anchorElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }

  return (
    <button className={`btn-reset main-button button--summary-up ${showBtn ? '' : 'hidden'}`}
      onClick={() => scrollToAnchor('summary')}
    >К сводке</button>
  )
}