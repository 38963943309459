import { Box, Typography, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './ModalDetailsDamage.css';


export const ModalDetailsDamage = ({open, onClose, data}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <Box
          sx={{ textAlign: 'center' }}
          className='modal-details-damage__header-form'
        >
          <Typography className='modal-details-damage__title'>
            Карта повреждений:
          </Typography>
          <CloseIcon
            className='modal-details-damage__close-icon'
            onClick={onClose}
          />
        </Box>
        <Box className='modal-details-damage__container'>
          <img src={data.img} alt="" />
        </Box>
      </Box>
    </Modal>
  )
}