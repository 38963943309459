import { useContext, useState, useEffect } from "react"
import AuthContext from "../../../../../../context/AuthContext"
import { createTicket } from "../../../../../../api/Tickets/createTicket"
import useDocumentTitle from "../../../../../../utils/useDocumentTitle";
import { validateTicketForm } from "../../ValidateTicketForm"
import { createTicketMessage } from "../../../../../../api/Tickets/createTicketMessage"
import './CreateTicketForm.css';


export const CreateTicketForm = ({ onSend }) => {
  useDocumentTitle('Ваши запросы')

  const { authTokens } = useContext(AuthContext)
  const [error, setError] = useState({
    subject: '',
    message: '',
  })

  const [createdTicket, setCreatedTicket] = useState()
  const [files, setFiles] = useState([])
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')


  const handleSubmitTicket = () => {
    if (validateTicketForm({ subject: subject, message: message, setError: setError })) {
      createTicket({
        subject: subject,
        createdTicket: setCreatedTicket,
        token: authTokens.access
      })
    }
  }

  useEffect(() => {
    if (createdTicket) {
      createTicketMessage({
        text: message,
        files: files,
        ticket: createdTicket.id,
        token: authTokens.access,
        isSended: () => { onSend() }
      })

      // onClose()
      // window.location.reload(false)
    }
  }, [createdTicket])

  return (

    <div className="tickets__message">
      <form action="" className="tickets__form" method="POST"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitTicket();
        }}
      >
        <div className="tickets__inputs-wrap">
          <div className="tickets__input-wrap tickets__input-wrap--theme">
            <label className="visually-hidden" htmlFor="theme">Введите тему сообщения</label>
            <input
              className={`tickets__input tickets__input--theme ${!!error.subject ? 'tickets__input--error ' : ''}`}
              type="text"
              name="theme"
              id="theme"
              value={subject}
              error={!!error.subject}
              placeholder="Введите тему сообщения"
              onChange={(e) => setSubject(e.target.value)}
            />
            <span className="tickets__message-error">{error.subject}</span>
          </div>
          <div className="tickets__input-wrap tickets__input-wrap--message">
            <label className="visually-hidden" htmlFor="message">Введите сообщение</label>
            <input
              className={`tickets__input tickets__input--message ${!!error.message ? 'tickets__input--error ' : ''}`}
              type="text"
              name="message"
              id="message"
              value={message}
              error={!!error.message}
              placeholder="Введите сообщение"
              onChange={(e) => setMessage(e.target.value)}
            />
            <span className="tickets__message-error">{error.message}</span>
          </div>
        </div>
        <button type="submit" className="btn-reset main-button tickets__button tickets__button--create">Создать запрос</button>
      </form>
    </div>
  )
}