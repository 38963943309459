import './DevicesSection.css';

export const DevicesSection = () => {
  return (
    <section className="devices">
      <div className="container devices__container">
        <h2 className="section-heading section-heading--devices">Пользуйтесь нашим сервисом с любого устройства</h2>
        <div className="devices__wrap">
          <ul className="list-reset devices__list">
            <li className="devices__item">
              <span className="devices__icon devices__icon--one"></span>
              <div className="devices__info">
                <h3 className="devices__title">На вашем смартфоне</h3>
                <p className="devices__text">В любой момент можно проверить авто</p>
              </div>
            </li>
            <li className="devices__item">
            <span className="devices__icon devices__icon--two"></span>
              <div className="devices__info">
                <h3 className="devices__title">Оплачивайте как удобно</h3>
                <p className="devices__text">Любой способ оплаты на выбор</p>
              </div>
            </li>
            <li className="devices__item">
            <span className="devices__icon devices__icon--three"></span>
              <div className="devices__info">
                <h3 className="devices__title">Ежедневная поддержка</h3>
                <p className="devices__text">Рады помочь по телефону с 7 до 24</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}