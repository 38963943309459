import { Autoplay, Scrollbar, Navigation } from 'swiper/modules';
import ReportPreview from '../../../media/images/ReportPreviewImg.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SwiperSlider.css';

export const SwiperSlider = ({ previewImages, onClose }) => {
  return (
    <div className="swiper-slider">
      <div className="swiper-slider__overlay" onClick={onClose}></div>
      <button className="btn-reset swiper-slider__close"
        onClick={onClose}>
        <svg className="swiper-slider__close-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M6.4 19.5L5 18.1L10.6 12.5L5 6.9L6.4 5.5L12 11.1L17.6 5.5L19 6.9L13.4 12.5L19 18.1L17.6 19.5L12 13.9L6.4 19.5Z" fill="#8C8C8C" />
        </svg>
        <span className="visually-hidden">Закрыть</span>
      </button>

      <div className="swiper-slider__wrap">
        <Swiper
          modules={[Scrollbar, Autoplay, Navigation]}
          spaceBetween={5}
          slidesPerView={1}
          navigation
          scrollbar={{ draggable: true }}
          className='report-preview-swiper'
        >
          {previewImages?.length > 0 ? (
            previewImages?.map((image, index) => (
              <SwiperSlide
                className='report-preview-slide'
                key={index}
              >
                <img
                  src={image?.uri}
                  alt="Изображение автомобиля"
                  className='report-preview-image'
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide
              className='report-preview-slide'
              key="default"
            >
              <img
                src={ReportPreview}
                alt="Default Image"
                className='report-preview-image'
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  )
}