import { Link, useParams } from "react-router-dom"
import React, { useState, useEffect, useContext } from "react"
import AuthContext from "../../../../../../context/AuthContext"
import './TicketModal.css'
import Loader from "../../../../../../components/Loader/Loader"
import { validateTicketMessage } from "./ValidateTicketMessage"
import { createTicketMessage } from "../../../../../../api/Tickets/createTicketMessage"
import { getTicketDetail } from "../../../../../../api/Tickets/getTicketDetail"
import { closeTicketRequest } from "../../../../../../api/Tickets/closeTicketRequest"
import { resetTicketUnreadMessages } from "../../../../../../api/Tickets/resetTicketUnreadMessages"
import useDocumentTitle from "../../../../../../utils/useDocumentTitle"


const TicketModal = ({ id, onClose }) => {
  // const {id} = useParams()
  const { authTokens, user } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [error, setError] = useState({
    message: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isSended, setIsSended] = useState(false)
  const [newMessageText, setNewMessageText] = useState('');
  const files = [];

  useDocumentTitle('Тикет')
  const fileTypes = {
    mp3: 'Аудио',
    mp4: 'Видео',
    pdf: 'PDF',
    doc: 'Документ (doc)',
    docx: 'Документ (docx)',
    jpg: 'Изображение (jpg)',
    jpeg: 'Изображение (jpeg)',
    png: 'Изображение (png)',
    gif: 'Изображение (gif)'
  };
  const BASE_URL_WITHOUT_PREFIX = process.env.REACT_APP_BASE_URL_WITHOUT_PREFIX

  useEffect(() => {
    getTicketDetail({ id: id, setData: setData, isLoading: setIsLoading, token: authTokens.access })
    resetTicketUnreadMessages({ id: id, token: authTokens.access })
  }, [id, authTokens])

  useEffect(() => {
    if (isSended) {
      // window.location.reload(false)

    }
  }, [isSended])

  if (isLoading) {
    return (
      <Loader />
    )
  }

  const isImageFile = (filename) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', '.bmp']; // Добавьте нужные расширения
    const ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    return imageExtensions.includes(ext.toLowerCase());
  };

  const handleMessageSubmit = (text, files) => {
    console.log(text)
    if (text || files) {
      if (validateTicketMessage({ selectedFiles: files, setError: setError })) {
        createTicketMessage({
          text: text,
          files: files,
          ticket: id,
          token: authTokens.access,
          isSended: () => {
            getTicketDetail({ id: id, setData: setData, isLoading: setIsLoading, token: authTokens.access })
            resetTicketUnreadMessages({ id: id, token: authTokens.access })
          }
        })
      }
    }
  }


  const handleTicketClose = () => {
    const confirmed = window.confirm("Вы уверены что хотите закрыть тикет ?");
    if (confirmed) {
      closeTicketRequest({
        id: id,
        is_closed: true,
        token: authTokens.access,
        setData: () => {
          onClose(true);
        }
      })
      // window.location.reload(false)
    }
  }

  return (
    <>
      <div className="tickets__overlay"></div>
      <section className="ticket-modal">
        <div className="ticket-modal__wrap">
          <div className="ticket-modal__wrap-top">
            <h3 className="heading-three">Тема запроса: {data?.ticket?.subject}</h3>
            <button className="btn-reset ticket-modal__close-button"
              onClick={() => onClose()}>
              <span className="visually-hidden">Закрыть окно тикета</span>
            </button>
          </div>

          <div className="ticket-modal__messages">
            {data?.messages.map((message, index) => (
              <div className="ticket-modal__message" key={index}>
                <p className="ticket-modal__username">
                  {message?.user_first_name} {message?.user_last_name}
                </p>
                <p className="ticket-modal__message-text">
                  {message?.text}
                </p>
              </div>
            ))}
          </div>

          {!data?.ticket?.is_closed ?
            <div className="ticket-modal__form-wrap">
              <form action="" className="tickets__form" method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleMessageSubmit(newMessageText, files);
                }}
              >
                <div className="tickets__inputs-wrap">
                  <div className="tickets__input-wrap tickets__input-wrap--message">
                    <label className="visually-hidden" htmlFor="message">Введите сообщение</label>
                    <input
                      className={`tickets__input tickets__input--message ${!!error.message ? 'tickets__input--error ' : ''}`}
                      type="text"
                      name="message"
                      id="message"
                      value={newMessageText}
                      error={!!error.message}
                      placeholder="Введите сообщение"
                      onChange={(e) => setNewMessageText(e.target.value)}
                    />
                    <span className="tickets__message-error">{error.message}</span>
                  </div>
                </div>
                <button type="submit" className="btn-reset main-button tickets__button tickets__button--sendmessage">Отправить</button>
                <button type="button" className="btn-reset tickets__button tickets__button--close"
                  onClick={handleTicketClose}
                >Закрыть запрос</button>
              </form>
            </div> :
            <div className="ticket-modal__system-message system-message">
              <p className="system-message__title">Системное уведомление</p>
              <p className="system-message__text">Текущий тикет закрыт! <br />
                Если у вас остались вопросы либо проблема не решена - то создайте новый тикет!</p>
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default TicketModal