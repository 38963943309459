import { useState, Fragment } from "react";
import { ModalDamage } from './ModalDamage/ModalDamage';
import './AccidentsHistory.css';

export const AccidentsHistory = ({ accidents }) => {
  const [isModalDamageOpen, setIsModalDamageOpen] = useState(null)
  const hasAccidents = accidents?.items?.length > 0;
  const accidentLength = hasAccidents ? accidents?.items?.length : 0;

  return (
    <section className="accidents-history" id="accidents-history">
      <div className="accidents-history__title">
        <h2 className="section-heading section-heading--accidents-history">История ДТП</h2>
        <div className={`accidents-history__count ${hasAccidents ? 'accidents-history__count--error' : 'accidents-history__count--success'}`}>
          {accidentLength > 0 ? `Найдено ДТП: ${accidentLength} ` : 'ДТП не обнаружено'}
        </div>
      </div>
      {hasAccidents ?
        <ul className="list-reset accidents-history__list">
          {accidents.items.map((accident, index) => (
            <li className="accidents-history__item accident" key={index}>
              <div className="accident__wrap">
                <div className="accident__info">
                  {accident?.accident?.date && (
                    <p className="accident__parameter">
                      Дата: <span> {accident.accident.date.split(" ")[0]}</span>
                    </p>
                  )}
                  {accident?.number && (
                    <p className="accident__parameter">
                      Номер: <span> {accident.number}</span>
                    </p>
                  )}
                  {accident?.type && (
                    <p className="accident__parameter">
                      Тип ДТП: <span> {accident.type}</span>
                    </p>
                  )}
                  {accident?.geo?.city && (
                    <p className="accident__parameter">
                      Регион: <span> {accident.geo.city}</span>
                    </p>
                  )}
                </div>
                <button className="btn-reset second-button accident__button"
                  onClick={() => {
                    setIsModalDamageOpen(accident);
                  }}>Посмотреть повреждения</button>
              </div>    

              {accident?.other_participants.length > 0 ? (
                <div>
                  <h3 className="heading-three">Другие участники ДТП</h3>
                  {accident?.other_participants?.map((participant, index) => {
                    return (
                      <div key={index}>
                        <h4 className="accident__participant">Участник {index + 1}</h4>
                        <div className="accident__wrap">
                          <div className="accident__info">
                            {participant?.type && (
                              <p className="accident__parameter">
                                Тип ДТП: <span> {participant.type}</span>
                              </p>
                            )}
                            {participant?.vehicle?.model?.name && (
                              <p className="accident__parameter">
                                Автомобиль:
                                <span>
                                  {` ${participant?.vehicle?.brand?.name} ${participant.vehicle.model.name}`}
                                </span>
                              </p>
                            )}
                            {participant?.damage?.raw && (
                              <p className="accident__parameter">
                                Повреждено: <span> {participant.damage.raw}</span>
                              </p>
                            )}
                          </div>
                          {participant?.damage && (
                            <button className="btn-reset second-button accident__button"
                              onClick={() => {
                                setIsModalDamageOpen(participant);
                              }}>Посмотреть повреждения</button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : <h3 className="heading-three">Участники ДТП не обнаружены</h3>}
              <ModalDamage accident={isModalDamageOpen} onClose={() => setIsModalDamageOpen(null)} />
            </li>
          ))}
        </ul>
        : ''}
    </section>
  )
}