import './RegistrationHistory.css';

export const RegistrationHistory = ({ registrationActions }) => {
  const hasRegistrationActions = registrationActions?.items?.length > 0;
  const registrationActionsLength = hasRegistrationActions ? registrationActions.items.length : 0;

  return (
    <section className="registration-history" id="registration-history">
      <div className="registration-history__title">
        <h2 className="section-heading section-heading--registration-history">История регистраций</h2>
        <div className="registration-history__count">
          {registrationActionsLength > 0 ? `Записи о регистрации: ${registrationActionsLength}` : 'Нет сведений о регистрации'}
        </div>
      </div>
      {hasRegistrationActions ? (
        <ul className="list-reset registration-history__list">
          {registrationActions.items.map((registration, index) => (
            <li className="registration-history__item" key={index}>
              {registration?.date?.start && (
                <p className="registration-history__parameter">Дата начала:
                  <span> {registration.date.start.split(" ")[0]}</span>
                </p>
              )}
              {registration?.date?.end && (
                <p className="registration-history__parameter">Дата окончания:
                  <span> {registration.date.end.split(" ")[0]}</span>
                </p>
              )}
              {registration?.geo?.city && (
                <p className="registration-history__parameter">Город:
                  <span> {registration.geo.city}</span>
                </p>
              )}
              {registration?.geo?.street && (
                <p className="registration-history__parameter">Улица:
                  <span> {registration.geo.street}</span>
                </p>
              )}

              {registration?.geo?.house && (
                <p className="registration-history__parameter">Дом:
                  <span> {registration.geo.house}</span>
                </p>
              )}

              {registration?.identifiers?.pts && (
                <p className="registration-history__parameter">ПТС:
                  <span> {registration.identifiers.pts}</span>
                </p>
              )}
              {registration?.identifiers?.sts && (
                <p className="registration-history__parameter">СТС:
                  <span> {registration.identifiers.sts}</span>
                </p>
              )}
              {registration?.owner?.phone_number && (
                <p className="registration-history__parameter">Номер телефона:
                  <span> {registration.owner.phone_number}</span>
                </p>
              )}
              {registration?.type && (
                <p className="registration-history__parameter">Причина:
                  <span> {registration.type}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}