import './ReviewsSection.css';
import reviewImg1 from '../../../media/images/new/review-img1.png';
import reviewImg1_2x from '../../../media/images/new/review-img1_2x.png';
import reviewImg2 from '../../../media/images/new/review-img2.png';
import reviewImg2_2x from '../../../media/images/new/review-img2_2x.png';
import reviewImg3 from '../../../media/images/new/review-img3.png';
import reviewImg3_2x from '../../../media/images/new/review-img3_2x.png';
import star from '../../../media/images/new/star.svg';

export const ReviewsSection = () => {
  const items = [
    {
      name: 'Иван',
      profession: 'Автолюбитель',
      stars: 5,
      date: '15 ноября',
      text: 'Сервис очень помог! Узнал, что автомобиль, который собирался купить, участвовал в серьёзной аварии. Это позволило избежать необдуманной покупки. Всё очень удобно, отчёт пришёл сразу',
      image: reviewImg1,
      image2x: reviewImg1_2x
    },
    {
      name: 'Мария',
      profession: 'Юрист',
      stars: 5,
      date: '10 ноября',
      text: 'Сервис отличный! Проверила машину перед покупкой и узнала, что пробег был скручен. Это сэкономило мне деньги и нервы. Всё чётко, прозрачно и по делу. Отличная работа, спасибо!',
      image: reviewImg2,
      image2x: reviewImg2_2x
    },
    {
      name: 'Алексей',
      profession: 'Водитель',
      stars: 5,
      date: '02 сентября',
      text: 'Очень полезный сервис! В отчёте нашёл информацию о всех владельцах машины, ДТП и даже штрафах. Теперь покупка машины стала намного безопаснее. 100% рекомендую!',
      image: reviewImg3,
      image2x: reviewImg3_2x
    },
  ];

  return (
    <section id='reviews' className="reviews">
      <div className="container reviews__container">
        <h2 className="section-heading section-heading--reviews">Отзывы постоянных пользователей</h2>
        <div className="reviews__wrap">
          <ul className="list-reset reviews__list">
            {items.map((item, index) => (
              <li className="reviews__item review" key={index}>
                <div className="review__wrap">
                  <div className="review__title">
                    <div className="review__user">
                      <p className="review__name">{item.name}</p>
                      <p className="review__profession">{item.profession}</p>
                    </div>
                    <div className="review__info">
                      <div className="review__stars">
                        {new Array(item.stars).fill(0).map((item, index) => (
                          <img loading="lazy" key={index} src={star} width="16" height="16" alt="рейтинг" />
                        ))}
                      </div>
                      <p className="review__date">{item.date}</p>
                    </div>
                  </div>
                  <div className="review__text">{item.text}</div>
                </div>
                <div className="review__image-wrap">
                  <picture className="review__picture">
                    <img loading="lazy" className="review__img" src={item.img} srcSet={`${item.image2x} 2x`}
                      width="324" height="140" alt="фото машины" />
                  </picture>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>

  )
}