import './ShortSalesHistory.css';
import img1 from '../../../../../../media/images/new/auto1.jpg';
import img2 from '../../../../../../media/images/new/auto2.jpg';
import img3 from '../../../../../../media/images/new/auto3.jpg';
import img4 from '../../../../../../media/images/new/auto4.jpg';

export const ShortSalesHistory = () => {
  return (
    <div className="short-block short-sales-history">
      <h4 className="short-sales-history__title">Размещение на Авито от 10 августа 2021 года</h4>
      <div className="short-sales-history__images-wrap">
        <img src={img1} width="110" height="auto" alt="красная машина" />
        <img src={img2} width="110" height="auto" alt="салон машины" />
        <img src={img3} width="110" height="auto" alt="спидометр" />
        <div className="short-sales-history__all-images">
          <img src={img4} width="110" height="auto" alt="красная машина" />
          <div className="short-sales-history__overlay">
            <p className="short-sales-history__more">Еще 9 фото</p>
          </div>
        </div>
      </div>
      <p className="short-sales-history__text">
        Учётный № 5064965. Автомобиль был куплен новым у официального дилера в 2017 году.
        Один собственник. Ключевые опции: Электростеклоподъемники, ...
        <span> Показать полностью</span>
      </p>
      <div className="short-sales-history__info">
        <p className="short-sales-history__parameter">Цена:
          <span> 429 000 &#8381;</span>
          <span className="short-sales-history__decrease"> &#8595; 50 000 &#8381;</span>
        </p>
        <p className="short-sales-history__parameter">Продавец:
          <span> Частное лицо</span>
        </p>
        <p className="short-sales-history__parameter">Пробег:
          <span> 93 840 км</span>
        </p>
        <p className="short-sales-history__parameter">Регион:
          <span> г. Москва</span>
        </p>
      </div>
    </div>
  )
}