import './RepairsHistory.css';

export const RepairsHistory = ({ repairs }) => {
  const hasRepairs = repairs?.items?.length > 0;
  const repairsLength = hasRepairs ? repairs.items.length : 0;

  return (
    <section className="repairs-history" id="repairs-history">
      <div className="repairs-history__title">
        <h2 className="section-heading section-heading--repairs-history">Ремонтные работы</h2>
        <div className={`repairs-history__count ${hasRepairs ?
          'repairs-history__count--error' :
          'repairs-history__count--success'
          }`}>
          {repairsLength > 0 ?
            `Сведения о ремонтных работах: ${repairsLength}` :
            'Не обнаружено сведений о ремонтных работах'}
        </div>
      </div>
      {hasRepairs ? (
        <ul className="list-reset repairs-history__list">
          {repairs?.items?.map((repair, index) => (
            <li className="repairs-history__item" key={index}>
              {repair?.date?.event && (
                <p className='repairs-history__parameter'>Дата:
                  <span> {repair.date.event.split(" ")[0]}</span>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}