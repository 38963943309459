import useDocumentTitle from '../../../utils/useDocumentTitle';
import { ButtonUp } from '../../components/ButtonUp/ButtonUp';
import './PrivacyPolicyPage.css';

export const PrivacyPolicyPage = () => {
  useDocumentTitle(`Политика конфеденциальности`);

  return (
    <section className="privacy">
      <div className='container privacy__container'>
        <h2 className="section-heading section-heading--privacy">Политика конфеденциальности</h2>
        <p className="privacy__text">
          Настоящая Политика конфиденциальности (далее — «Политика») регулирует обработку и защиту персональных данных пользователей (далее — «Пользователь»), предоставляемых компании CheckAuto (далее — «Компания») при использовании сайта https://check.auto/ (далее — «Сайт»).
        </p>
        <ol className="privacy__list">
          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Настоящая Политика разработана в соответствии с действующим законодательством Российской Федерации в области защиты персональных данных.
              </li>
              <li className="privacy__inner-item">
                Использование Сайта Пользователем означает согласие с настоящей Политикой.
              </li>
              <li className="privacy__inner-item">
                В случае несогласия с условиями Политики Пользователь должен прекратить использование Сайта.
              </li>
              <li className="privacy__inner-item">
                Политика распространяется только на Сайт и не регулирует деятельность сторонних ресурсов, ссылки на которые могут быть размещены на Сайте.
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">СОБИРАЕМЫЕ ДАННЫЕ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                При использовании Сайта Компания может собирать следующие персональные данные Пользователя:
                <ul className="privacy__unordered-list">
                  <li>Имя и фамилия;</li>
                  <li>Контактный телефон;</li>
                  <li>Адрес электронной почты;</li>
                  <li>VIN-код и номер автомобиля;</li>
                  <li>Иные данные, предоставленные Пользователем. </li>
                </ul>
              </li>
              <li className="privacy__inner-item">
                Также автоматически собираются данные технического характера:
                <ul className="privacy__unordered-list">
                  <li>IP-адрес Пользователя;</li>
                  <li>Тип браузера и операционной системы;</li>
                  <li>Время и дата посещения Сайта;</li>
                  <li>Источники перехода на Сайт.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ЦЕЛИ ОБРАБОТКИ ДАННЫХ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Собранные данные используются для следующих целей:
                <ul className="privacy__unordered-list">
                  <li>Предоставление услуг, заказанных Пользователем;</li>
                  <li>Улучшение работы Сайта и пользовательского опыта;</li>
                  <li>Обратная связь с Пользователем;</li>
                  <li>Информирование о новостях, акциях и обновлениях;</li>
                  <li>Исполнение требований законодательства Российской Федерации.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ХРАНЕНИЕ И ЗАЩИТА ДАННЫХ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Компания принимает все необходимые организационные и технические меры для защиты персональных данных Пользователей от утраты, несанкционированного доступа, изменения и раскрытия.
              </li>
              <li className="privacy__inner-item">
                Персональные данные хранятся в течение срока, необходимого для достижения целей их обработки, если иное не предусмотрено законодательством.
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ПЕРЕДАЧА ДАННЫХ ТРЕТЬИМ ЛИЦАМ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Компания может передавать персональные данные третьим лицам в следующих случаях:
                <ul className="privacy__unordered-list">
                  <li>Для выполнения услуг, заказанных Пользователем (например, передача данных проверяющим организациям);</li>
                  <li>По запросу уполномоченных государственных органов в соответствии с законодательством Российской Федерации;</li>
                  <li>В иных случаях с согласия Пользователя.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ПРАВА ПОЛЬЗОВАТЕЛЕЙ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Пользователь имеет право:
                <ul className="privacy__unordered-list">
                  <li>Получать информацию о своих персональных данных, обрабатываемых Компанией;</li>
                  <li>Требовать уточнения, блокировки или уничтожения своих персональных данных в случае их неполноты, устаревания или незаконной обработки;</li>
                  <li>Отозвать согласие на обработку персональных данных. </li>
                </ul>
              </li>
              <li className="privacy__inner-item">
                Для реализации своих прав Пользователь может обратиться в Компанию по контактам, указанным в разделе 8 Политики.
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">ИЗМЕНЕНИЯ В ПОЛИТИКЕ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                Компания оставляет за собой право вносить изменения в настоящую Политику. Актуальная редакция Политики размещается на Сайте.
              </li>
              <li className="privacy__inner-item">
                Изменения вступают в силу с момента их публикации на Сайте, если иное не предусмотрено новой редакцией Политики.
              </li>
            </ol>
          </li>

          <li className="privacy__item">
            <h3 className="heading-three heading-three--privacy">КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
            <ol className="privacy__inner-list">
              <li className="privacy__inner-item">
                По вопросам, связанным с настоящей Политикой и обработкой персональных данных, Пользователь может связаться с Компанией:
                <ul className="privacy__unordered-list">
                  <li>Электронная почта: support@check.auto</li>
                  <li>Телефон: +7 (XXX) XXX-XX-XX</li>
                  <li>Юридический адрес: [указать адрес] </li>
                </ul>
              </li>
              Настоящая Политика конфиденциальности является неотъемлемой частью пользовательского соглашения Компании.
            </ol>
          </li>
        </ol>
      </div >
      <ButtonUp />
    </section >
  )
}