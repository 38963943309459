import { Box } from "@mui/material"
import './CustomTextField.css'
import { Link, useNavigate } from "react-router-dom";
import HelpModal from "../../../components/HelpModal/HelpModal";
import { codeValidation } from "../../../utils/FieldValidation";
import { useContext, useEffect, useState } from "react";
import { createReportRequest } from "../../../api/Reports/CreateReportRequest";
import AuthContext from "../../../context/AuthContext";


const CustomTextField = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const [code, setCode] = useState()
  const [error, setError] = useState()
  const { user, authTokens } = useContext(AuthContext)
  const [codeType, setCodeType] = useState('GRZ')
  const navigate = useNavigate()
  const [reportStatus, setReportStatus] = useState('initial');

  const placeholders = {
    VIN: 'VIN - пример: JT1L0EE9007092333',
    GRZ: 'A 007 AA | 197',
    BODY: 'Кузов - пример: LN130-0128818'
  };
  const [uuid, setUuid] = useState()

  const handleFieldSubmit = () => {
    if(!user) {
      navigate('/login');
      return;
    }
    if (user?.request_quantity >= 1) {
      const errorMessage = codeValidation(code, codeType);
      setError(errorMessage);

      if (!errorMessage) {
        setReportStatus('sent');

        createReportRequest({
          setUuid: setUuid,
          code: code,
          codeType: codeType,
          token: authTokens.access
        })

        setTimeout(() => {
          setReportStatus('ready');
          createReportRequest({
            setUuid: setUuid,
            code: code,
            codeType: codeType,
            token: authTokens.access
          })
        }, 4000);
      }
    } else {
      navigate('/tariff-plans');
    }
  }

  const reportButton = {
    'initial': (
      <button className="btn-reset main-button check-auto__button"
        onClick={handleFieldSubmit}
      >Проверить</button>
    ),
    'sent': (
      <button className="btn-reset main-button check-auto__button check-auto__button--sent"
        disabled={true}
      >Идет запрос</button>
    ),
    'ready': (
      <button className="btn-reset main-button check-auto__button check-auto__button--ready"
        onClick={handleFieldSubmit}
      >Готово</button>
    )
  }[reportStatus];

  useEffect(() => {
    if (uuid) {
      navigate(`/full-report/${uuid}/`)
    }
  }, [uuid])

  const handleTextInput = (e) => {
    setCode(e.target.value)
  }

  const handleClickHelpIcon = () => {
    setIsHelpModalOpen(!isHelpModalOpen)
  }

  const handleCloseHelpModal = () => {
    setIsHelpModalOpen(false);
  };

  return (
    <Box style={{ position: 'relative' }}>
      <HelpModal open={isHelpModalOpen} onClose={handleCloseHelpModal} />

      <div className="check-auto">
        <div className="check-auto__wrap">
          <div className="check-auto__tab-wrap">
            <p className={`select-code-type ${codeType === 'GRZ' ? 'select-code-type--active' : ''}`}
              onClick={() => setCodeType('GRZ')}
            >Госномер</p>
            <p className={`select-code-type ${codeType === 'VIN' ? 'select-code-type--active' : ''}`}
              onClick={() => setCodeType('VIN')}
            >VIN-код</p>
            <p className={`select-code-type ${codeType === 'BODY' ? 'select-code-type--active' : ''}`}
              onClick={() => setCodeType('BODY')}
            >Кузов</p>
          </div>
          <div className="check-auto__input-wrap">
            <input className="input-reset check-auto__input" type="text"
              placeholder={placeholders[codeType]}
              onChange={(e) => handleTextInput(e)}
            />
            {reportButton}
          </div>
        </div>
      </div>

      <label htmlFor="custom-text-field" className='custom-helper-text'>{error}</label>
    </Box>
  )
}

export default CustomTextField
