import React, { useContext, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import AuthContext from "../../../../context/AuthContext"
import { sendFeedbackEmailApi } from "../../../../api/sendFeedbackEmailRequest";
import { validateHelpForm } from "./ValidateHelpForm"
import '../AuthForm.css';

const HelpForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({ name: '', email: '' });
  const [success, setSuccess] = useState(false)
  const { authTokens } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleHelpSubmit = () => {
    if (validateHelpForm(name, email, setError)) {
      sendFeedbackEmailApi({
        email_from: email,
        subject: name,
        message: name,
        files: [],
        token: authTokens.access,
        setSuccess: setSuccess
      })
    }
  };

  useEffect(() => {
    if (success) {
      navigate('/')
    }
  }, [success]);

 return (
    <>
      <form action="" className="auth-form" method="POST" noValidate={true}
        onSubmit={(e) => {
          e.preventDefault();
          handleHelpSubmit();
        }}
      >
        <div className="auth-form__container">
          <div className="auth-fields">

            <p className="auth-fields__wrap">
              <label className="visually-hidden" htmlFor="name">name</label>
              <input
                className={`auth-field ${!!error.name ? 'auth-field--error' : ''}`}
                type="text"
                name="name"
                id="name"
                placeholder="Ваше имя"
                onChange={(e) => setName(e.target.value)}
                error={!!error.name}
              />
              <span className="auth-field__message-error">{error.name}</span>
            </p>

            <p className="auth-fields__wrap">
              <label className="visually-hidden" htmlFor="email">email</label>
              <input
                className={`auth-field ${!!error.email ? 'auth-field--error' : ''}`}
                type="email"
                name="email"
                id="email"
                placeholder="mail@example.com"
                onChange={(e) => setEmail(e.target.value)}
                error={!!error.email}
              />
              <span className="auth-field__message-error">{error.email}</span>
            </p>
          </div>
          <div className="auth-form__buttons">
            <button type="submit" className="btn-reset main-button auth-form__button auth-form__button--help">Отправить</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default HelpForm