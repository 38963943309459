import './GeneralInfo.css';
import { accusativeCase, nominative, prepositionalCase, numToPr, numberCases } from '../../../../utils/numbersToString';
import error from '../../../../media/images/new/error.svg';
import success from '../../../../media/images/new/success.svg';

const ownersToString = (num) => {
  return `${numToPr(num, accusativeCase).toLowerCase()} ${numberCases(num, "владельца", "владельцев", "владельца", "владельцев")}`
}

const accidentsToString = (num) => {
  return `${numToPr(num, prepositionalCase).toLowerCase()} ${numberCases(num, "дорожно-транспортном происшествии", "дорожно-транспортных происшествиях", "дорожно-транспортных происшествиях", "дорожно-транспортных происшествиях")}`
}

const finesToString = (num) => {
  return `${num < 1000 ?
    numToPr(num, nominative).toLowerCase() :
    num.toString()
    } ${numberCases(num, "штраф", "", "штрафа", "штрафов")}`
}

const arbitrationsToString = (num) => {
  return `${num.toString()} ${numberCases(num, "арбитражное дело", "", "арбитражных дела", "арбитражных дел")}`
}


export const GeneralInfo = ({ data }) => {
  const days = data?.ownership?.history?.usage?.days
  // const isSmScreen = useMediaQuery('(max-width:600px)');

  const formatDuration = (days) => {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    let result = '';

    if (years > 0) {
      result += `${years} ${years === 1 ? 'год' : years > 1 && years < 5 ? 'года' : 'лет'}`;
    }

    if (months > 0) {
      result += `${result ? ', ' : ''}${months} ${months === 1 ? 'месяц' : months > 1 && months < 5 ? 'месяца' : 'месяцев'}`;
    }

    if (remainingDays > 0) {
      result += `${result ? ', ' : ''}${remainingDays} ${remainingDays === 1 ? 'день' : remainingDays > 1 && remainingDays < 5 ? 'дня' : 'дней'}`;
    }

    return result || 'Срок эксплуатации не найден';
  };

  return (
    <div className="general-info">
      <h2 className="section-heading section-heading--general-info">Общая информация</h2>
      <div className="general-info__content">
        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Количество владельцев:
              <span>{data?.ownership?.history?.count ? ` ${data.ownership.history.count}` : ' Не найдено владельцев'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.ownership?.history?.count ? success : error}
                width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.ownership?.history?.count ?
              `Автомобиль за всё время эксплуатации имел только ${ownersToString(data?.ownership?.history?.count)}.
            Это снижает вероятность скрытых дефектов и сложностей с историей транспортного средства` :
              `Автомобиль за всё время эксплуатации не имел владельцев.`}
          </p>
        </div>

        <div className="general-info__block" id="service-life">
          <div className="general-info__title">
            <h4 className="general-info__heading"> Срок эксплуатации:
              <span> {days ? formatDuration(days) : 'Срок эксплуатации не найден'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={days / 365 < 10 ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {days / 365 < 10 ?
              `Автомобиль эксплуатируется менее ${Math.ceil(data?.ownership?.history?.usage?.days / 365)} ${numberCases(Math.ceil(data?.ownership?.history?.usage?.days / 365), "года", "", "лет", "лет")}` :
              `Автомобиль эксплуатируется уже более ${Math.floor(data?.ownership?.history?.usage?.days / 365)} ${numberCases(Math.floor(data?.ownership?.history?.usage?.days / 365), "года", "", "лет", "лет")}, что может говорить о значительном износе ключевых узлов
            и механизмов. Такой возраст машины требует повышенного внимания к её техническому состоянию`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">ДТП ГИБДД:
              <span>
                {data?.accidents?.history?.count
                  ?
                  ` ${data.accidents.history.count}`
                  :
                  ' Не найдено ДТП'}
              </span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.accidents?.has_accidents ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.accidents?.has_accidents ?
              `Автомобиль участвовал в ${accidentsToString(data?.accidents?.history?.count)}.
            Это может указывать на возможные скрытые повреждения кузова или других элементов, которые требуют проверки` :
              `Автомобиль не участвовал в дорожно-транспортных происшествиях`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Ограничения ГИБДД:
              <span>
                {data?.restrictions?.registration_actions_archive?.count
                  ?
                  ` ${data.restrictions.registration_actions_archive.count}`
                  :
                  ' Нет ограничений'}
              </span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.restrictions?.registration_actions_archive?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.restrictions?.registration_actions_archive?.count ?
              `На автомобиль наложены ограничения на регистрационные действия.` :
              `На автомобиль не наложено ограничений на регистрационные действия.
            Это означает, что его можно свободно зарегистрировать на нового владельца`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Залоги:
              <span>{data?.pledges?.count ? ` ${data?.pledges?.count}` : ' Залоги не найдены'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.pledges?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.pledges?.count ?
              `На автомобиль наложено несколько залогов, что создает риски для покупателя, так как машина может быть изъята за долги предыдущего владельца` :
              `На автомобиль не наложены залоги`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Штрафы:
              <span>{data?.fines?.count ? ` ${data?.fines?.count}` : ' Штрафы не обнаружены'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.fines?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.fines?.count ?
              `По данным базы ГИБДД, на автомобиль наложено ${finesToString(data?.fines?.count)}.
            Это может говорить о несоблюдении владельцем правил дорожного движения, что потенциально связано с эксплуатацией автомобиля в интенсивных условиях` :
              `По данным базы ГИБДД, на автомобиль не наложены штрафы.`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Наличие полиса ОСАГО:
              <span>{data?.insurance?.has_osago ? ' Есть полис ОСАГО' : ' Нет полиса ОСАГО'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.insurance?.has_osago ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.insurance?.has_osago ?
              `На автомобиль оформлен действующий полис ОСАГО, что свидетельствует о соблюдении владельцем требований законодательства и позволяет сразу использовать транспортное средство на дорогах` :
              `На автомобиль не оформлен действующий полис ОСАГО, что свидетельствует о не соблюдении владельцем требований законодательства и не позволяет сразу использовать транспортное средство на дорогах`
            }
          </p>
        </div>

        <div className="general-info__block" id="technical-inspections">
          <div className="general-info__title">
            <h4 className="general-info__heading">Пройденные техосмотры:
              <span>{data?.inspections?.count ? ` ${data?.inspections?.count}` : ' Не проводились'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.inspections?.count ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.inspections?.count ?
              `Автомобиль проходил обязательные техосмотры, что может говорить об отсутствии проблем с техническим состоянием и достаточном внимании владельца к регулярному обслуживанию` :
              `Автомобиль не проходил обязательные техосмотры, что может говорить о возможных проблемах с техническим состоянием или недостаточном внимании владельца к регулярному обслуживанию`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Использование в такси:
              <span>{data?.taxi?.used_in_taxi ? ' Использовалось' : ' Не использовалось'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.taxi?.used_in_taxi ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.taxi?.used_in_taxi ?
              `Автомобиль использовался в качестве такси, что говорит о повышенной интенсивности эксплуатации, возможном износе и высоком пробеге` :
              `Автомобиль не использовался в качестве такси`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Использование в лизинге:
              <span>{data?.leasing?.count ? ' Было в лизинге' : ' Не было в лизинге'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.leasing?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.leasing?.count ?
              `Автомобиль находился в лизинге, что повышает вероятность интенсивного использования и быстрого износа.` :
              `Автомобиль не находился в лизинге, что снижает вероятность интенсивного использования и быстрого износа.`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Коммерческое использование:
              <span>{data?.commercial_use?.count ? ' Использовалось' : ' Не использовалось'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.commercial_use?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.commercial_use?.count ?
              `Автомобиль использовался в коммерческих целях` :
              `Автомобиль не использовался в коммерческих целях, что обычно свидетельствует о меньшем износе и аккуратной эксплуатации`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Арбитраж:
              <span>{data?.arbitration?.count ? ` ${data?.arbitration?.count}` : ' Нет информации об арбитраже'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.arbitration?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.arbitration?.count ? `На автомобиль зарегистрировано ${arbitrationsToString(data?.arbitration?.count)}. 
            Это может указывать на юридические споры, связанные с правами собственности или долговыми обязательствами, что увеличивает риск покупки` :
              `На автомобиль не зарегистрированы арбитражные дела`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Ремонтные работы:
              <span>{data?.repairs?.history?.count ? ` ${data.repairs.history.count}` : ' Не найдено'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.repairs?.history?.count ? error : success} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.repairs?.history?.count ?
              `По данным проверки, автомобиль подвергался серьёзным ремонтным работам.` :
              `По данным проверки, автомобиль не подвергался серьёзным ремонтным работам. Это указывает на его хорошее техническое состояние`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Сервисное обслуживание:
              <span>{data?.service?.count ? ` ${data?.service?.count}` : ' Не обслуживалось'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.service?.count ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.service?.count ?
              `Автомобиль проходил сервисное обслуживание` :
              `Автомобиль не проходил сервисное обслуживание, что может повышать риск скрытых неисправностей или недостаточного ухода за транспортным средством`
            }
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Таможня:
              <span>{data?.customs?.history?.count ? ` ${data?.customs?.history?.count}` : ' Не найдено'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.customs?.history?.count ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.customs?.history?.count ?
              ` На автомобиль есть информация по таможенной базе.` :
              ` На автомобиль отсутствует информация по таможенной базе.
            Это может вызвать дополнительные вопросы о его легальности ввоза и происхождения, что требует более тщательной проверки`}
          </p>
        </div>

        <div className="general-info__block">
          <div className="general-info__title">
            <h4 className="general-info__heading">Фотографии:
              <span> {data?.images?.photos?.count ? ` ${data.images.photos.count}` : ' Не найдено фотографий'}</span>
            </h4>
            <div className="general-info__icon">
              <img src={data?.images?.photos?.count ? success : error} width="20" height="20" alt="" />
            </div>
          </div>
          <p className="general-info__text">
            {data?.images?.photos?.count ?
              `Представлено ${data.images.photos.count} фотографий автомобиля, что позволяет полноценно оценить его состояние перед покупкой` :
              `Не найдено фотографий`}
          </p>
        </div>
      </div>

    </div>
  )
}