import './InsuranceHistory.css';

export const InsuranceHistory = ({ insurances }) => {
  const hasInsurance = insurances?.items?.length > 0;
  const insuranceLength = hasInsurance ? insurances.items.length : 0;

  return (
    <section className="insurance-history" id="insurance-history">
      <div className="insurance-history__title">
        <h2 className="section-heading section-heading--insurance-history">История страхования</h2>
        <div className={`insurance-history__count ${hasInsurance ?
          'insurance-history__count--success' :
          'insurance-history__count--error'
          }`}>
          {insuranceLength > 0 ? `Обнаружено страхований: ${insuranceLength}` : 'Страхования не найдены'}
        </div>
      </div>
      {hasInsurance ? (
        <ul className="list-reset insurance-history__list">
          {insurances?.items?.map((insurance, index) => (
            <li className="insurance-history__item" key={index}>
              <div className="insurance-history__item-left">
                {insurance?.date?.created && (
                  <p className='insurance-history__parameter'>Дата получения:
                    <span>
                      {` ${insurance.date.created.split(" ")[0]}`}
                    </span>
                  </p>
                )}
                {insurance?.date?.end && (
                  <p className='insurance-history__parameter'>Дата истечения:
                    <span>
                      {` ${insurance.date.end.split(" ")[0]}`}
                    </span>
                  </p>
                )}
                {insurance?.contract?.amount.value && (
                  <p className='insurance-history__parameter'>Цена:
                    <span>
                      {` ${insurance.contract.amount.value}₽`}
                    </span>
                  </p>
                )}
                {insurance?.contract?.is_active ? (
                  <p className='insurance-history__parameter'>Статус:
                    <span style={{ color: '#82DF49' }}> Активен</span>
                  </p>
                ) : (
                  <p className='insurance-history__parameter'>Статус:
                    <span style={{ color: '#ff0000' }}> Не активен</span>
                  </p>
                )}
              </div>
              <div className="insurance-history__item-right">
                {insurance?.contract?.kbm && (
                  <p className='insurance-history__parameter'>КБМ:
                    <span> {insurance.contract.kbm}</span>
                  </p>
                )}
                {insurance?.contract?.using_type?.description && (
                  <p className='insurance-history__parameter'>Тип использования:
                    <span> {insurance.contract.using_type.description}</span>
                  </p>
                )}
                {insurance?.number && (
                  <p className='insurance-history__parameter'>Номер:
                    <span> {insurance.number}</span>
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}