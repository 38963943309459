import { Link } from 'react-router-dom';
import './AdsHistory.css';

export const AdsHistory = ({ ads }) => {
  const hasAds = ads?.items?.length > 0;
  const adsLength = hasAds ? ads?.items?.length : 0;

  return (
    <section className="ads-history" id="ads-history">
      <div className="ads-history__title">
        <h2 className="section-heading section-heading--ads-history">История объявлений</h2>
        <div className="ads-history__count">
          {adsLength > 0 ? `Обнаружено объявлений: ${adsLength}` : 'Объявления не найдены'}
        </div>
      </div>
      {hasAds ? (
        <ul className="list-reset fines-history__list">
          {ads?.items?.map((ad, index) => (
            <li className="ads-history__item" key={index}>
              {ad?.date?.publish && (
                <p className='ads-history__parameter'>Дата публикации:
                  <span> {ad.date.publish.split(" ")[0]}</span>
                </p>
              )}
              {ad?.geo?.city && (
                <p className='ads-history__parameter'>Город:
                  <span> {ad.geo.city}</span>
                </p>
              )}
              {ad?.vehicle?.mileage && (
                <p className='ads-history__parameter'>Пробег:
                  <span> {ad.vehicle.mileage} км</span>
                </p>
              )}
              {ad?.vehicle?.condition && (
                <p className='ads-history__parameter'>Состояние:
                  <span> {ad.vehicle.condition}</span>
                </p>
              )}
              {ad?.price?.value && (
                <p className='ads-history__parameter'>Цена:
                  <span> {ad.price.value} ₽</span>
                </p>
              )}
              {ad?.text && (
                <p className='ads-history__parameter'>Описание:
                  <span> {ad.text}</span>
                </p>
              )}
              {ad?.uri && (
                <p className='ads-history__parameter'>Ссылка: &nbsp;
                  <Link className="ads-history__link"
                    to={ad.uri}
                    target="_blank">
                    {ad.uri}
                  </Link>
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}