import HelpForm from "../../components/AuthForms/HelpForm/HelpForm"
import './Auth.css';

const HelpBlock = () => {
  return (
    <div className="auth__wrap">
      <div className="auth__title">
        <h2 className="section-heading section-heading--auth">Помощь</h2>
        <p className="auth__text">Если у вас есть вопросы или предложения, пожалуйста, заполните форму, и наша команда свяжется с вами в ближайшее время</p>
      </div>
      <HelpForm />     
    </div>
  )
}

export default HelpBlock