import { useNavigate } from 'react-router-dom';
import './DetailsTabSection.css';
import { DetailsGeneralInfo } from './DetailsGeneralInfo/DetailsGeneralInfo';
import { DetailsPledges } from './DetailsPledges/DetailsPledges';
import { DetailsMileageHistory } from './DetailsMileageHistory/DetailsMileageHistory';
import { DetailsService } from './DetailsService/DetailsService';
import { DetailsLegal } from './DetailsLegal/DetailsLegal';
import { DetailsTrafficAccident } from './DetailsTrafficAccident/DetailsTrafficAccident';
import { DetailsCalculation } from './DetailsCalculation/DetailsCalculation';
import { DetailsAdsHistory } from './DetailsAdsHistory/DetailsAdsHistory';
import { DetailsUseInTaxi } from './DetailsUseInTaxi/DetailsUseInTaxi';

const TabsTitles = [
  'Общая информация',
  'Залоги',
  'История пробега',
  'Обслуживание и техосмотры',
  'Юридическая чистота',
  'ДТП',
  'Расчёты стоимости ремонта',
  'История объявлений',
  'Использование в такси и каршеринге'
];

const TabsContent = [
  {
    element: <DetailsGeneralInfo />
  },
  {
    element: <DetailsPledges />
  },
  {
    element: <DetailsMileageHistory />
  },
  {
    element: <DetailsService />
  },
  {
    element: <DetailsLegal />
  },
  {
    element: <DetailsTrafficAccident />
  },
  {
    element: <DetailsCalculation />
  },
  {
    element: <DetailsAdsHistory />
  },
  {
    element: <DetailsUseInTaxi />
  }
]

export const DetailsTabSection = () => {
  const navigate = useNavigate();

  return (
    <section className="details">
      <div className='container details__container'>
        <h2 className="section-heading section-heading--details">Досконально проверяем детали</h2>

        <div className="details__tabs details-tabs">
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-1" defaultChecked />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-2" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-3" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-4" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-5" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-6" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-7" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-8" />
          <input className="hidden" type="radio" name="details-tabs-oblect" id="details-tabs-oblect-9" />

          <div className="details-tabs__oblects">
            <ul className="list-reset details-tabs__titles-list">
              {TabsTitles.map((item, index) => (
                <li key={index} className="details-tabs__titles-item">
                  <label htmlFor={`details-tabs-oblect-${index + 1}`} className={`details-tabs__label details-tabs__label--${index + 1}`} tabIndex={0}>
                    <span className="details-tabs__tab-title">{item}</span>
                  </label>
                </li>
              ))}
            </ul>
            <button className="btn-reset main-button details-tabs__button"
              onClick={() => navigate('/short-report')}
            >Посмотреть пример отчета</button>
          </div>

          <ul className="list-reset details-tabs__content">
            {TabsContent.map((item, index) => (
              <li key={index} className={`details-tabs__content-item details-tabs__content-item--${index + 1}`}>
                {item.element}
              </li>
            ))}
          </ul>
        </div>

        <button className="btn-reset main-button details-tabs__button details-tabs__button--mobile"
              onClick={() => navigate('/short-report')}
            >Посмотреть пример отчета</button>
      </div>
    </section>
  )
}