import './DetailsService.css';
import rolf from '../../../../media/images/new/rolf.svg';
import fitservice from '../../../../media/images/new/fitservice.svg';

export const DetailsService = () => {
  const serviceItems = [
    {
      date: '17 сентября 2017 года',
      service: 'Регламентное ТО',
      logo: rolf,
      companyName: 'Рольф-Северное, г. Москва',
      distance: '21 526 км',
      rollback: false
    },
    {
      date: '30  марта 2018 года',
      service: 'Текущий ремонт',
      companyName: 'Дилер, г. Москва',
      distance: '40 410 км',
      rollback: false
    },
    {
      date: '30 июня 2019 года',
      service: 'Регламентное ТО',
      companyName: 'Дилер, г. Москва',
      distance: '91 614 км',
      rollback: false
    },
    {
      date: '2 октября 2019 года',
      service: 'Регламентное ТО',
      logo: fitservice,
      companyName: 'FITSERVICE, г. Москва',
      distance: '60 000 км',
      rollback: true
    },
    {
      date: '13 августа 2020 года',
      service: 'Регламентное ТО',
      companyName: 'Дилер, г. Москва',
      distance: '71 853 км'
    }
  ];

  return (
    <div className="tab tab--service tab-service">
      <div className="tab__title">
        <h3 className="heading-three">Обслуживание и техосмотры</h3>
        <p className="tab__text">Отчёт покажет, обслуживали ли автомобиль в сервисах и у дилеров и проводился ли техосмотр по расписанию.</p>
      </div>

      <div className="tab__content-block tab__content-block--service">
        <h3 className="heading-three">История эксплуатации</h3>

        <ul className="list-reset tab-service__list">
          {serviceItems.map((item, index) => (
            <li className="tab-service__item" key={index}>
              <div className="tab-service__item-left">
                <p className="tab-service__date">{item.date}</p>
                <p className="tab-service__service">{item.service}</p>
                <p className="tab-service__company">
                  {item.logo && <img className="tab-service__logo" src={item.logo}
                    width="60" height="20" alt="логотип компании" />}
                  <span className="tab-service__company-name">{item.companyName}</span>
                </p>
              </div>
              <div className="tab-service__item-right">            
                  <p className={`tab-service__distance ${item.rollback ? 'tab-service__distance--rollback' : ''}`}>
                    {item.distance}
                    </p>
                  {item.rollback && <p className="tab-service__rollback">Обнаружена скрутка</p>}                
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}