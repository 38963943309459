import { Link, useParams } from 'react-router-dom';
import './UserProfilePage.css';
import { TabProfile } from './TabProfile/TabProfile';
import { TabBalance } from './TabBalance/TabBalance';
import { TabFavorites } from './TabFavorites/TabFavorites';
import { TabTickets } from './TabTickets/TabTickets';
import { ButtonUp } from '../../components/ButtonUp/ButtonUp';

export const UserProfilePage = () => {
  const tabRoutes = [
    {
      name: 'Профиль',
      path: 'user-profile'
    },
    {
      name: 'Баланс',
      path: 'balance'
    },
    {
      name: 'Избранное',
      path: 'favorite-reports'
    },
    {
      name: 'Поддержка',
      path: 'tickets'
    }
  ];

  const tabs = {
    'user-profile': TabProfile,
    'balance': TabBalance,
    'favorite-reports': TabFavorites,
    'tickets': TabTickets
  };

  const { tab } = useParams();
  const TabComponent = tabs[tab];

  return (
    <section className="user-profile">
      <div className="container user-profile__container">
        <div className="user-profile__title">
          <h2 className="section-heading section-heading--user-profile">Личный кабинет</h2>
          <ul className="list-reset user-profile__tab-list">
            {tabRoutes.map((item, index) => (
              <li key={item} className="user-profile__tab-item">
                <Link to={'/user-profile/' + item.path}
                  className={`user-profile__tab-link ${tab === item.path ? 'user-profile__tab-link--active' : ''}`}
                >{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        {TabComponent && <TabComponent />}
      </div>
      <ButtonUp />
    </section>
  )
}