import './TaxiHistory.css';

export const TaxiHistory = ({ taxiHistory }) => {
  const hasTaxi = taxiHistory?.items?.length > 0;
  const taxiLength = hasTaxi ? taxiHistory.items.length : 0;

  return (
    <section className="taxi-history" id="taxi-history">
      <div className="taxi-history__title">
        <h2 className="section-heading section-heading--taxi-history">Лицензии такси</h2>
        <div className={`taxi-history__count ${hasTaxi ?
          'taxi-history__count--error' :
          'taxi-history__count--success'}`}>
          {taxiLength > 0 ? `Есть данные о регистрации в такси` : 'Нет данных о регистрации в такси'}
        </div>
      </div>
      {hasTaxi ? (
        <ul className="list-reset taxi-history__list">
          {taxiHistory?.items?.map((taxi, index) => (
            <li className="taxi-history__item" key={index}>
              <h4 className="taxi-history__item-title">Лицензия: {index + 1}</h4>
              <div className="taxi-history__wrap">
                <div className="taxi-history__wrap-left">

                  {taxi?.license?.status && (
                    <p className='taxi-history__parameter'>Статус лицензии:
                      {taxi?.license?.status === 'ACTIVE' ? (
                        <span style={{ color: '#82DF49' }}> Активна</span>
                      ) : (<span style={{ color: '#DF4949' }}> Аннулирована</span>)}
                    </p>
                  )}

                  {taxi?.license?.number && (
                    <p className='taxi-history__parameter'>Номер лицензии:
                      <span> {taxi.license.number}</span>
                    </p>
                  )}

                  {taxi?.license?.issued && (
                    <p className='taxi-history__parameter'>Дата получения:
                      <span > {taxi.license.issued.split(" ")[0]}</span>
                    </p>
                  )}

                  {taxi?.date?.end && (
                    <p className='taxi-history__parameter'>Дата окончания:
                      <span> {taxi.date.end.split(" ")[0]}</span>
                    </p>
                  )}

                  {taxi?.region?.code && (
                    <p className='taxi-history__parameter'>Регион выдачи:
                      <span> {taxi.region.code}</span>
                    </p>
                  )}

                  {taxi?.city?.name && (
                    <p className='taxi-history__parameter'>Город выдачи:
                      <span> {taxi.city.name}</span>
                    </p>
                  )}
                </div>
                <div className="taxi-history__wrap-right">
                  {taxi?.company?.name && (
                    <p className='taxi-history__parameter'>Юридическое лицо получателя:
                      <span> {taxi.company.name}</span>
                    </p>
                  )}
                  {taxi?.vehicle?.reg_num && (
                    <p className='taxi-history__parameter'>Госномер:
                      <span> {taxi.vehicle.reg_num}</span>
                    </p>
                  )}
                  {taxi?.vehicle?.brand?.name && (
                    <p className='taxi-history__parameter'>Марка автомобиля:
                      <span> {taxi.vehicle.brand.name}</span>
                    </p>
                  )}
                  {taxi?.vehicle?.model?.name && (
                    <p className='taxi-history__parameter'>Модель автомобиля:
                      <span> {taxi.vehicle.model.name}</span>
                    </p>
                  )}
                  {taxi?.vehicle?.year && (
                    <p className='taxi-history__parameter'>Год выпуска:
                      <span> {taxi.vehicle.year}</span>
                    </p>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}