import './AnswersSection.css';

export const AnswersSection = () => {
  const items = [
    {
      question: 'Как я могу проверить автомобиль?',
      answer: 'Для проверки автомобиля введите VIN-номер или госномер в специальное поле на нашем сайте и нажмите «Проверить». Сервис обработает запрос и выдаст полный отчет о машине.'
    }, {
      question: 'Что включает в себя проверка истории автомобиля?',
      answer: 'Отчет содержит информацию о регистрации, количестве владельцев, наличии ограничений (арест, залог, запрет на регистрационные действия), участие в ДТП, страховые выплаты, пробег, данные о такси, таможенной истории и т.д.'
    }, {
      question: 'Какие данные я получу в отчёте?',
      answer: <>Вы получите:
        <ul className="list-reset answers__inner-list">
          <li>Историю регистраций и смены владельцев</li>
          <li>Наличие ограничений (арест, залог и т.д.)</li>
          <li>Информацию о ДТП и страховых выплатах</li>
          <li>Историю прохождения техосмотров</li>
          <li>Данные о работе в такси</li>
          <li>Пробег по разным источникам</li>
          <li>Таможенную историю и возможные штрафы</li>
        </ul>
      </>
    }, {
      question: 'Сколько времени занимает проверка?',
      answer: 'Обычно проверка занимает от 10 секунд до 1 минуты. В редких случаях обработка запроса может занять немного больше времени.'
    },
    {
      question: 'Нужен ли мне VIN-номер для проверки?',
      answer: 'Да, VIN-номер позволяет получить максимально  полную информацию. Однако, если у вас есть только госномер, вы также сможете выполнить проверку, но некоторые данные могут быть недоступны.'
    },
    {
      question: 'Как я могу быть уверенным в точности информации?',
      answer: 'Мы собираем данные только из официальных источников, включая ГИБДД, страховые компании, таможенные базы, судебные реестры и другие надежные источники. Это гарантирует актуальность и достоверность предоставляемой информации.'
    }
  ]

  return (
    <section id='faq' className="answers">
      <div className='container answers__container'>
        <h2 className="section-heading section-heading--answers">Ответы на вопросы</h2>

        <div className="answers__content answers__tabs">
          <input className="hidden" type="radio" name="answers-object" id="answers-object-1" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-2" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-3" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-4" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-5" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-6" />
          <input className="hidden" type="radio" name="answers-object" id="answers-object-7" />

          <ul className="list-reset answers__list">
            {items.map((itemData, index) => (
              <li className={`answers__item answers__item--${index + 1}`} key={index}
              onClick={() => {
                const input = document.getElementById(`answers-object-${index + 1}`);
                input.checked = true;
              }}>
                <div className="answers__title">
                  <p className="answers__question">{itemData.question}</p>
                  <label htmlFor={`answers-object-${index + 1}`} className="answers__button answers__button--read-more" 
                  onClick={(e) => e.stopPropagation()}></label>
                  <label htmlFor={`answers-object-7`} className="answers__button answers__button--read-less"
                  onClick={(e) => e.stopPropagation()} ></label>
                </div>
                <p className="answers__answer">{itemData.answer}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}