import React, { useEffect, useState } from "react";
import './ButtonUp.css';

export function ButtonUp() {
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    const showButtonUp = () => {
      if (window.pageYOffset > 300) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    }
    window.addEventListener('scroll', showButtonUp);

    return () => {
      window.removeEventListener('scroll', showButtonUp);
    }
  }, []);

  return (
    <button className={`btn-reset btn--up ${showBtn ? 'shown' : ''}`} type="button" onClick={() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }}>
      <svg className="arrow-top" viewBox="0 0 5 9">
        <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z" />
      </svg>
      <span className="visually-hidden">Наверх</span>
    </button>
  )
}