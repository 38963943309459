import './ShortOperationHistory.css';

export const ShortOperationHistory = () => {
  return (
    <div className="short-block short-operation-history">
      <table className="short-operation-history__table operation-table">
        <thead className="operation-table__head">
          <tr>
            <th></th>
            <th className="operation-table__head-cell">
              <h4 className="operation-table__head-title">Первый владелец</h4>
              <p className="operation-table__parameter">Период владения: <span>23 июня 2017г. - 30 августа 2019г.</span></p>
              <p className="operation-table__parameter">Тип владельца: <span>Физическое лицо</span></p>
              <p className="operation-table__parameter">Регион регистрации: <span>Московская область, г.Балашиха</span></p>
              <button className="btn-reset operation-table__button">Посмотреть историю регистрационных действий</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="operation-table__row">
            <td className="operation-table__cell operation-table__date">23 июня 2017</td>
            <td className="operation-table__cell operation-table__cell--right">
              <h5 className="operation-table__cell-title">Первичная регистрация</h5>
              <p className="operation-table__cell-text">Данные о регистрации, Московская область</p>
            </td>
          </tr>
          <tr className="operation-table__row">
            <td className="operation-table__cell operation-table__date">22 августа 2017<br />
              <span>Пробег 8 528 км</span>
            </td>
            <td className="operation-table__cell operation-table__cell--right">
              <h5 className="operation-table__cell-title">Прохождение технического осмотра</h5>
              <p className="operation-table__cell-text">Данные о техосмотре, г. Москва</p>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  )
}