import './ShortCommercialUse.css';
import taxi from '../../../../../../media/images/new/taxi.png';

export const ShortCommercialUse = () => {
  return (
    <div className="short-block short-block--commercial short-commercial-use">
      <div className="short-commercial-use__image-wrap">
        <img className="short-commercial-use__image" src={taxi} width="575" height="354" alt="такси" />
      </div>
    </div>
  )
}
