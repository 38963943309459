import { useNavigate, Link } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from "react"
import AuthContext from "../../../context/AuthContext"
import NotificationWebSocket from "../../../api/WebSockets/NotificationWebSocket"
import './Header.css';
import logo from '../../../media/images/new/logo-header.svg';

const isChildElement = (element, parent) => {
  if (element && parent && element == parent) {
    return true;
  } else if (element && element.parentElement) {
    return isChildElement(element.parentElement, parent)
  } else {
    return false;
  }
}

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logoutUser } = useContext(AuthContext);
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate(`/`);

      setTimeout(() => {
        const homeSection = document.getElementById(id);
        if (homeSection) {
          homeSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  const accountButton = useRef();

  useEffect(() => {
    if (!isProfileShow) {
      return;
    }
    const close = (evt) => {
      if (!isChildElement(evt.target, accountButton.current)) {
        setIsProfileShow(false);
        window.removeEventListener('click', close);
      }
    }
    window.addEventListener('click', close, true);

    return () => window.removeEventListener('click', close);
  }, [isProfileShow]);

  useEffect(() => {   
    if(!openMenu) {
      window.document.documentElement.classList.remove('no-scroll');
    } else {
      window.document.documentElement.classList.add('no-scroll');
    }
    return () => {
      window.document.documentElement.classList.remove('no-scroll');
    }
  }, [openMenu])

  return (
    <>
      
      <header className='header'>
        <div className='container header__container'>
          {user ?
            <NotificationWebSocket user_id={user?.id} />
            : ''}
          <div className="header__wrap">
            <Link to="/" className='header__logo-link'>
              <img loading="lazy" className="header__logo" src={logo} width="112" height="13" alt="checkauto" />
            </Link>

            <button className={`btn-reset main-nav-toggle burger ${openMenu ? 'burger--active' : ''}`} type="button" aria-label="Открыть меню"
              onClick={() => { setOpenMenu(last => !last) }}>
              <span className="burger__line"></span>
            </button>
            <div className={`burger-overlay ${openMenu ? 'burger-overlay--show' : ''}`}
              onClick={() => setOpenMenu(false)}
            ></div>

            <div className={`main-nav ${!openMenu ? 'main-nav--closed' : 'main-nav--opened'} `}>
              <nav className="nav header__nav">
                <ul className="list-reset nav__list">
                  <li className="nav__item">
                    <button onClick={() => {
                      scrollToSection('benefit');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">Чем полезен</button>
                  </li>
                  <li className="nav__item">
                    <button onClick={() => {
                      navigate('/short-report');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">Пример отчета</button>
                  </li>
                  <li className="nav__item">
                    <button onClick={() => {
                      scrollToSection('reviews');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">Отзывы</button>
                  </li>
                  <li className="nav__item">
                    <button onClick={() => {
                      user ? navigate('/user-profile/tickets') : scrollToSection('faq');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">FAQ</button>
                  </li>
                  <li className="nav__item">
                    <button onClick={() => {
                      scrollToSection('partners');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">Наши партнеры</button>
                  </li>
                  <li className="nav__item">
                    <button onClick={() => {
                      navigate('/help');
                      setOpenMenu(false);
                    }} className="btn-reset nav__link">Задать вопрос</button>
                  </li>
                </ul>
              </nav>
            </div>

            {user ?
              <div className="user-block">
                <button className="btn-reset user-block__logout-btn"
                  onClick={() => logoutUser()}
                >
                  Выйти <span>из аккаунта</span>
                </button>

                <div className="user-block__drop drop-down" ref={accountButton}>
                  <button className="btn-reset user-block__profile-btn"
                    onClick={() => setIsProfileShow(last => !last)}>
                    <span className="visually-hidden">Профиль</span>

                    <svg className="user-block__profile-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_2146_1099" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                        <rect width="30" height="30" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2146_1099)">
                        <path d="M15 14.6153C13.7969 14.6153 12.767 14.187 11.9103 13.3303C11.0534 12.4734 10.625 11.4434 10.625 10.2403C10.625 9.03717 11.0534 8.00727 11.9103 7.15061C12.767 6.29373 13.7969 5.8653 15 5.8653C16.2031 5.8653 17.233 6.29373 18.0897 7.15061C18.9466 8.00727 19.375 9.03717 19.375 10.2403C19.375 11.4434 18.9466 12.4734 18.0897 13.3303C17.233 14.187 16.2031 14.6153 15 14.6153ZM5.625 24.1347V21.3556C5.625 20.7435 5.79125 20.1766 6.12375 19.655C6.45625 19.1333 6.90062 18.7323 7.45687 18.4519C8.69229 17.8462 9.93865 17.392 11.1959 17.089C12.4532 16.7861 13.7212 16.6347 15 16.6347C16.2788 16.6347 17.5468 16.7861 18.8041 17.089C20.0614 17.392 21.3077 17.8462 22.5431 18.4519C23.0994 18.7323 23.5437 19.1333 23.8762 19.655C24.2087 20.1766 24.375 20.7435 24.375 21.3556V24.1347H5.625Z" fill="black" fillOpacity="0.3" />
                      </g>
                    </svg>
                  </button>

                  <div className={`drop-down__grid ${isProfileShow ? "drop-down__grid--open" : ''}`}>
                    <div className="drop-down__content">
                      <ul className="list-reset nav__list nav__list--profile">
                        <li className="nav__item nav__item--profile">
                          <Link to="/user-profile/user-profile"
                            onClick={() => setIsProfileShow(false)}
                            className="nav__link nav__link--profile">Профиль</Link>
                        </li>
                        <li className="nav__item nav__item--profile">
                          <Link to="/user-profile/balance"
                            onClick={() => setIsProfileShow(false)}
                            className="nav__link nav__link--profile">Баланс</Link>
                        </li>
                        <li className="nav__item nav__item--profile">
                          <Link to="/user-profile/favorite-reports"
                            onClick={() => setIsProfileShow(false)}
                            className="nav__link nav__link--profile">Избранное</Link>
                        </li>
                        <li className="nav__item nav__item--profile">
                          <Link to="/user-profile/tickets"
                            onClick={() => setIsProfileShow(false)}
                            className="nav__link nav__link--profile">Поддержка</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className='header__buttons'>
                <button className='btn-reset second-button header__button header__button--login'
                  onClick={() => navigate('/login')}
                >Вход</button>
                <button className='btn-reset main-button header__button header__button--registration'
                  onClick={() => navigate('/registration')}
                >Регистрация</button>
              </div>
            }

          </div>
        </div>
      </header>
     
    </>
  )
}