import { useState } from 'react';
import './ModalBuy.css';
import iconProtection from '../../../media/images/new/icon-protection2.svg';

export const ModalBuy = ({modalOpen, onClose, onBuy}) => {
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [firstPaymentChecked, setFirstPaymentChecked] = useState(false);

  return (
    <>
      <div className={`modal-buy-overlay ${modalOpen ? 'modal-buy-overlay--show' : ''}`}
      onClick={onClose}></div>
      <section className={`modal-buy ${modalOpen ? 'modal-buy--open' : ''} `}>
        <div className="modal-buy__left">
          <div className="modal-buy__left-wrap">
            <h3 className="heading-three heading-three--modal-buy">Полный отчет за <span>1 рубль</span></h3>
            <ul className="list-reset modal-buy__list">
              <li className="modal-buy__item">
                <p className="modal-buy__item-text">Гарантии качества</p>
                <div className="modal-buy__icon modal-buy__icon--one"></div>
              </li>
              <li className="modal-buy__item">
                <p className="modal-buy__item-text">Доступны все функции сервиса</p>
                <div className="modal-buy__icon modal-buy__icon--two"></div>
              </li>
              <li className="modal-buy__item">
                <p className="modal-buy__item-text">Гарантии качества</p>
                <div className="modal-buy__icon modal-buy__icon--three">
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="modal-buy__right">
          <div className="modal-buy__right-wrap">
            <h2 className="section-heading section-heading--modal-buy">Купи отчёт этого авто</h2>
            <div className="modal-buy__one-ruble one-ruble">
              <p className="one-ruble__one-report">1 отчет</p>
              <p className="one-ruble__text">1 рубль</p>

            </div>
            <button className="btn-reset main-button modal-buy__pay-button"
              onClick={onBuy} /*disabled={!agreementChecked || !firstPaymentChecked}*/
            >Оплатить 1 рубль</button>
            <div className="modal-buy__agreement">
              <p>
                <input
                  className="visually-hidden license-agreement-checkbox"
                  type="checkbox"
                  id="license"
                  name="license" 
                  onChange={(e) => setAgreementChecked(e.target.checked)}
                  checked={agreementChecked} />
                <label
                  className="license-agreement-label"
                  htmlFor="license">
                  Согласен с условиями лицензионного соглашения
                </label>
              </p>
              <p>
                <input
                  className="visually-hidden first-payment-checkbox"
                  type="checkbox"
                  id="first-payment"
                  name="first-payment"
                  onChange={(e) => setFirstPaymentChecked(e.target.checked)}
                  checked={firstPaymentChecked} />
                <label
                  className="first-payment-label"
                  htmlFor="first-payment">
                  Первый платёж в 1 руб. за пробный период
                  доступа в личный кабинет на 3 дня, далее согласно тарифу 990 руб. за 7 дней доступа к сервису
                </label>
              </p>
            </div>

            <div className="modal-buy__protection protection">
              <div className="protection__block">
                <img src={iconProtection} width="20" height="20" alt="иконка" />
                <p className="protection__text">Ваши данные защищены</p>
              </div>
              <div className="protection__buttons">
                <button className="btn-reset protection__button protection__button--mastercard"></button>
                <button className="btn-reset protection__button protection__button--visa"></button>
                <button className="btn-reset protection__button protection__button--mir"></button>
              </div>
            </div>
            <button className="btn-reset modal-buy__close-button"
              onClick={onClose}
            >
              <span className="visually-hidden">Закрыть окно</span>
            </button>
          </div>
        </div>
      </section></>
  )
}