import useDocumentTitle from '../../../utils/useDocumentTitle';
import { ButtonUp } from '../../components/ButtonUp/ButtonUp';
import './OfferPage.css';

export const OfferPage = () => {
  useDocumentTitle(`Страница оферты`);

  return (
    <section className="offer">
      <div className='container offer__container'>
        <h2 className="section-heading section-heading--offer">Страница оферты</h2>
        <ol className="offer__list">
          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">
                Настоящий документ является официальным предложением (далее — «Оферта») компании CheckAuto (далее — «Исполнитель») и содержит условия оказания услуг по проверке автомобилей.
              </li>
              <li className="offer__inner-item">
                В случае принятия изложенных в Оферте условий лицо, осуществляющее акцепт (далее — «Заказчик»), становится стороной настоящего договора.
              </li>
              <li className="offer__inner-item">
                Акцептом Оферты является осуществление Заказчиком оплаты услуг Исполнителя.
              </li>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ПРЕДМЕТ ДОГОВОРА</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">
                Исполнитель обязуется оказать Заказчику услуги по проверке автомобиля по предоставленным данным (VIN, госномер, иные идентификационные сведения), а Заказчик обязуется оплатить услуги на условиях, указанных в настоящем договоре.
              </li>
              <li className="offer__inner-item">
                В рамках проверки предоставляются следующие услуги:
                <ul className="offer__unordered-list">
                  <li>Проверка автомобиля на предмет залога и ограничений;</li>
                  <li>Проверка истории ДТП;</li>
                  <li>Проверка пробега;</li>
                  <li>Проверка на розыск;</li>
                  <li>Иные услуги, указанные на сайте Исполнителя https://check.auto/.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">
                Исполнитель обязуется:
                <ol className="offer__inner-list">
                  <li className="offer__inner-item">Оказать услуги качественно и в срок, предусмотренный настоящим договором.</li>
                  <li className="offer__inner-item">Предоставить Заказчику результаты проверки в виде отчёта, направленного на указанный им электронный адрес.</li>
                  <li className="offer__inner-item">Сохранять конфиденциальность полученных от Заказчика данных.</li>
                </ol>
              </li>
              <li className="offer__inner-item">
                Заказчик обязуется:
                <ol className="offer__inner-list">
                  <li className="offer__inner-item">Предоставить корректные данные для проверки автомобиля.</li>
                  <li className="offer__inner-item">Оплатить услуги Исполнителя в полном объёме и в установленный срок.</li>
                  <li className="offer__inner-item">Не использовать информацию, полученную в результате проверки, в незаконных целях.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">СТОИМОСТЬ УСЛУГ И ПОРЯДОК ОПЛАТЫ</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">Стоимость услуг определяется в соответствии с тарифами, размещёнными на сайте Исполнителя https://check.auto/.</li>
              <li className="offer__inner-item">Оплата услуг производится посредством безналичного расчёта на условиях 100% предоплаты.</li>
              <li className="offer__inner-item">Услуга считается оказанной в момент отправки отчёта Заказчику.</li>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">Исполнитель не несёт ответственности за:</li>
              <ul className="offer__unordered-list">
                <li>Некорректность предоставленных Заказчиком данных;</li>
                <li>Использование результатов проверки Заказчиком;</li>
                <li>Несоответствие данных в отчёте фактическому состоянию автомобиля, если это обусловлено отсутствием информации в официальных базах данных.</li>
              </ul>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">Все споры, возникающие в связи с исполнением настоящего договора, решаются путём переговоров между сторонами.</li>
              <li className="offer__inner-item">В случае невозможности урегулирования спора в досудебном порядке он подлежит рассмотрению в суде по месту нахождения Исполнителя.</li>
            </ol>
          </li>

          <li className="offer__item">
            <h3 className="heading-three heading-three--offer">ПРОЧИЕ УСЛОВИЯ</h3>
            <ol className="offer__inner-list">
              <li className="offer__inner-item">Настоящий договор вступает в силу с момента акцепта Оферты и действует до полного исполнения сторонами своих обязательств.</li>
              <li className="offer__inner-item">Исполнитель вправе вносить изменения в условия Оферты. Изменения вступают в силу с момента их публикации на сайте Исполнителя.</li>
            </ol>
          </li>
        </ol>
      </div >
      <ButtonUp />
    </section >
  )
}