import './ShortLegalRisks.css';

export const ShortLegalRisks = () => {
  return (
    <div className="short-block short-legal-risks">
      <ul className="list-reset short-legal-risks__list">
        <li className="short-legal-risks__item">Не найдены ограничения на регистрацию</li>
        <li className="short-legal-risks__item">Нет сведений о розыске</li>
        <li className="short-legal-risks__item short-legal-risks__item--error">Обнаружен залог
          <p>Если авто продадут без согласия банка, сделку могут признать недействительной, а машину - забрать. 
            Перед покупкой убедитесь, что продавец погасил долг.
          </p>
        </li>
        <li className="short-legal-risks__item">Не найдены неоплаченные штрафы</li>
        <li className="short-legal-risks__item">Не найдены сведения о лизинге.</li>
      </ul>
    </div>
  )
}