import { Link } from "react-router-dom";
import RegistrationForm from "../../components/AuthForms/RegistrationForm/RegistrationForm";
import './Auth.css';

const RegistrationBlock = () => {
  return (
    <div className="auth__wrap">
      <div className="auth__title">
        <h2 className="section-heading section-heading--auth">Попробуйте сейчас</h2>
        <p className="auth__text">Пройдите простую регистрацию через E-mail
          или авторизуйтесь через соц. сети</p>
      </div>
      <RegistrationForm />
      <div className="auth__info-block">
        <p style={{ margin: '0' }}>
          <span>Уже есть аккаунт? </span>
          <Link className="auth__link" to='/login'>Войти</Link>
        </p>
        <p className="auth__agreement">
          Создавая аккаунт, Вы соглашаетесь с <Link className="auth__agreement-link" to="/offer">условиями оферты </Link>
          <br /> и <Link className="auth__agreement-link" to="/privacy">политикой конфиденциальности</Link>
        </p>
      </div>
    </div>
  )
}

export default RegistrationBlock