import './DetailsMileageHistory.css';
import img from '../../../../media/images/new/mileage-chart.png';
import error from '../../../../media/images/new/error.svg';

export const DetailsMileageHistory = () => {
  return (
    <div className="tab tab--mileage-history tab-mileage">
      <div className="tab__title">
        <h3 className="heading-three heading-three--mileage">История пробега</h3>
        <p className="tab__text">Некоторые продавцы занижают показания одометра, чтобы продать машину дороже. Поэтому мы проверяем, скручивали ли пробег, когда и на сколько.</p>
      </div>
      <div className="tab__content-block tab__content-block--mileage">
        <div className="tab__heading-wrap">
          <h3 className="heading-three heading-three--content">Пробег автомобиля</h3>
          <img src={error} width={20} height={20} />
        </div>
        <div className="tab-mileage__content-wrap">
          <div className="tab-mileage__info">
            <p className="tab-mileage__text">Последнее зафиксированное значение пробега 91 851 км.</p>
            <p className="tab-mileage__text tab-mileage__text--bold">Реальное значение пробега с учетом скрутки - не менее 123 456 км.</p>
            <p className="tab-mileage__text">19 октября 2019 года обнаружена <span style={{color: '#FF0000'}}>скрутка</span> пробега на 31 614 км.</p>
          </div>
          <div className="tab-mileage__image-wrap">
            <img src={img} width="695" height="406" alt="график пробега" />
          </div>
        </div> 
      </div>
    </div >
  )
}