import { validateName, validateEmail } from "../../../../utils/FieldValidation";

export const validateHelpForm = (name, email, setError) => {
  let isValid = true;
  let newError = { name: '', email: '' };

  // Validate name
  newError.name = validateName(name);
  if (newError.name) {
    isValid = false;
  }

  // Validate email
  newError.email = validateEmail(email);
  if (newError.email) {
    isValid = false;
  }

  setError(newError);
  return isValid;
};