import './CommercialUse.css';

export const CommercialUse = ({ commercialUse }) => {
  const hasCommercialUse = commercialUse?.items?.length > 0;
  const commercialUseLength = hasCommercialUse ? commercialUse.items.length : 0;

  return (
    <section className="commercial-use" id="commercial-history">
      <div className="commercial-use__title">
        <h2 className="section-heading section-heading--commercial-use">
          Коммерческое использование
        </h2>
        <div className={`commercial-use__count ${hasCommercialUse ?
          'commercial-use__count--error' :
          'commercial-use__count--success'}`}>
          {commercialUseLength > 0 ?
            `Коммерческое использование: ${commercialUseLength}` :
            'Нет информации о коммерческом использовании'}
        </div>
      </div>
      {hasCommercialUse ? (
        <ul className="list-reset commercial-use__list">
          {commercialUse?.items?.map((commercial, index) => (
            <li className="commercial-use__item" key={index}>
              {commercial?.company?.name && (
               <p className='commercial-use__parameter'>Наименование компании:
                  <span> {commercial.company.name}</span>
                </p>
              )}
              {commercial?.company?.tin && (
                <p className='commercial-use__parameter'>ИНН компании:
                  <span> {commercial.company.tin}</span>
                </p>
              )}             
            </li>
          ))}
        </ul>
      ) : ''}
    </section>
  )
}